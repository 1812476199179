export class ProductSearchMock {
  public static data = {
    "simpleSearchresponse": {
      "Rema": [
        {
          "id": 9591,
          "title": "BUTTERDEJSPLADER",
          "site": "Rema",
          "description": "450 GR. / REMA 1000",
          "price": 9.95,
          "availability": false,
          "offer": false,
          "offerdesc": null,
          "labels": [
            "Frost",
            "EU's Økologimærke"
          ],
          "product_id": "45506",
          "item_category": [
            "Frost",
            "Brød og kager"
          ],
          "favorite": false
        }
      ],
      "Bilka": [
        {
          "id": 4412,
          "title": "Madame Butterfly Øko. myslikiks",
          "site": "Bilka",
          "description": "950 g 22,05/kg.",
          "price": 15.95,
          "availability": true,
          "offer": false,
          "offerdesc": null,
          "labels": [
            "oekologisk"
          ],
          "product_id": "33341",
          "item_category": [
            "Brød og kager"
          ],
          "favorite": false
        }
      ],
      "Nemlig": [
        {
          "id": 905,
          "title": "Butter chicken",
          "site": "Nemlig",
          "description": "450 g / Agnes",
          "price": 51.36,
          "availability": true,
          "offer": false,
          "offerdesc": null,
          "labels": [
            "KØL"
          ],
          "product_id": "5020135",
          "item_category": [
            "Køl og mejeri",
            "Færdigretter på køl",
            "Middagsretter på køl"
          ],
          "favorite": false
        }
      ],
      "Coop": [
        {
          "id": 17679,
          "title": "Butternutgræskar",
          "site": "Coop",
          "description": "Ella''s Kitchen, 0,12 kg, Fra 4 mdr.",
          "price": 14.75,
          "availability": true,
          "offer": false,
          "offerdesc": null,
          "labels": [
            "Ø-mærket"
          ],
          "product_id": "0",
          "item_category": [
            "Baby & børn"
          ],
          "favorite": false
        }
      ]
    }
  }
}