import { LANGUAGE_CODE } from '@angular/fire/auth';

export const Constants: any = {
    SellersDelliveryOptions: [
        {
            name: 'Rema',
            deliveryOptions: ['selfpickup', 'homedelivery']
        },
        {
            name: 'Bilka',
            deliveryOptions: ['selfpickup','driveinpickup']
        },
        {
            name: 'Nemlig',
            deliveryOptions: ['homedelivery']
        },
        {
            name: 'Coop',
            deliveryOptions: ['homedelivery', 'selfpickup']
        },
        {
            name: 'Foetex',
            deliveryOptions: ['homedelivery', 'selfpickup']
        }
    ],
    DeliveryOptions: ['homedelivery', 'selfpickup', 'driveinpickup'],
    InitialSelectedProduct: {
        Rema: {
          selectedProductList: [],
          selectedQuantity: 0,
          selectedUnit: 0,
          totalPrice: 0,
          selectedMeasure: null
        },
        Bilka: {
          selectedProductList: [],
          selectedQuantity: 0,
          selectedUnit: 0,
          totalPrice: 0,
          selectedMeasure: null
        },
        Nemlig: {
          selectedProductList: [],
          selectedQuantity: 0,
          selectedUnit: 0,
          totalPrice: 0,
          selectedMeasure: null
        },
        Coop: {
          selectedProductList: [],
          selectedQuantity: 0,
          selectedUnit: 0,
          totalPrice: 0,
          selectedMeasure: null
        },
        Foetex: {
          selectedProductList: [],
          selectedQuantity: 0,
          selectedUnit: 0,
          totalPrice: 0,
          selectedMeasure: null
        }
    },
    LabelIcons: {
      "Økologisk-DK": "oekologisk.svg",
      "Økologisk - EU": "eu_oeko_maerkning.svg",
      "Glutenfri": '',
      "Laktosefri": '',
      "Svanemærket": 'svanemaerket.jpg',
      "Nøglehul": "noeglehul.svg",
      "Fuldkornsmærket": "fuldkornsmaerket.svg",
      "Fairtrade": "fairtrade.svg",
      "Frost": "frozen.svg",
      "Dansk Produceret": '',
      "Den blå krans": '',
      "Aldersbegrænset til 16 år": '',
      "Aldersbegrænset til 18 år": '',
      "ASC": '',
      "Astma og allergi": '',
      "Avisvare": '',
      "Halal": "halal.png",
      "Uden tilsat sukker": '',
      "MSC mærke": '',
      "Køl": ''
    },
    ProductUnit: {
      'gram': ['gram', 'kg'],
      'mililiter': ['ml', 'liter'],
      'stk': ['stk']
    },
    DOMAIN: 'https://api.visave.net',
    // DOMAIN: 'http://test-api.visave.net:8080',
    BASE_URI: {
      searchApi: '/search/list/',
      addFavApi: '/favourite/add',
      removeFavApi: '/favourite/delete',
      getFavApi: '/favourite/get/',
      saveListApi: '/save/add',
      getUserPrefrenceApi: '/user/preferences/',
      getSaveListItemsApi: '/save/fetch/list/',
      getSavedListsApi: '/save/fetch/lists/',
      deleteSavedListApi: '/save/delete/',
      myProfileApi: '/user/add/info',
      getUserInfoApi: '/user/get/info/for/',
      emailNotificationApi: '/notify/user',
      deleteUserprofileApi: '/user/delete/profile/'
    },
    CURRENCY_SYMBOL:{
      'denmark': "DKK"
    },
    LANGUAGE_OPTIONS: {
      'dk': 'Dansk',
      'en': 'English'
    },
    SELLER_NAMES: {
      'Rema': "Rema 1000",
      'Coop': "COOP",
      'Nemlig': "Nemlig",
      'Bilka': "Bilka",
      'Foetex': "Føtex"
    },
    PREMIUM_LABELS: {
      'Rema': "Rema_Premium",
      'Coop': "COOP_Medlemsrabat",
      'Nemlig': "Nemlig_Premium",
      'Bilka': "Bilka_Plus",
      'Foetex': "Føtex_Plus"
    },
    PREMIUM_LISTING_LABELS: {
      'Rema': "Premium",
      'Coop': "Medlemsrabat",
      'Nemlig': "Premium",
      'Bilka': "Plus",
      'Foetex': "Plus"
    },
    IMAGE_SOURCE: "https://all-product-images.s3.eu-central-1.amazonaws.com/"

}


// Astma-allergimærket
// Frozen : Frost

// Økologisk - EU