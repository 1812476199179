<div class="container-fluid">
    <div class="row mt-3 mb-2">
        <div class="col-9 position-relative">
            <div class="row filtering-block" *ngIf="(filteredProductList$ |async) && !hasError">
                <div class="col-auto">
                    <div class="form-check main-sorting">
                        <input class="form-check-input" name="radioList" type="checkbox" id="tilbud" [ngModel]="isDiscountSelected" (ngModelChange)="selectDiscounted($event)" />
                        <label class="form-check-label" for="tilbud" title="Tilbud" translate>
                          offer
                        </label>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="form-inline">
                        <div class="category-select">
                            <ng-select [(ngModel)]="filterLabel" placeholder="{{'select_ a_filter' | translate}}" (change)="selectFilteringOptions(filterLabel)">
                                <ng-option *ngFor="let filterOption of productFilteringOptions" [value]="filterOption">{{filterOption.name}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="form-inline">
                        <div class="category-select">
                            <ng-select [(ngModel)]="sortLabel" placeholder="{{'sort_product_by' | translate}}" (change)="selectSortingOptions(sortLabel)">
                                <ng-option *ngFor="let sortOption of productSortingOptions" [value]="sortOption.id">{{sortOption.name | translate}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="reset-filtering-block position-absolute" 
                *ngIf="countVal > 0 && (filteredProductList$ |async)">
                <span translate>to_apply_filter_sort_conditions1 </span>
                <button class='btn btn-primary btn-sm' (click)='resetFilterSelections()'>re-set</button>
                <span translate> to_apply_filter_sort_conditions2</span>
            </div>
        </div>
        <div class="col-3 d-flex flex-row justify-content-end align-items-center" *ngIf="countVal || cartItems">
            <div class="d-flex align-items-center flex-row quantity-area" *ngIf="countVal > 0 && (filteredProductList$ |async) && !enableAddToCart" ngbTooltip="{{'please_select_products_from_all_stores_for_comparison' | translate}}">
                <button type="button" [disabled]="!enableAddToCart" class="btn btn-primary btn-sm mr-2"  [ngClass]="{'animate-me': enableAddToCart}" placement="left" translate>
                    <i class="fa fa-shopping-cart mr-1" aria-hidden="true"></i>add_to_cart
                </button>
            </div>
            <div class="d-flex align-items-center flex-row quantity-area" *ngIf="countVal > 0 && (filteredProductList$ |async) && enableAddToCart">
                <button type="button" class="btn btn-primary btn-sm mr-2"  [ngClass]="{'animate-me': enableAddToCart}" (click)="addProduct(quantityMatch)" placement="left" translate>
                    <i class="fa fa-shopping-cart mr-1" aria-hidden="true"></i>add_to_cart
                </button>
            </div>
        </div>
    </div>
    <hr class="my-1" *ngIf="(filteredProductList$ | async) && !hasError" />
    <div class="row">
        <div class="col-md-12 main-content-area">
            <ng-container *ngIf="(productSearchLoading$ | async) || (filteredProductList$ | async); else nothingToShow">
                <ng-container *ngIf="(filteredProductList$ | async) as data; else loading">
                    <app-products *ngIf="!hasError" [sellerFilter]="sellerFilters" [filteredProductList]="filteredProductList$" [resetSelectedProduct]="resetSelectedProducts"></app-products>
                    <div *ngIf="hasError" class="d-flex justify-content-center align-items-center placeholder-text text-center">
                        {{noDataFound}}
                    </div>
                </ng-container>
                
            </ng-container>
            <ng-template #loading>
                <div class="loader-overlay">
                    <div class="loader"></div>
                </div>
            </ng-template>
            <ng-template #nothingToShow>
                <div class="d-flex justify-content-center align-items-center placeholder-text text-center" translate>
                    search_a_product_to_compare
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div class="alert alert-warning alert-dismissible invalid-comparision fade show" role="alert" *ngIf="!hasError && invalidFilter">
    <strong translate>minimum_two_sellers_required</strong>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<ng-template #quantityMatch let-modal>
    <div class="modal-header d-none">
        <h4 class="modal-title" id="modal-basic-title">Quantity Match</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-product-compare-modal [selectedProductList]="selectedProductList" [fullSellerOrder]="fullSellerOrder"></app-product-compare-modal>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')" translate>back_to_product_selection</button>
        <button type="button" class="btn btn-primary" (click)="moveToCart()" translate>continue</button>
    </div>
</ng-template>

