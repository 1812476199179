import { Component, OnInit } from '@angular/core';
import { SupportConstants } from './faq_constants';

// interface Faq {
//   ques: string;
//   ans: string;
// }

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  faqQuestions: any;
  searchText: string;

  constructor() { 
    this.faqQuestions = SupportConstants;
    this.searchText = '';
  }

  ngOnInit(): void {
  }

}
