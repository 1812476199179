import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Constants } from '../../shared/constants';
import { SavedListsModel, SearchedProductModel, SelectedProductsModel } from '../types/products.model';
import * as ProductActions from './products.actions';
// import ToDo from './todo.model';
// import ToDoState, { initializeState } from './todo.state';

// export const productStateFeatureKey = 'productListState';

export interface ProductListState {
    loading: boolean;
    searchedKeyword: string;
    searchedProductList: SearchedProductModel;
    selectedProductList: SelectedProductsModel;
    selectedDeliveryFilters: Array<string>;
    cartItems: any;
    filteredProductList: SearchedProductModel;
    favProductList: SearchedProductModel;
    savedList: SearchedProductModel;
    savedLists: SavedListsModel;
    loggedInUserData: any;
    savedListItems: any;
    userInfo: any;
    sitePrefrences: Array<string>;
    userPrefrences: any;
}

const initialState: ProductListState = {
    loading: false,
    searchedKeyword: '',
    searchedProductList: undefined,
    selectedProductList: null,
    selectedDeliveryFilters: null,
    cartItems: null,
    filteredProductList: null,
    favProductList: null,
    savedList: null,
    savedLists: null,
    loggedInUserData: null,
    savedListItems: null,
    userInfo: null,
    sitePrefrences: [],
    userPrefrences: {}
};

export const getProductListState = createFeatureSelector<ProductListState>('productListingReducer');

export const getLoadingStatus = createSelector(
    getProductListState,
    state => state.loading
);

export const getSearchedProductList = createSelector(
    getProductListState,
    state => state.searchedProductList
);

export const getSelectedProductList = createSelector(
    getProductListState,
    state => state.selectedProductList
);

export const getselectedDeliveryFilters = createSelector(
    getProductListState,
    state => state.selectedDeliveryFilters
);

export const getCartItems = createSelector(
    getProductListState,
    state => state.cartItems
);

export const getSearchedKey = createSelector(
    getProductListState,
    state => state.searchedKeyword
);

export const getFavProductList = createSelector(
    getProductListState,
    state => state.favProductList
);

export const getSavedList = createSelector(
    getProductListState,
    state => state.savedList
);

export const getSavedLists = createSelector(
    getProductListState,
    state => state.savedLists
);

export const getLoggedInUserData = createSelector(
    getProductListState,
    state => state.loggedInUserData
);

export const getSavedListItems = createSelector(
    getProductListState,
    state => state.savedListItems
);

export const getUserInfo = createSelector(
    getProductListState,
    state => state.userInfo
);

export const getUserSitePrefrences = createSelector(
    getProductListState,
    state => state.sitePrefrences
);

export const getUserPrefrencesData = createSelector(
    getProductListState,
    state => state.userPrefrences
);

const reducer = createReducer(
    initialState,
    on(ProductActions.GetProductList, (state: ProductListState, { payload }) => {
        return {
            ...state,
            searchedKeyword: payload,
            loading: true,
            searchedProductList: null
        };
    }),
    on(ProductActions.SuccessProductList, (state: ProductListState, { payload }) => {
        return { ...state, searchedProductList: payload, loading: false };
    }),
    on(ProductActions.FailureProductList, (state: ProductListState, { payload }) => {
        return { ...state, searchedProductList: payload, loading: false };
    }),
    on(ProductActions.ClearProductList, (state: ProductListState) => {
        return { 
            ...state,
            searchedProductList: null,
            selectedProductList: Constants.InitialSelectedProduct,
            cartItems: null
        };
    }),
    on(ProductActions.ClearSelectedProductList, (state: ProductListState) => {
        return { 
            ...state,
            selectedProductList: Constants.InitialSelectedProduct
        };
    }),
    on(ProductActions.UpdateSelectedProductList, (state: ProductListState, {payload}) => {
        return { ...state, selectedProductList: payload };
    }),
    on(ProductActions.UpdateCart, (state: ProductListState, {payload}) => {
        return { ...state, cartItems: payload };
    }),
    on(ProductActions.GetDeliveryOptionFilter, (state: ProductListState, {payload}) => {
        return { ...state, selectedDeliveryFilters: payload, loading: true };
    }),
    on(ProductActions.GetFavList, (state: ProductListState) => {
        return {
            ...state,
            loading: true
        };
    }),
    on(ProductActions.SuccessFavList, (state: ProductListState, { payload }) => {
        return { ...state, favProductList: payload, loading: false };
    }),
    on(ProductActions.FailureFavList, (state: ProductListState, { payload }) => {
        return { ...state, favProductList: payload, loading: false };
    }),
    on(ProductActions.GetSavedListItems, (state: ProductListState, { payload }) => {
        return {
            ...state,
            loading: true,
            savedListItems: null
        };
    }),
    on(ProductActions.SuccessSavedListItems, (state: ProductListState, { payload }) => {
        return { ...state, savedListItems: payload, loading: false };
    }),
    on(ProductActions.FailureSavedListItems, (state: ProductListState, { payload }) => {
        return { ...state, savedListItems: payload, loading: false };
    }),
    on(ProductActions.GetSavedLists, (state: ProductListState) => {
        return {
            ...state,
            loading: true,
            savedLists: null
        };
    }),
    on(ProductActions.SuccessSavedLists, (state: ProductListState, { payload }) => {
        return { ...state, savedLists: payload, loading: false };
    }),
    on(ProductActions.FailureSavedLists, (state: ProductListState, { payload }) => {
        return { ...state, savedLists: payload, loading: false };
    }),
    on(ProductActions.UpdateLoggedInUserData, (state: ProductListState, { payload }) => {
        return { ...state, loggedInUserData: payload };
    }),
    on(ProductActions.GetSavedLists, (state: ProductListState) => {
        return {
            ...state,
            loading: true,
            savedLists: null
        };
    }),
    on(ProductActions.SuccessSavedLists, (state: ProductListState, { payload }) => {
        return { ...state, savedLists: payload, loading: false };
    }),
    on(ProductActions.UpdateUserSitePrefrences, (state: ProductListState, { payload }) => {
        return {
            ...state,
            loading: true,
            sitePrefrences: payload
        };
    }),
    on(ProductActions.GetUserPrefrencesData, (state: ProductListState, { payload }) => {
        return {
            ...state,
            loading: true,
            
        };
    }),
    on(ProductActions.SuccessUserPrefrencesData, (state: ProductListState, { payload }) => {
        return { ...state, userPrefrences: payload, loading: false };
    }),
    on(ProductActions.FailureUserPrefrencesData, (state: ProductListState, { payload }) => {
        return { ...state, userPrefrences: payload, loading: false };
    }),
    // on(ProductActions.UpdateUserPrefrencesData, (state: ProductListState, { payload }) => {
    //     return {
    //         ...state,
    //         loading: true,
    //         userPrefrences: payload
    //     };
    // }),
    on(ProductActions.ClearCart, (state: ProductListState) => {
        return { 
            ...state,
            savedListItems: null,
            searchedProductList: null,
            selectedProductList: Constants.InitialSelectedProduct,
            cartItems: []
        };
    })
);

export function ProductListingReducer(state: ProductListState | undefined, action: Action): ProductListState{
    return reducer(state, action);
}
