import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NavItems } from './_navItems';
import * as ProductListingReducer from '../home/products/state/products.reducer';
import * as ProductActions from '../home/products/state/products.actions';
import * as _ from 'lodash';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HomeService } from '../home/home.service';
import { Router } from '@angular/router';
import { Constants } from '../home/shared/constants';
import { ProductsService } from '../home/products/products.service';
import { ProductDetailsModel, ProductList, ProductListModel, SavedListsModel } from '../home/products/types/products.model';
import { AppService } from '../services/app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoggedInUserModel } from '../auth/login/types/login.model';
import { TranslateConfigService } from '../services/translate-config.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';

interface SavedLists {
  savedLists : string[];
}
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit {
  productCategories: string[];
  deliveryOptions: string[];
  isMenuCollapsed: boolean;
  @Output() sidebarCollpase = new EventEmitter<boolean>();
  // @Output() sidebarCollpase = new EventEmitter<MouseEvent>()
  updateCartItems$: Observable<any>;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  
  selectedDeliveryFilters: Array<string>;
  selectedSellerFilters: Array<string>;
  filteredProductList$: Observable<any>;
  @ViewChild("mainSidebar", {read: ElementRef, static: true}) mainSidebar: ElementRef; // gets #target1
  @ViewChild("savedToogle", {read: ElementRef, static: true}) savedToogle: ElementRef; // gets #target1
  @HostListener('window:resize', ['$event'])
  scrWidth: number;
  cartItems: any;
  sellerOptions: any;
  disableSellerSelection: boolean;
  showAlert: boolean;
  filteredProductList: any;
  elementRef: any;
  loggedInUserData: any;
  saveList: Array<string>;
  favPage: boolean;
  userPrefrences: any;
  listItems: ProductListModel;
  selectedProduct: any;
  @Output() savedListSelected = new EventEmitter<boolean>();
  keyIndex: number;
  searchKeyWord: string;
  searchedProducts: any;
  savedListsData$: Observable<SavedListsModel>;
  listName: any;
  languageOptions: any;
  selectedLangugage: any;
  sellerNameConstant: any;
  loggedInUserData$: Observable<any>;
  loggedInUserDetails: LoggedInUserModel;
  savedListItemsData$: Observable<any>;
  cartItemArray: any[];
  shakeMe: boolean;
  expandPreview: boolean;
  expanded: boolean;
  isCartMenuCollapsed: boolean;
  @ViewChild("cartContainer", {read: ElementRef, static: true}) cartContainer: ElementRef; // gets #target1
  loggedInFlag: boolean;
  cartExpanded: any;
  isUpdatedCart: boolean;
  userSitePrefrencesData$: Observable<string[]>;
  sitePreference: any;
  messageDeleteProfile: string;
  selectedLanguage: string;
  languageOptionForm: any;
  signinPage: boolean;

  constructor(
    private service: HomeService,
    private store: Store<ProductListingReducer.ProductListState>,
    private readonly authService: AuthService,
    private _route: Router,
    private _productsService: ProductsService,
    private appService: AppService,
    private modalService: NgbModal,
    private translate: TranslateConfigService,
    private translateService: TranslateService
  ) {
    this.updateCartItems$ = this.service.updateCartItems$;
    this.filteredProductList$ = this.service.getFilteredProductList();
    this.selectedDeliveryFilters = [];
    this.selectedSellerFilters = [];
    this.disableSellerSelection = false;
    this.savedListsData$ = this.service.savedListsData$;
    this.languageOptions = Constants.LANGUAGE_OPTIONS;
    this.sellerNameConstant = Constants.SELLER_NAMES;
    this.loggedInUserData$ = this.service.loggedInUserData$;
    this.savedListItemsData$ = this.service.savedListItemsData$;
    // this.isCartMenuCollapsed = true;
    this.loggedInFlag = false;
    this.expandPreview = false;
    this.userSitePrefrencesData$ = this.service.userSitePrefrencesData$;
    this.languageOptions = Constants.LANGUAGE_OPTIONS;
    this.selectedLanguage = 'dk'
    this.languageOptionForm = new FormGroup({
      languageOption: new FormControl('dk')
    });
    localStorage.setItem('prefered_lang', this.selectedLanguage);
    this.updateFormValues(this.selectedLanguage);
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    const cartViewButton = document.getElementById('cartViewButton');
    const saveListButton = document.getElementById('savedToggle');

    // Check if the click was outside the element
    if ((targetElement && !this.mainSidebar?.nativeElement.contains(targetElement)) && !this.isMenuCollapsed) {
      this.isMenuCollapsed = true;
      this.appService.setMainMenuStatus(this.isMenuCollapsed);
      this.sidebarCollpase.emit(this.isMenuCollapsed);
    }
    if(targetElement &&  saveListButton?.contains(targetElement)){
      this.collapsePreviewMenu();
    }

    if((targetElement && (cartViewButton !== targetElement) && !this.cartContainer?.nativeElement.contains(targetElement)) && !this.isCartMenuCollapsed && !this.isUpdatedCart){
      this.isCartMenuCollapsed = true;
    }
    
  }

  ngOnInit(): void {
    this.isMenuCollapsed = true;
    this.getScreenSize();
    this.deliveryOptions = _.cloneDeep(NavItems.DELIVERYOPTIONS);
    // this.sellerOptions = _.cloneDeep(NavItems.SELLERSLIST);
    this.sellerOptions = [];
    this.selectedLangugage = localStorage.getItem('prefered_lang') !== 'null' ? localStorage.getItem('prefered_lang') : 'dk';

    this.filteredProductList$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      filteredProductList => {
        this.filteredProductList = filteredProductList;
        const sellerCountArray = [];
        Object.keys(this.filteredProductList).map(filter => {
          const index = _.findIndex(this.selectedSellerFilters, (seller) => { return _.isMatch(seller, filter) });
          if (index > -1 && this.filteredProductList[filter].length !==0) {
            sellerCountArray.push(filter);
          }
        })
        
        
        if (sellerCountArray.length <= 2){
          this.disableSellerSelection = true;
          // this.showAlert = true;
        }else{
          this.disableSellerSelection = false;
          // this.showAlert = false;
        }
      }
    );

    this.authService.getLoggedInUserinfo().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(loggedInUser => {
      this.loggedInUserData = loggedInUser;
      let userInfo = {
        loggedInUserData : this.loggedInUserData,
        userPrefrence : this.userPrefrences
      }
      this.service.setRegisterMessageStatus(false);
      this.store.dispatch(ProductActions.UpdateLoggedInUserData({payload: userInfo}));
      this.service.setUserInfo(this.loggedInUserData);
      this.selectByDefault(this.deliveryOptions, 'delivery');
      
      this.getUserPrefrences();
      // console.log(this.loggedInUserData);
      var retrievedObject = localStorage.getItem('cachedCart');
      this.cartItems =  JSON.parse(retrievedObject)?.cartItems ? JSON.parse(retrievedObject).cartItems : [];
      this.updateCart(this.cartItems);
      this.isCartMenuCollapsed = true;
    });
    
    // get updated saveList 
    this.service.getSaveList().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(savedList => {
      if(this.saveList.indexOf(savedList[0]) < -1){
        this.saveList.push(savedList);
        // console.log(this.saveList);
        this.service.setAllSavedList(this.saveList);
      }
      
    });

    this.updateCartItems$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.cartItems = data;
        setTimeout(() => {
          this.shakeMe = true;
        }, 1500);
        setTimeout(() => {
          // this.flashToCart = false;
          this.shakeMe = false;
        }, 2000);
        this.isCartMenuCollapsed = false;
        this.isUpdatedCart = true;
        setTimeout(() => {
          this.isUpdatedCart = false;
        }, 1000);
      }
    );

    this.savedListsData$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(allSavedList => {
      this.saveList = allSavedList.savedLists;
    });

    this.loggedInUserData$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.loggedInUserData = data.loggedInUserData;
      }
    );

    this.userSitePrefrencesData$.pipe(filter(userSitePrefrencesData => userSitePrefrencesData !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      userSitePrefrencesData => {
        this.service.getUserPreferencesData().subscribe(userPrefrences => {
          this.userPrefrences = userPrefrences;
          const selectedSellerLocal = localStorage.getItem('selectedSellers');
          if(this.userPrefrences?.userInfo){
            this.sitePreference = userSitePrefrencesData.length > 0 ? _.cloneDeep(userSitePrefrencesData) : _.cloneDeep(this.userPrefrences.userInfo.site_preference);
            // this.loggedInFlag = this.userPrefrences.userInfo.userValid;
            this.resetUserPreferenceSelection();
            this.selectPreferedLanguage(this.userPrefrences.userInfo.preferedLanguage ? this.userPrefrences.userInfo.preferedLanguage : this.selectedLangugage);
            // this._route.navigate(['home']);
            // if(!_.isEqual(this.userPrefrences.userInfo.site_preference, userSitePrefrencesData)){
            //   this.userPrefrences.savedLists = [];
            // }
          } else if (JSON.parse(selectedSellerLocal).length){
            this.sitePreference = _.cloneDeep(JSON.parse(selectedSellerLocal));
            this.resetUserPreferenceSelection();
          }
        });
      }
    );

    this.savedListItemsData$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(listItems => {
      let cartItemArray = this._productsService.arrangeCartItems(listItems);
      this._productsService.setSavedListTriggered(this.listName);
      this.updateCart(cartItemArray);
      localStorage.setItem('cachedCart', JSON.stringify({'cartItems': cartItemArray}));
      this._route.navigate(['searchresults']);
    });

    this.appService.getCartMenuStatus().subscribe(menuStatus => {
      this.expandPreview = menuStatus;
    });
    
  }

  public collapsePreviewMenu(): void {
    this.expandPreview = false;
    this.isCartMenuCollapsed = !this.isCartMenuCollapsed;
  }

  public updateCart(cartItemsList): void {
    this.store.dispatch(ProductActions.UpdateCart({payload: cartItemsList}));
  }

  public deleteSaveList(listName, modalName): void{
    this.modalService.open(modalName, { size: 'md' });
    this.listName = listName;
  }

  public resetUserPreferenceSelection(): void{
    this.sellerOptions = [];
    // this.store.dispatch(ProductActions.ClearCart());
    // localStorage.removeItem('cachedCart');
    this.selectedSellerFilters = [];
    this.selectByDefault(NavItems.SELLERSLIST, 'seller');
  }

  public getUserPrefrences(): void{
    this.service.getUserPrefrences( this.loggedInUserData.email).pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(userPrefrences => {
      this.userPrefrences = userPrefrences;
      if (this.userPrefrences?.userInfo) {
        this.service.setUserPremiumSites(this.userPrefrences.userInfo.premiumSites);
        this.loggedInFlag = this.userPrefrences.userInfo.userValid;
        let selectedSellerLocal = localStorage.getItem('selectedSellers');
        if (!this.userPrefrences.userInfo.site_preference) {
          this.sitePreference = JSON.parse(selectedSellerLocal);
        } else {
          localStorage.setItem('selectedSellers', JSON.stringify([]));
          this.sitePreference = this.userPrefrences.userInfo.site_preference;
        }
        // localStorage.setItem('selectedSellers', JSON.stringify([]));
        
        this.userDetails();
        this.resetUserPreferenceSelection();
        this.selectPreferedLanguage(this.userPrefrences.userInfo.preferedLanguage);
        this.saveList = this.userPrefrences.savedLists;
        this.service.setAllSavedList(this.saveList);
      }
    });
  }

  public userDetails(): void{
    this.loggedInUserDetails = {
      displayName : '',
      preferedLanguage: localStorage.getItem('prefered_lang')
    }
    if(this.userPrefrences.userInfo){
      let userName = this.userPrefrences.userInfo.firstName + ' ' + this.userPrefrences.userInfo.lastName;
      this.loggedInUserDetails.displayName = userName ? userName : this.loggedInUserData.displayName;
      this.selectedLangugage = this.selectedLangugage ? this.selectedLangugage : this.languageOptions[this.userPrefrences.userInfo.preferedLanguage];
    }
    let userInfo = {
      loggedInUserData : this.loggedInUserData,
      userPrefrence : this.userPrefrences
    }
    this.store.dispatch(ProductActions.UpdateLoggedInUserData({payload: userInfo}));
  }

  public selectByDefault(options, key): void{
    options.map(option => {
      switch (key) {
        case 'delivery':
          this.selectDeliveryOptions(option, true);
          break;
      
        case 'seller':
          this.selectSellerOptions(option, true);
          break;
        default:
          break;
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sellerOptions, event.previousIndex, event.currentIndex);
    let orderedArray = this.sellerOptions.map(seller => {
      return seller.name;
    });
    this.service.setSellerFilterOrder(orderedArray);
  }


  getScreenSize(event?) {
    this.scrWidth = window.innerWidth;
    if (this.scrWidth < 768){
      this.isMenuCollapsed = true;
    }
      console.log(this.scrWidth);
  }

  sidebarToggle(event): void{
    event.stopPropagation();
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.sidebarCollpase.emit(this.isMenuCollapsed)
  }

  public selectDeliveryOptions(option, isChecked: boolean){
    let deliveryFilters: any = _.cloneDeep(this.selectedDeliveryFilters);
    if (isChecked){
      option.selected = isChecked;
      deliveryFilters.push(option.id);
    }else{
      const index = deliveryFilters.indexOf(option.id);
      if (index > -1) {
        option.selected = isChecked;
        deliveryFilters.splice(index, 1);
      }
    }
    this.selectedDeliveryFilters = deliveryFilters;
    this.service.updateDeliveryFilters(deliveryFilters);
    // this.store.dispatch(ProductActions.UpdateSelectedDeliveryOptions({payload: deliveryFilters}));
  }

  public selectSellerOptions(option, isChecked: boolean){
    let sellerFilters: any = _.cloneDeep(this.selectedSellerFilters);
    let sellerVisibilityIndex = _.findIndex(this.sitePreference, (seller) => { return _.isMatch(seller, option.name) });
    if(sellerVisibilityIndex > -1){
      if (isChecked){
        let sellerOption = option;
        sellerOption['selected'] = isChecked;
        sellerFilters.push(option.name);
        let sellerVisibilityIndex = _.findIndex(this.sellerOptions, (seller) => { return _.isMatch(seller.name, option.name) });
        if(sellerVisibilityIndex === -1){
          this.sellerOptions.push(sellerOption);
        }else{
          this.sellerOptions[sellerVisibilityIndex].selected = isChecked;
        }
        if (sellerFilters.length > 2){
          this.disableSellerSelection = false;
          // this.showAlert = false;
        }
      }else{
        const index = _.findIndex(sellerFilters, (seller) => { return _.isMatch(seller, option.name) });
        if (index > -1) {
          option.selected = isChecked;
          sellerFilters.splice(index, 1);
        }
      }
    }

    let nonEmpty= [];
    if (this.filteredProductList){
      nonEmpty = Object.keys( _.pickBy(this.filteredProductList, value => value.length > 0));
    }
    this.selectedSellerFilters = nonEmpty.length > 0 ? _.intersection(nonEmpty, sellerFilters) : sellerFilters;
    if (this.selectedSellerFilters.length <= 2){
      this.disableSellerSelection = true;
      // this.showAlert = true;
    }
    this.selectedSellerFilters = sellerFilters;
    this.service.updateSellerFilters(sellerFilters);
  }

  public closeAlert(): void{
    // this.showAlert = false;
  }

  public favProductList(): void{
    this.store.dispatch(ProductActions.GetFavList(null));
    // this.favProductListTriggered.emit(true);
  }

  public signout(): void{
    this.authService.SignOut();
    this.loggedInFlag = false;
    this._route.navigate(['signin']);
  }

  public getSavedListItems(listName): void{
    this.cartItems = [];
    this.listName = listName;
    this.store.dispatch(ProductActions.GetSavedListItems({payload: listName}));
    this.store.dispatch(ProductActions.ClearCart());
    
  } 

  public selectProduct(product: ProductDetailsModel): void{
    let selectedProduct: ProductList = _.cloneDeep(this.selectedProduct);
    selectedProduct[product.site].selectedProductList.splice(0, 1, product);
    selectedProduct[product.site].selectedQuantity = selectedProduct[product.site].selectedQuantity + 1;
    this._productsService.productSelection(selectedProduct[product.site]);
    this.selectedProduct = {...selectedProduct};
    this.updateAmount(selectedProduct);
  }

  public updateAmount(selectedProduct: ProductList): void{
    let selectedProducts = this._productsService.updateAmount(selectedProduct);
    this.updateSelectedCartItems(selectedProducts);
  }

  public updateSelectedCartItems(selectedProduct): void{
    console.log("final:",selectedProduct);
    this.store.dispatch(ProductActions.UpdateSelectedProductList({payload: selectedProduct}));
  }

  public updateFormValues(selectedLanguage): void{
    const formvalue = {
      languageOption: selectedLanguage
    }
    this.languageOptionForm.patchValue(formvalue);
  }


  public selectPreferedLanguage(language): void{
    // this.selectedLangugage = this.languageOptions[language];
    // localStorage.setItem('prefered_lang', language);
    // this.translate.changeLanguage(language);

    this.updateFormValues(language?.value?.languageOption ? language.value.languageOption : language);
    localStorage.setItem('prefered_lang', language?.value?.languageOption ? language.value.languageOption : language);
    this.translate.changeLanguage(language?.value?.languageOption ? language.value.languageOption : language);
  }

  public openCart(flag): void{
    this.isCartMenuCollapsed = flag;
  }

  public deleteProfile(modalName): void{
    this.messageDeleteProfile = this.translateService.instant('you_are_about_to_delete');
    this.modalService.open(modalName, { size: 'sm' });
  }

  public confirmProfileDelete(): void{
    this.service.deleteUserProfile(this.userPrefrences.userInfo.emailid).pipe(takeUntil(this.ngUnsubscribe)).subscribe(deleteProfileResponse => {
      if (deleteProfileResponse.response === 'Success'){
        // this.messageDeleteProfile = 'Successfully deleted!';
        this.signout();
        setTimeout(() => {
          this.modalService.dismissAll();
          
        }, 3000);
      }else{
        this.messageDeleteProfile = 'Something went wrong!';
      }
    });
    console.log('account deleted');
  }

  public confirmDeleteSavedList(): void{
    this.service.deleteSavedLists(this.listName).pipe(takeUntil(this.ngUnsubscribe)).subscribe(deleteSavedListResponse => {
      if (deleteSavedListResponse.response === 'Success'){
        this.modalService.dismissAll();
        let listIndex = this.saveList.indexOf(this.listName);
        if (listIndex > -1) {
          this.saveList.splice(listIndex, 1);
          this.service.setAllSavedList(this.saveList);
        }
      }else{
        this.modalService.dismissAll();
      }
    });
    console.log('savedlist deleted');
  }

  ngDoCheck() {
    this.signinPage = this._route.url === '/signin';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
