<div class="alert alert-warning alert-dismissible fade show mb-0 text-center p-1" role="alert" *ngIf="showLoginRequiredMsg">
    <strong translate>user_not_registered_message</strong> &nbsp;&nbsp;
    <button class="btn btn-sm btn-success login-btn" (click)="openLoginModal(loginModal)" type="button" data-dismiss="alert" aria-label="viSave Login">
        <i class="fa fa-user-o" aria-hidden="true"></i> <span translate> login</span>
    </button>
</div>
<app-navbar (sidebarCollpase)=expandMain($event)></app-navbar>
<!-- <div class="fb-login-button" data-width="" data-size="large" data-button-type="continue_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div> -->
<router-outlet></router-outlet>

<ng-template #loginModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title" translate>login</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-login-modal></app-login-modal>
    </div>
</ng-template>