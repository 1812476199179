<div class="login-wrapper">
    <div class="login-banner">
        <div class="row">
            <div class="col-12 banner-text text-center" innerHtml="{{'overpaying_for_groceries' | translate}}">
            </div>
            <div class="col-sm-12 text-center">
                <!-- <div class="lang-selection mt-5 mb-4">
                    <form class="form-inline d-flex justify-content-center align-items-center" [formGroup]="languageOptionForm">
                        <fieldset *ngFor="let language of languageOptions | keyvalue">
                            <div class="form-check form-check-inline" >
                                <input class="form-check-input" [id]="language.key" type="radio" formControlName="languageOption" value="{{language.key}}" (change)="selectPreferedLanguage(languageOptionForm)">
                                <label class="form-check-label" [for]="language.key" [title]="language.value">
                                    {{ language.value }} 
                                </label>
                            </div>
                        </fieldset>
                    </form>
                </div> -->
                <button class="btn btn-success login-btn my-3 mr-3" (click)="loginAsGuest(sellerSelection)" type="button" data-dismiss="alert" aria-label="viSave Login">
                    <i class="fa fa-user-o" aria-hidden="true"></i> <span translate> try_me</span>
                </button>
                <button class="btn btn-success login-btn my-3" (click)="openLoginModal(loginModal)" type="button" data-dismiss="alert" aria-label="viSave Login">
                    <i class="fa fa-user-o" aria-hidden="true"></i> <span translate> login</span>
                </button>
            </div>
            <div class="col-12">
                <div class="video-wrapper">
                    <video width="100%" controls>
                        <source src="../../../assets/images/app_intro.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                    <div class="video-shadow"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="services">
        <div class="row">
            <div class="col-sm-3 col-xs-12">
                <div class="card align-items-center">
                    <img class="card-img-top" src="../../../assets/images/search.png" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title" translate>step_1</h5>
                        <p class="card-text" translate>step_1_desc</p>
                        
                    </div>
                    </div>
            </div>
            <div class="col-sm-3 col-xs-12">
                <div class="card align-items-center">
                    <img class="card-img-top" src="../../../assets/images/compare.png" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title" translate>step_2</h5>
                        <p class="card-text" translate>step_2_desc</p>
                        
                    </div>
                    </div>
            </div>
            <div class="col-sm-3 col-xs-12">
                <div class="card align-items-center">
                    <img class="card-img-top" src="../../../assets/images/save.png" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title" translate>step_3</h5>
                        <p class="card-text" innerHtml="{{'step_3_desc' | translate}}"></p>
                        
                    </div>
                    </div>
            </div>
            <div class="col-sm-3 col-xs-12">
                <div class="card align-items-center">
                    <img class="card-img-top" src="../../../assets/images/shop.png" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title" translate>step_4</h5>
                        <p class="card-text" translate>step_4_desc</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="disclaimer">
    <div class="container">
        <div class="row">
            <div class="col text-center py-4">
                <img src="../../assets/images/viSave_logo.png" alt="viSave Logo" width="110px" height="30px">
            </div>
        </div>
        <div class="row justify-content-md-center">
            <div class="col-sm-8 col-xs-12 pb-5 text-center" innerHtml="{{'about_us' | translate}}">
            </div>
        </div>
    </div>
</div>
<div class="footer-section">
    <div class="container">
        <div class="row">
            <div class="col-md-3"><img src="../../assets/images/viSave_logo.png" alt="viSave Logo" width="110px" height="30px"></div>
            <div class="col-md-3"><strong><a [routerLink]="['../aboutus']" translate> About Us</a></strong><br /><span class="content" translate>aboutuspageshort</span></div>
            <div class="col-md-3"><strong>Contact Us</strong><br /><span class="content">Robert Jacobsens Vej 18H<br/>Kobenhaven S<br/>Denmark<br/>2300<br/><br/>Email: visavehelp@gmail.com<br/>CVR # 32 50 21 05</span></div>
            <div class="col-md-3"><strong>Share Us On</strong><br /><span class="content">Coming soon..</span></div>
        </div>
    </div>
</div>
<div class="copyright text-center"><small>&copy; Copyright {{currentYear}}, viSave. All Rights Reserved </small></div>

<ng-template #loginModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title" translate>login</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-login-modal></app-login-modal>
    </div>
</ng-template>

<ng-template #sellerSelection let-modal>
    <div class="modal-header">
      <h6 class="modal-title" id="modal-basic-title" translate>sellect_seller_try</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <form [formGroup]="sellerSelectionForm">
                    <div *ngFor="let seller of sellerSelectionForm.get('preferedSellers')['controls']; let i=index; let lastItem = last;"
                        class="form-check">
                        <input class="form-check-input" [id]="sellersList[i].key" type="checkbox" [formControl]="seller" (change)="onCheckboxChange(sellersList[i].key);">
                        <label class="form-check-label" [for]="sellersList[i].key">{{sellersList[i].value}}</label> &nbsp;&nbsp;<span *ngIf="!lastItem"></span>    
                    </div>
                </form>
            </div> 
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="this.selectedSeller?.length < 2" class="btn btn-primary" (click)="continueAndProceed()" translate>continue</button>
    </div>
</ng-template>
