<div class="row">
    <div class="col text-center flex-1 align-self-center">
        <h6 translate>connect_with_us</h6>
        <div class="login-block pt-1 pb-3"> 
            <ul class="firebaseui-idp-list">
                <li class="firebaseui-list-item">
                    <button class="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button" (click)="appSignin('google')" data-provider-id="google.com" style="background-color:#ffffff" data-upgraded=",MaterialButton">
                        <span class="firebaseui-idp-icon-wrapper">
                            <img class="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg">
                        </span>
                        <span class="firebaseui-idp-text firebaseui-idp-text-long" translate>sign_in_with_google</span>
                    </button>
                </li>
                <li class="firebaseui-list-item">
                    <button [disabled]="true" class="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-facebook firebaseui-id-idp-button" (click)="appSignin('facebook')" data-provider-id="facebook.com" style="background-color:#3b5998;cursor:not-allowed;" data-upgraded=",MaterialButton">
                        <span class="firebaseui-idp-icon-wrapper">
                            <img class="firebaseui-idp-icon" alt="" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/facebook.svg">
                        </span>
                        <span class="firebaseui-idp-text firebaseui-idp-text-long" translate>sign_in_with_facebook</span>
                    </button>
                </li>
            </ul>
        </div>
    </div> 
</div>