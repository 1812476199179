import { Component, NgZone, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/home/shared/constants';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import * as ProductListingReducer from '../../home/products/state/products.reducer';
import * as ProductActions from '../../home/products/state/products.actions';
import { HomeService } from 'src/app/home/home.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  languageOptions: Object;
  languageOptionForm: FormGroup;
  selectedLanguage: string;
  currentYear: number;
  sellerSelectionForm: FormGroup;
  sellersList: any[];
  selectedSellersNames: any;
  selectedSeller: any;

  constructor(
    private readonly authService: AuthService,
    private readonly translate: TranslateConfigService,
    private modalService: NgbModal,
    public router: Router,  
    public ngZone: NgZone,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private store: Store<ProductListingReducer.ProductListState>,
    private _homeService: HomeService, 
  ) { 
    this.languageOptions = Constants.LANGUAGE_OPTIONS;
    // this.sellerNameConstant = Constants.SELLER_NAMES;
    this.selectedLanguage = 'dk'
    // this.sellerSelectionForm = new FormGroup({
    //   sellerSelection: new FormControl()
    // });
    localStorage.setItem('prefered_lang', this.selectedLanguage);
    // localStorage.setItem('prefered_lang', this.selectedLanguage);
    // this.updateFormValues(this.selectedLanguage);
    var d = new Date(); 
    this.currentYear = d.getFullYear(); 
    this.sellerSelectionForm = this.formBuilder.group({
      preferedSellers: this.createSellerList(Constants.SELLER_NAMES),
    });
  }

  public createSellerList(sellersArray) {
    this.sellersList = [];
    
    for (let key of Object.keys(sellersArray)) {
      let seller = {
        key: key,
        value: sellersArray[key]
      }
      this.sellersList.push(seller);
    }
    const arr = this.sellersList.map(seller => {
      return new FormControl(seller.selected || false);
    });
    return new FormArray(arr);
  }

  // public updateFormValues(selectedLanguage): void{
  //   const formvalue = {
  //     languageOption: selectedLanguage
  //   }
  //   this.languageOptionForm.patchValue(formvalue);
  // }

  ngOnInit(): void {
    // this.languageOptionForm.patchValue({languageOption: this.selectedLanguage});
  }

  // public selectPreferedLanguage(languageOptionForm): void{
  //   this.updateFormValues(languageOptionForm.value.languageOption);
  //   localStorage.setItem('prefered_lang', languageOptionForm.value.languageOption);
  //   this.translate.changeLanguage(languageOptionForm.value.languageOption);
  // }



  private getSelectedSellers() {
    this.selectedSellersNames = _.map(
      this.sellerSelectionForm.controls.preferedSellers["controls"],
      (seller, i) => {
        return seller.value && this.sellersList[i].key;
      }
    );
    // this.getSelectedSellersName();
  }

  public onCheckboxChange(e) {
    this.getSelectedSellers();
    this.selectedSeller = this.selectedSellersNames.filter(Boolean);
    if(this.selectedSellersNames.filter(Boolean).length === 4 ){
      this.selectedSellersNames.some((seller, index) => {
        if(!seller){
          this.sellerSelectionForm.controls.preferedSellers['controls'][index].disable();
        }
      })

    }else{
      this.sellerSelectionForm.controls.preferedSellers['controls'].some((seller, index) => {
        if(seller.disabled){
          this.sellerSelectionForm.controls.preferedSellers['controls'][index].enable();
        }
      })
    }
    // this.sellerValidator();
  }

  public continueAndProceed(): void{
    this.modalService.dismissAll();
    localStorage.setItem('selectedSellers', JSON.stringify(this.selectedSeller));
    this.store.dispatch(ProductActions.UpdateUserSitePrefrences({payload: (this.selectedSeller)}));
    this.router.navigate(['home']);
    this._homeService.setRegisterMessageStatus(true);
  }

  public openLoginModal(modalName): void{
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      this.router.navigate(['device-error']);
    } else{
      this.modalService.open(modalName, { size: 'sm' });
    }
    
  }


  public loginAsGuest(modalName): void{
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      this.router.navigate(['device-error']);
    } else {
      localStorage.setItem('selectedSellers', JSON.stringify([]));
      this.modalService.open(modalName, { size: 'sm' });
    }
    
  }
}
