<div class="container">
    <div class="row">
        <div class="col-12">
          <h6 class="page-title mt-3 pb-1">Privacy Policy</h6>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 privacy-block">
            <h6>Handelsbetingelser</h6> 
            <p>Følgende vilkår gælder for alle ydelser og produkter, der bliver solgt og leveret gennem ARN og andre hjemmesider, der administreres af ARN. Handelsbetingelserne gælder for enhver opgave, ARN påtager sig.  
            </p>
            <p>
            Din adgang til og brug af hjemmesiden udgør samtykke til at være bundet af disse betingelser, som etablerer et kontraktforhold mellem dig og ARN. Hvis du ikke giver samtykke til vilkårene, må du ikke tage adgang til eller bruge hjemmesiden. 
            </p>
            <p>
            ARN kan fra en tid til anden ændre vilkårene. Ændringerne i vilkårene vil træde i kraft, efter ARN slår sådanne opdaterede vilkår op på rette sted. Din fortsatte adgang til eller brug af hjemmeside eller køb af vores produkter efter sådanne opslag udgør samtykke til at være bundet af de ændrede vilkår. 
            </p> 
            <h6>Generelle oplysninger</h6> 
            <p>ARN
            CVR: 32 50 21 05<br/>
            Robert Jacobsens Vej 18H,<br/>
            2300, København S<br/>
            E-mail: <a href="mailto:visavehelp@gmail.com">visavehelp@gmail.com</a><br/>
            Tlf.nr.: +45 213 58 213<br/>
            </p>
            <p>
            ARN er formidler af en tjeneste der muliggør sammenligning af priser inden for dagligvarer-segmentet. ARN tilbyder services og løsninger inden for planlægning og køb af dagligvarer, gennem sin digitale IT-platform.
            </p>
            <h6>Priser og betaling</h6> 
            <p>Den gældende pris er den, som på dagen for bestillingen er angivet på det pågældende produkt. Alle priser er inklusiv moms og angives i DKK.  
            </p>
            <p>ARN kan frit ændre de oplyste priser uden forudgående varsel. Er der sket en trykfejl og køber burde vide eller ved, at der er tale om en trykfejl, kan køber ikke få den trykfejlede pris. ARN kan til hver en tid annullere en ordre, der er sket ved en trykfejl på prisen.  
            <br/>
            Alle ordrer tillægges fragtomkostninger. Ved bestilling kan du se den endelig pris, hvilket er inklusive moms, afgifter og eventuelle fragtomkostninger. 
            </p> 
            <p>Hos ARN kan der betales med betalingskort og bankoverførsel. ARN trækker først beløbet, når ordren er klar. Når du er tilmeldt abonnement på vores services, vil det relevante beløb blive trukket fra din konto månedligt.
            </p>
            <h6>Fortrolighed</h6>
            <p>Hos ARN mener vi, at tillid og fortrolighed er afgørende for et godt samarbejde. Alle oplysninger modtaget fra dig eller om dig vil blive behandlet fortroligt, og alle medarbejdere er underlagt tavshedspligt. 
            </p>
            <h6>Ansvar</h6>
            <p>ARN er ikke ansvarlige for nedbrud eller midlertidige afbrydelser på vores hjemmeside, nedbrud i strømforsyning eller internetforbindelse, hærværk på systemet (både fysisk såvel som computervirus og hacking), misbrug af personoplysninger eller andre forhold og omstændigheder, der er uden for ARN kontrol. Det kan ikke garanteres, at du kan anvende downloadede filer på alle computere, og ved brugen af filerne vil det altid formodes, at du er i besiddelse af en software, som kan åbne/læse den relevante fil.
            </p>
            <h6>Produktansvar</h6> 
            <p>For sådanne skader, som umiddelbart er omfattet af lov nr. 481 af 7. juni 1989 om produktansvar, gælder lovens bestemmelser. 
            <br/>For så vidt angår produktansvar, der ikke er omfattet af ovennævnte lovs bestemmelser, gælder følgende begrænsninger:
            </p>
            <ul>
                <li>
                    ARN er kun ansvarlig for personskade, hvis det bevises, at skaden skyldes fejl eller forsømmelse begået af ARN eller andre, som ARN har ansvaret for. 
                </li>
                <li>
                    ARN er ansvarlig for skade på fast ejendom og løsøre på samme betingelser som for personskade.
                </li>    
            </ul>
            <p>
            ARN og kunden er gensidigt forpligtet til at lade sig sagsøge ved den domstol eller voldgiftsret, som behandler erstatningskrav, der er rejst mod en af dem på grundlag af en skade, som påstås forårsaget af materiellet.
            </p>
            <h6>Levering</h6> 
            <p>Kunden gives adgang til vores online IT-platform så snart, betalingen er foretaget, hvorfor levering sker umiddelbart efter købet. 
            <br/>Hvis leverancen er forsinket, vil vi ARN underrette kunden herom pr. e-mail.  
            </p>
            <h6>Fortrydelsesret</h6> 
            <p>Du har 14 dages fortrydelsesret, når du handler hos os.  
            <br/>Fortrydelsesfristen udløber 14 dage efter den dag du har købt adgang til vores tjenester. 
            <br/>Du skal inden 14 dage fra adgangen, give os meddelelse om, at du ønsker at fortryde købet. Meddelelsen skal gives ved mail på: pushpendra.st@gmail.com eller via Telefon: +45 213 58 213
             </p>
             
            <h6>Fejl og Mangler</h6>
            <p>Såfremt du oplever fejl eller mangler ved dit køb, vil disse udbedres efter følgende procedure: Såfremt du oplever væsentligt store problemer med vores søgemaskine, navnlig i de tilfælde hvor priserne på vores platform ikke er i overensstemmelse med en given forhandlers priser, vil vi refundere en fuld måneds betaling for tjenesten, til din konto.
            </p>
            <p>Retten til at få tilbagebetaling gælder ikke når fejlen opstår som følge af fejlagtig brug af vores tjenester, herunder fejl grundet inkompatibel software hos brugeren.
            </p>
            <h6>Tilbagebetaling af købsbeløbet</h6>
            <p>
            Hvis du fortryder dit køb, får du det fulde beløb tilbage. Det gælder dog ikke i følgende tilfælde: Når fejl og mangler ved vores tjeneste skyldes forkert brug af denne. Når der opstår midlertidige forstyrrelser på vores platform som følge af tekniske problemer, herunder midlertidige fejl af angivne priser og varer på vores platform.
            </p> 
            <p>Vi refunderer din betaling senest 14 fra den dato, hvor vi har modtaget meddelelse om din beslutning om at fortryde denne aftale.
            </p>
             
            <h6>Persondatapolitik</h6> 
             
            <p>For at du kan indgå en aftale med os og handle på viSave.net har vi brug for følgende oplysninger om dig: Navn, E-mail, samt adresseoplysninger (postkode og land). 
            <br/>Behandlingen sker efter reglerne i vores Persondatapolitik. 
            </p>
            <h6>Lovvalg og værneting</h6> 
            <p>
            Enhver tvist der udspringer af dit køb gennem ARN samt på hjemmesiden eller heraf følgende tvister, herunder disse handelsbetingelers omfang eller gyldighed, er underlagt dansk ret og skal indbringes for Københavns byret.
            </p> 
             
            <h6>Klageadgang</h6>  
            <p>Hvis du vil klage over dit køb, skal du rette henvendelse til vores Kundeservice på pushpendra.st@gmail.com 
            <br/>Har vi ikke fundet en løsning sammen, har du mulighed for at indgive en klage til Center for Klageløsning; 
            </p>
            <p>
                Nævnenes Hus 
                <br/>Toldboden 2 
                <br/>Viborg 8800 
            </p> 
            <p>Du kan klage til Center for Klageløsning via www.forbrug.dk</p>  
            <p> 
            EU-Kommissionens online klageportal kan også anvendes ved indgivelse af en klage. Det er særlig relevant, hvis du er forbruger med bopæl i et andet EU-land. Klage indgives her: http://ec.europa.eu/odr. Ved indgivelse af en klage skal du angive vores e-mailadresse pushpendra.st@gmail.com
            </p> 
            <h6>Privatlivspolitik</h6>
            
            <h6>Generelt</h6>
            <p>Denne persondatapolitik redegør for, hvordan vi anvender og behandler de personoplysninger, som vores kunder giver til os samt de personoplysninger som Visave.net indsamler, når du benytter vores hjemmesider.
            <br/>
            Hos Visave.net respekterer og beskytter vi dine personlige oplysninger og deler dem ikke med en tredjemand. Visave.net respekterer alle ønsker om hemmeligholdelse af personoplysninger og er opmærksomme på, at alle personoplysninger behandles forsvarligt og hensigtsmæssigt.
            </p>
            <h6>Personlige oplysninger</h6>
            <p>En personoplysning er enhver form for information, der kan henføres til fysiske personer, selvom dette forudsætter kendskab til eksempelvis adresse, CPR-nummer mv.
            <br/>Der sondres mellem to typer af personoplysninger, almindelige oplysninger og følsomme oplysninger. 
            <br/>Følsomme oplysninger kan være oplysninger om etnisk oprindelse, politisk, race, religiøs eller filosofisk overbevisning eller fagforeningsmæssigt tilhørsforhold, samt behandling af genetiske eller biometriske data med det formål entydigt at identificere en fysisk person, vedkommendes helbredsoplysninger eller seksuelle orientering.
            </p>
            <h6>Ikke-personlige oplysninger</h6>
            <p>Vi kan indsamle ikke-personlige oplysninger om Brugere, når de besøger vores hjemmeside. Ikke-personlige oplysninger kan blandt andet omfatte computertype, browser-navn og tekniske oplysninger om Brugerens forbindelsesmåde på hjemmesiden, eksempelvis internetudbyder, styresystem og andre lignende oplysninger.
            </p>
            <h6>Web browser cookies</h6>
            <p>Denne hjemmeside benytter ”cookies” for at forbedre Brugernes oplevelse. Brugerens webbrowser gemmer cookies på sin harddisk til registreringsformål og til tider for at tackle oplysninger om dem. Disse oplysninger hentes kun, når brugeren besøger hjemmesiden, og vil blive brugt ved brugerens følgende besøg på hjemmesiden, således brugeren kan blive identificeret anonymt. De primære formål er som følger:
            <br/>Muliggør anonym identifikation af brugeren ved hjælp af cookies (identificere browsers og enheder, ikke individer). Dermed gives der et anslået antal af besøg og brugerens gennemsnitlige besøgstid på hjemmesiden.
            </p>
            <p>Anonym identifikation af det mest besøgte materiale hos brugeren, og således det mest attraktive for brugeren.
            </p>
            <p>Fastslå hvorvidt det er første gang brugeren tilgår hjemmesiden eller om brugeren har besøgt hjemmesiden før.
            </p>
            <p>Medmindre brugeren registrerer sig selv til en webservice, bliver cookies aldrig brugt til at identificere personlige data. Cookies er udelukkende til statistiske årsager. De fungerer som assistance til at forbedre brugerens oplevelse af hjemmesiden.
            </p>
            <p>Brugere kan vælge at sætte deres browser til at afvise cookies eller varsle Brugeren, når cookies bliver sendt. Brugeren skal dog være opmærksom på, at visse dele af Hjemmesiden herefter kan have nedsat funktionalitet.
            </p>
            <h6>Hvilke personoplysninger indsamler vi?</h6>
            
            <p>Vi kan indsamle personlige oplysninger fra Brugere på en række forskellige måder, inklusiv men ikke begrænset til, når Brugere gennemfører et køb, betaler for et produkt eller andre aktiviteter på vores hjemmeside. Brugere kan blive spurgt om navn, telefonnummer, adresse, e-mailadresse og kreditoplysninger efter behov. Derudover indsamles den information, som Brugeren måtte anføre i sin besked.
            </p>
            <p>Der er mulighed for, at Brugere kan besøge vores hjemmeside anonymt og vi vil udelukkende indsamle personlige oplysninger fra Brugere, når de frivilligt giver sådanne oplysninger til os. Brugere kan altid vælge ikke at give personlige oplysninger, men dette kan forhindre dem i at benytte visse tjenesteydelser eller funktioner på vores hjemmeside.
            </p>
            <p>I særlige tilfælde vil Visave.net ved lov være forpligtet til at indsamle specifikke og verificerede personoplysninger.
            </p>
            
            <h6>Hvorfor indsamler vi personoplysninger</h6>
            
            <p>For at kunne give Brugeren den bedst mulige oplevelse med vores service og hjemmesider, indsamles relevante personoplysninger. Vi indsamler oplysninger om brugerens adresse for at kunne fremsende relevante data fra lokale supermarkeder til brugeren.
            </p>
            <p>Når du bruger Visave.net hjemmesider, giver du således samtykke til, at Visave.net må håndtere de kategorier af oplysninger, der er relevante for at kunne levere den nødvendige service eller optimering, vedligehold eller udvikling af vores hjemmesider.
            </p>
            <p>Opbevaring af personoplysninger
            </p>
            <p>Personoplysninger opbevares maksimalt i 5 år efter kundeforholdets ophør, hvorefter de slettes.
            </p>
            <p>Personoplysninger indhentet med henblik på at sende nyhedsbreve og opdateringer omkring vores services vil beholdes indtil disse afmeldes af Brugeren.
            </p>
            <p>Fælles for alle personoplysninger gælder dog, at personoplysninger altid slettes, når personoplysningerne ikke er relevante i forhold til det formål, hvorpå de er blevet indsamlet eller, hvis et samtykke trækkes tilbage inden en egentlig handel er begyndt.
            </p>
             
            
            <h6>Hvordan vi beskytter dine oplysninger</h6> 
            
            <p>Brugerens oplysninger beskyttes ved brug af Amazon Web Services’ AES-256- kryptering. Gennem denne kryptering sikres adgangen til dine oplysninger mod uvedkommende.
            </p>
            <p>Deling af dine personlige oplysninger
            </p>
            <p>Visave.net deler Brugerens oplysninger med betroede samarbejdspartnere, som hjælper Visave.net med at vedligeholde, opdatere eller drive vores forretning, hjemmeside eller services for Brugeren. De pågældende samarbejdspartnere vil kun have adgang til Brugerens personlige oplysninger i det omfang det er nødvendigt og der vil altid udarbejdes en kontraktuel forpligtelse til at behandle dine oplysninger fortroligt.
            </p>
            <p>Brugerens oplysninger kan blive delt med tredjeparter eller samarbejdspartnere, med henblik på at kunne levere markedsføring, annoncering eller andre lignende services. Disse services inkluderer, men er ikke begrænset til: Amazon Web Services.
            </p>
            <p>I tilfælde af at Visave.net vil optræde i en forsikringssag, vil Visave.net dele de oplysninger, der er nødvendige for at forsikringsselskabet kan behandle en eventuel forsikringssag.
            </p>
            <p>Vi forbeholder os ret til at bruge eller videregive oplysninger, som er nødvendige for at overholde love, regler eller juridiske anmodninger eller for at samarbejde med en retslig undersøgelse.
            </p>
            
            <h6>Retsgrundlag</h6>
            <p>
            Visave.net behandler dine oplysninger enten på baggrund af dit udførte samtykke, eller hvis behandlingen er nødvendig for, at der kan gennemføres en kontakt, som du er part i.
            </p>
            <p>Såfremt behandlingen af Brugerens personoplysninger er baseret på samtykke, har Brugeren mulighed for at tilbagekalde dette samtykke. Dette er beskrevet i punktet omkring ”Dine rettigheder”.
            </p>
            <p>Såfremt Brugeren ikke ønsker at give samtykke til vores behandling af personoplysninger, kan Visave.net ikke yde de services, der ydes på Visave.net hjemmesider.
            </p>
            <p>Dine rettigheder
            </p>
            <p>Som Bruger hos Visave.net har du følgende rettigheder
            </p>
            <p>Som registreret har du ret til at få indsigt i, hvilke personoplysninger vi behandler på dig. Der er mulighed for, at Brugeren til enhver tid kan anmode om et dataudtræk af alle de data, der behandles på dig. Dine data vil blive leveret hurtigst muligt – dog altid med svar inden for 4 uger.
            </p>
            <p>Som registreret har Brugeren ret til at få transporteret sin data til en anden dataansvarlig. Dine personlige oplysninger vil blive leveret, via E-mail.
            </p>
            <p>Som registreret har du altid ret til at få dine personoplysninger slettet. Visave.net vil dog i visse tilfælde forbeholde sig retten til at enten begrænse eller afvise en sletning, såfremt disse personoplysninger er nødvendige for at kunne overholde særregler.
            </p>
            <p>Som registeret har du ret til at berigtiget forkerte eller unøjagtige oplysninger, der behandles om dig.
            </p>
            <p>Som registreret har du enhver tid ret til at trække et samtykke tilbage, såfremt der ønskes at standse eller begrænse en behandling af dine personoplysninger.
            </p>
            <h6>Klage</h6>
            
            <p>Såfremt du ønsker at klage over Visave.net behandling af dine personoplysninger, kan denne klage rettes til den ansvarlige myndighed. I Danmark er denne myndighed Datatilsynet, Borgergade 28, 5., 1300 København K, telefonnummer: 33 19 32 00. Mere information kan findes på www.datatilsynet.dk
            </p>
            <h6>Kontakt os
            </h6>
            <p>Hvis du har spørgsmål vedrørende denne privatpolitik, Hjemmesidens praksis eller dine handler med Hjemmesiden, er du velkommen til at kontakte os på:
            </p>
            <p>
            ARN (Visave.net)
            <br/>Robert Jacobsens Vej 18H
            <br/>København S, 2300, Danmark
            <br/>+45 21 35 82 13
            <br/>pushpendra.st@gmail.com
            </p>
            <p>CVR: 32 50 21 05
            </p>
            <p>Visave.net’s politik er sidst opdateret den 02.02.21</p>
        </div>
    </div>
</div>
<footer class="footer mt-auto py-2">
    <div class="container">
      <span class="d-flex justify-content-center">ARN  | CVR# 32 50 21 05 | visavehelp@gmail.com <a [routerLink]="['/home']"> Support</a></span>
    </div>
</footer>
