import { AfterContentInit, ChangeDetectorRef, Component, DoCheck, EventEmitter, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { HomeService } from '../home.service';
import * as ProductListingReducer from '../products/state/products.reducer';
import * as ProductActions from '../products/state/products.actions';
import { Observable, of, Subject } from 'rxjs';
import { SearchConstants } from './search_constants';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit, DoCheck {
  searchForm: FormGroup;
  @Output() searchTriggered = new EventEmitter<boolean>();
  searchFilters: Array<any>;
  keyword: string;
  productSuggestions: any;
  model: any;
  formatter: (result: string) => string;
  search: (text$: Observable<string>) => Observable<any>;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  updateCartItems$: Observable<any>;
  cartItems: any;
  homePage: boolean;
  searchPlaceholderTranslated: any;
  showSearch: boolean;
  
  constructor(
    private formBuilder: FormBuilder,
    private service: HomeService,
    private store: Store<ProductListingReducer.ProductListState>,
    private router: Router,
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    private _route: Router,
    private cdref: ChangeDetectorRef
  ) {

    this.searchForm = this.formBuilder.group({
      productName: new FormControl('', [Validators.required])
    });
    this.keyword = 'name';
    this.updateCartItems$ = this.service.updateCartItems$;
    const states = SearchConstants.data;
  }

  ngOnInit(): void {
    this.formatter = (result: string) => result.toUpperCase();

    this.search = (text$: Observable<string>) => 
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map(term => term === '' ? []
          : this.productSuggestions.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
      )
    
    this.service.getProductOptionList().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(productOptionList => {
      this.productSuggestions = productOptionList;
    });

    this.updateCartItems$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.cartItems = data;
      }
    );
    
  }

  ngDoCheck() {
    if (this._route.url === '/home'){
      this.homePage = true;
      console.log(this._route.url, this.homePage);
      this.cdref.detectChanges();
      this.showSearch = true;
    }else if(this._route.url === '/signin' || this._route.url === '/device-error'){
      this.showSearch = false;
    }else{
      this.homePage = false;
      this.showSearch = true;
    }
  }

  public searchProduct(option, alreadySearchedAlert): void{
    this.getProduct(option.item, alreadySearchedAlert);
  }

  public onEnter(searchedKeyword, alreadySearchedAlert): void{
    searchedKeyword = searchedKeyword ? searchedKeyword : this.searchForm.controls.productName.value;
    this.getProduct(searchedKeyword, alreadySearchedAlert);
  }

  public getProduct(item, alreadySearchedAlert): void{
    if(_.some(this.cartItems, { 'searchKey': item.toLowerCase() }) || _.some(this.cartItems, { 'searchKey': item })){
      this.alreadySearched(alreadySearchedAlert);
    }else{
      const searchedKeyword = item;
      this.store.dispatch(ProductActions.GetProductList({payload: searchedKeyword}));
      this.router.navigate(['searchresults']);
      this.searchTriggered.emit(true);
    }
  }

  public alreadySearched(modalName): void{
    setTimeout(() => {
      this.modalService.open(modalName, { size: 'sm' });
    }, 100);
    
    // console.log(modalName);
  }

  onChange(filter: string, isChecked: boolean) {
    if (this._route.url === '/home'){
      this.homePage = true;
      console.log(this._route.url, this.homePage);
    }
  }

  // selectEvent(item) {
  //   console.log(item);
  // }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
