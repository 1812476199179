// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    // apiKey: "AIzaSyAuErRWDRnPwMhr0LkkG6j-K4jN5chac1A",
    // authDomain: "mart-poc.firebaseapp.com",
    // projectId: "mart-poc",
    // storageBucket: "mart-poc.appspot.com",
    // messagingSenderId: "758836916299",
    // appId: "1:758836916299:web:67b723c96965b4b7601dce",
    // measurementId: "G-NF2B2Y1LE3"
    apiKey: "AIzaSyC-2NJfa4alz10vNtu7OFCNXDbRly45-9c",
    authDomain: "visave-c99f3.firebaseapp.com",
    projectId: "visave-c99f3",
    storageBucket: "visave-c99f3.appspot.com",
    messagingSenderId: "141487537415",
    appId: "1:141487537415:web:95efde26c87d45033419a2",
    measurementId: "G-498TB41PKZ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
