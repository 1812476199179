import { FakeDbService } from './fake-db/fake-db.service';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProductListingEffects } from './home/products/state/products.effects';
import * as ProductReducer from './home/products/state/products.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthService } from './services/auth/auth.service';
import { AngularFireModule } from '@angular/fire';
import { AuthModule } from './auth/auth.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './navbar/navbar.component';
import { NavModule } from './navbar/nav.module';
import { SupportComponent } from './home/support/support.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PrivacyPolicyComponent } from './home/privacy-policy/privacy-policy.component';
import { ErrorPagesComponent } from './error-pages/error-pages.component';
import { AboutusComponent } from './home/pages/aboutus/aboutus.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SupportComponent,
    PrivacyPolicyComponent,
    ErrorPagesComponent,
    AboutusComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only modey67
    }),
    InMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 3000,
      passThruUnknownUrl: true
    }),      
    EffectsModule.forRoot([ProductListingEffects]),
    StoreModule.forRoot({ productListingReducer: ProductReducer.ProductListingReducer }),
    // StoreModule.forRoot({ todos: ProductReducer }),
    AutocompleteLibModule,
    NgSelectModule,
    BrowserAnimationsModule,
    DragDropModule,
    AngularFireModule.initializeApp(environment.firebase),
    AuthModule,
    NgbModule,
    NavModule,
    FormsModule,
    ReactiveFormsModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    //AngularFireAuthModule
    TranslateModule.forRoot({
      loader: {
      provide: TranslateLoader,
      useFactory: (rootLoaderFactory),
      deps: [HttpClient],
      },
    }),
    HomeModule,
  ],
  providers: [AuthService],
  bootstrap: [AppComponent],
  exports: [TranslateModule]
})
export class AppModule { }

export function rootLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
