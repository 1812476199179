import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LoginModalComponent } from './login/login-modal/login-modal.component';



@NgModule({
  declarations: [LoginComponent, LoginModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild(),
    RouterModule
  ],
  exports: [LoginComponent, LoginModalComponent]
})
export class AuthModule { }
