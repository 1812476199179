<div class="container">
    <div class="row">
        <div class="col-12">
          <h6 class="page-title mt-3 pb-1">About Us</h6>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 privacy-block" innerHtml="{{'aboutuspagecontent' | translate}}">
        </div>
    </div>
</div>
