<div class="cart-preview"  [ngClass]="{'expanded-preview': expandPreview}">
    <ng-container *ngIf="cartItems; else bucketPreview">
        <ng-container *ngIf="expandPreview; else previewBlock">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 cart-detail-listing-block">
                        <app-cart [cartInDetail]="cartItems"></app-cart>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #previewBlock>
            <div class="content-area d-flex justify-content-between flex-column preview-listing-block">
                <app-cart [cartItems]="cartItems"></app-cart>
                <span [disabled]="true" *ngIf="savedListFlag" class="text-center alert-success quantity-zoom">{{saveListMessage}}</span>
                <div class="d-flex justify-content-between align-items-center">
                    <button class="btn btn-primary" (click)="clearCart()" translate>clear_cart</button>
                    <button *ngIf="!isGuestIn" type="button" (click)="saveListModal(saveList)" class="btn btn-primary"><span class="btn-text" translate>save_list</span></button>
                    <button *ngIf="isGuestIn" [ngbTooltip]="registerationReqMessage" placement="left" type="button" class="btn btn-secondary"><span class="btn-text" translate>save_list</span></button>
                    <!-- <span class="btn-tooltip">
                        <button [disabled]="isGuestIn" ngbTooltip="Please register to use this service" placement="bottom" type="button" (click)="saveListModal(saveList)" class="btn btn-primary"><span class="btn-text" translate>save_list</span></button>
                        <span translate *ngIf="isGuestIn">registered_user_service</span>
                    </span> -->
                </div>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #bucketPreview>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 py-2">
                    <h7 class="d-flex justify-content-center align-items-center placeholder-text text-center">Bucket Preview</h7>
                </div>
            </div>
        </div>
    </ng-template>
    <button class="view-more-preview" [ngClass]="expandPreview ? 'move-right' : 'move-left'" title="View cart in detail" (click)="toggleExpandedPreview()" aria-hidden="true">
        <i class="fa" [ngClass]="expandPreview ? 'fa-caret-right' : 'fa-caret-left'"></i>
    </button>
</div>
<ng-template #saveList let-modal>
    <div class="modal-header">
      <h6 class="modal-title" id="modal-basic-title" translate>save_list</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <form class="form-inline" [formGroup]="saveListForm">
                <div class="form-group mb-2 col-sm-12">
                    <div class="form-check form-check-inline" *ngIf="allSavedList.savedLists.length > 0">
                        <input class="form-check-input" type="radio" [checked]="saveListSavingOption === 'existingList'" name="saveListOption" id="existingList" (click)="onChangeSaveOption('existingList')" value="existingList">
                        <label class="form-check-label" for="existingList" translate>update_existing_list</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" [checked]="saveListSavingOption === 'newList'" name="saveListOption" id="newList" (click)="onChangeSaveOption('newList')" value="newList">
                        <label class="form-check-label" for="newList" translate>add_as_new_list</label>
                    </div>
                </div>
                <div class="form-group mb-2 col-sm-12 d-block" *ngIf="saveListSavingOption === 'existingList'">
                    <div class="list-group">
                        <button type="button" (click)="selectSavedlist(saveListName)" class="list-group-item list-group-item-action" *ngFor="let saveListName of allSavedList.savedLists" [ngClass]="{'active': selectedSavedList === saveListName}">
                            {{saveListName}}
                        </button>
                    </div>
                    <!-- <ul class="list-group">
                        <li class="list-group-item" *ngFor="let saveListName of allSavedList.savedLists" [ngClass]="{'active': saveListTriggered === saveListName}">
                            {{saveListName}}
                        </li>
                    </ul> -->
                </div>
                <div class="form-group mb-2 col-sm-12" *ngIf="saveListSavingOption === 'newList'">
                    <label for="inputPassword2" class="sr-only">List Name:</label>
                    <input type="text" formControlName="name" class="form-control" id="save-list" placeholder="Set a short name">
                </div>
            </form> 
        </div>
    </div>
    <div class="modal-footer" *ngIf="saveListSavingOption">
        <button type="button" class="btn btn-light" (click)="modal.close('Save click')" translate>cancel</button>
        <button type="button" class="btn btn-primary" (click)="saveSelectedProductsList(saveListForm)" translate>save</button>
    </div>
</ng-template>