import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HomeService } from '../home.service';
import { Constants } from '../shared/constants';
import * as ProductListingReducer from '../products/state/products.reducer';
import { SearchedProductModel } from '../products/types/products.model';
import * as _ from 'lodash';
import * as ProductActions from '../products/state/products.actions';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { ProductsService } from '../products/products.service';

@Component({
  selector: 'app-favrouite',
  templateUrl: './favrouite.component.html',
  styleUrls: ['./favrouite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FavrouiteComponent implements OnInit {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  favProductsList: any;
  favProductsList$: Observable<any>;
  sellers: string[];
  expanded: boolean;
  labelIcons: any;
  imgPath: string;
  favProductListData$: Observable<SearchedProductModel>;
  @Output() searchTriggered = new EventEmitter<boolean>();
  fullSellerOrder: any;
  sellerOrder: any;
  sellerFilter: any;
  sellerNameConstant: any;
  imageSource: any;
  
  constructor(
    private service: HomeService,
    private store: Store<ProductListingReducer.ProductListState>,
    private _router: Router,
    private appService: AppService,
    private productService: ProductsService,
  ) { 
    this.favProductsList$ = this.service.getFavProduct();
    this.labelIcons = Constants.LabelIcons;
    this.imgPath = "../../../assets/images/";
    this.sellerNameConstant = Constants.SELLER_NAMES;
    this.imageSource = Constants.IMAGE_SOURCE; 
  }

  public expandMain(flag: boolean): void{
    this.expanded = flag;
  }

  ngOnInit(): void {
    this.service.getFavProduct().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(favProducts => {
      this.favProductsList = favProducts.favouriteResponse;
      var result = _(this.favProductsList).omitBy(_.isEmpty).value();
      let favList = [];
      Object.keys(result).forEach(seller => {
        result[seller].forEach(product => {
          favList.push(product.product_id);
        })
      })
      this.productService.setFavList(favList);
      this.sellerOrder = Object.keys(favProducts.favouriteResponse);
    });

    this.service.getSellerFilter().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(sellerFilters => {
      this.sellerFilter = sellerFilters;
      
      // this.filterProductList(this.productListingRawData, this.sortOption);
    });

    this.service.getSellerFilterOrder().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(sellerOrder => {
      this.fullSellerOrder = sellerOrder;
      this.reOrderSellers(sellerOrder);
    });
  }

  public sellerVisibility(seller): boolean{
    return (this.sellerFilter.indexOf(seller) > -1);
  }

  public reOrderSellers(sellerOrder) : void{
    this.sellerOrder = sellerOrder;
  }

  public removeFav(product): void {
    let productObj = _.cloneDeep(product);
    productObj.favorite = false;
    this.service.removeFavProduct(productObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(favResponse => {
      if (favResponse.success){
        product.favorite = !product.favorite;
      }else{
        alert("Something went wrong. Try Again");
      }
    });
  }

  public selectProduct(item): void{
    const searchedKeyword = item.searchedKeyword;
    this.store.dispatch(ProductActions.GetProductList({payload: searchedKeyword}));
    this._router.navigate(['searchresults']);
    this.searchTriggered.emit(true);
  }

  public onImgError(event){
    event.target.src = './assets/images/no-image-available.png';
    event.target.parentNode.parentNode.className += ' no-img'
    //Do other stuff with the event.target
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
