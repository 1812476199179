import { createAction, props } from '@ngrx/store';
import { SearchedObjectModel } from '../../search/types/search.model';
import { SavedListsModel, SearchedProductModel, SelectedProductsModel } from '../types/products.model';
// import ToDo from './todo.model';

export const enum ProductListingActionTypes {
    LOAD_PRODUCT_LIST= '[Get product list api] load product status',
    LOAD_PRODUCT_SUCCESS = '[Success product list api] product list loaded success',
    LOAD_PRODUCT_FAILURE = '[Failure product list api] product list loaded failure',
    CLEAR_SELECTED_PRODUCT_LIST = '[Clear selected product list] clear selected product list',
    CLEAR_PRODUCT_LIST = '[Clear product list] clear product list',
    GET_SELECTED_PRODUCT_LIST = '[GET selected product list] get selected product list',
    UPDATE_CART_ITEMS = '[UPDATE cart list] update cart items',
    CLEAR_CART_LIST = '[Clear cart list] clear cart list',
    SELECTED_DELIVERY_OPTIONS = '[GET SELECTED DELIVERY OPTIONS] get selected delivery options',
    LOAD_FAV_LIST= '[Get fav list api] load fav status',
    LOAD_FAV_SUCCESS = '[Success fav list api] fav list loaded success',
    LOAD_FAV_FAILURE = '[Failure fav list api] fav list loaded failure',
    LOAD_SAVED_LIST_ITEMS_LIST= '[Get saved list items api] load saved list items status',
    LOAD_SAVED_LIST_ITEMS_SUCCESS = '[Success saved list items api] saved list items loaded success',
    LOAD_SAVED_LIST_ITEMS_FAILURE = '[Failure saved list items api] saved list items loaded failure',
    LOAD_SAVED_LISTS_LIST= '[Get saved lists api] load saved lists status',
    LOAD_SAVED_LISTS_SUCCESS = '[Success saved lists api] saved lists loaded success',
    LOAD_SAVED_LISTS_FAILURE = '[Failure saved lists api] saved lists loaded failure',
    // LOAD_SAVED_LISTS_ITEMS_LIST= '[Get saved lists items api] load saved lists items status',
    // LOAD_SAVED_LISTS_ITEMS_LIST_SUCCESS = '[Success saved lists items api] saved lists items loaded success',
    // LOAD_SAVED_LISTS_ITEMS_LIST_FAILURE = '[Failure saved lists items api] saved lists items loaded failure',
    LOAD_LOGGED_IN_USER = '[Get loggedin user data api] logged in user data',
    LOAD_USER_SITE_PREFRENCES = '[Get user site prefrences] user site prefrences',
    LOAD_USER_PREFRENCES_DATA = '[Get user prefrences data] user prefrences data',
    LOAD_USER_PREFRENCES_SUCCESS_DATA = '[Get user prefrences success data] user prefrences success data',
    LOAD_USER_PREFRENCES_FAILURE_DATA = '[Get user prefrences failure data] user prefrences failure data'
}

export const GetProductList = createAction(
    ProductListingActionTypes.LOAD_PRODUCT_LIST,
    props<{ payload: string}>()
);

export const SuccessProductList = createAction(
    ProductListingActionTypes.LOAD_PRODUCT_SUCCESS,
    props<{ payload: SearchedProductModel}>()
);

export const FailureProductList = createAction(
    ProductListingActionTypes.LOAD_PRODUCT_FAILURE,
    props<{ payload: any}>()
);

export const UpdateSelectedProductList = createAction(
    ProductListingActionTypes.GET_SELECTED_PRODUCT_LIST,
    props<{ payload: SelectedProductsModel}>()
);

export const UpdateCart = createAction(
    ProductListingActionTypes.UPDATE_CART_ITEMS,
    props<{ payload: any}>()
);

export const GetDeliveryOptionFilter = createAction(
    ProductListingActionTypes.SELECTED_DELIVERY_OPTIONS,
    props<{ payload: any}>()
);

export const GetFavList  = createAction(
    ProductListingActionTypes.LOAD_FAV_LIST,
    props<{ payload: string}>()
);

export const SuccessFavList = createAction(
    ProductListingActionTypes.LOAD_FAV_SUCCESS,
    props<{ payload: SearchedProductModel}>()
);

export const FailureFavList = createAction(
    ProductListingActionTypes.LOAD_FAV_FAILURE,
    props<{ payload: any}>()
);

export const GetSavedListItems = createAction(
    ProductListingActionTypes.LOAD_SAVED_LIST_ITEMS_LIST,
    props<{ payload: string}>()
);

export const SuccessSavedListItems = createAction(
    ProductListingActionTypes.LOAD_SAVED_LIST_ITEMS_SUCCESS,
    props<{ payload: SearchedProductModel}>()
);

export const FailureSavedListItems = createAction(
    ProductListingActionTypes.LOAD_SAVED_LIST_ITEMS_FAILURE,
    props<{ payload: any}>()
);

export const GetSavedLists = createAction(
    ProductListingActionTypes.LOAD_SAVED_LISTS_LIST
);

export const SuccessSavedLists = createAction(
    ProductListingActionTypes.LOAD_SAVED_LISTS_SUCCESS,
    props<{ payload: SavedListsModel}>()
);

export const FailureSavedLists = createAction(
    ProductListingActionTypes.LOAD_SAVED_LISTS_FAILURE,
    props<{ payload: any}>()
);

export const UpdateUserSitePrefrences = createAction(
    ProductListingActionTypes.LOAD_USER_SITE_PREFRENCES,
    props<{ payload: Array<string>}>()
);

export const GetUserPrefrencesData = createAction(
    ProductListingActionTypes.LOAD_USER_PREFRENCES_DATA,
    props<{ payload: string}>()
);

export const SuccessUserPrefrencesData = createAction(
    ProductListingActionTypes.LOAD_USER_PREFRENCES_SUCCESS_DATA,
    props<{ payload: any}>()
);

export const FailureUserPrefrencesData = createAction(
    ProductListingActionTypes.LOAD_USER_PREFRENCES_FAILURE_DATA,
    props<{ payload: any}>()
);

// export const GetSavedListsItemsList = createAction(
//     ProductListingActionTypes.LOAD_SAVED_LISTS_ITEMS_LIST
// );

// export const SuccessSavedListsItemsLists = createAction(
//     ProductListingActionTypes.LOAD_SAVED_LISTS_ITEMS_LIST_SUCCESS,
//     props<{ payload: SavedListsModel}>()
// );

// export const FailureSavedListsItemsLists = createAction(
//     ProductListingActionTypes.LOAD_SAVED_LISTS_ITEMS_LIST_FAILURE,
//     props<{ payload: any}>()
// );

export const UpdateLoggedInUserData = createAction(
    ProductListingActionTypes.LOAD_LOGGED_IN_USER,
    props<{ payload: any}>()
);

export const ClearSelectedProductList = createAction(ProductListingActionTypes.CLEAR_SELECTED_PRODUCT_LIST);
export const ClearProductList = createAction(ProductListingActionTypes.CLEAR_PRODUCT_LIST);
export const ClearCart = createAction(ProductListingActionTypes.CLEAR_CART_LIST);
