import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { FavrouiteComponent } from './favrouite/favrouite.component';
import { HomeComponent } from './home.component';
import { MainPageComponent } from './main-page/main-page.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Focus on the business logic of your next application',
      description:
        'Angular boilerplate is a clean, intuitive, and fast starter project which contains a robust, maintainable, scalable, and agile architecture, allowing the developer to focus on the business logic and not on Front-end architecture matters.',
      robots: 'index, follow',
    },
    canActivate:[AuthService],
    children:[
      { path: 'searchresults', component: MainPageComponent},
      { path: 'favrouites', component: FavrouiteComponent}
    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
