import { filter, map, takeUntil } from 'rxjs/operators';
import { CartModel, ProductDetailsModel, ProductList, ProductListModel, SearchedProductModel, SelectedProductsModel } from './types/products.model';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ProductListingReducer from '../products/state/products.reducer';
import * as ProductActions from '../products/state/products.actions';
import { Observable, Subject } from 'rxjs';
import { HomeService } from '../home.service';
import * as _ from 'lodash';
import { Constants } from '../shared/constants';
import { KeyValue } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ProductsService } from './products.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductsComponent implements OnInit {
  @Input() filteredProductList: Observable<ProductListModel>;
  @Input() resetSelectedProduct: boolean;
  @Input() searchFilters: Array<string>;
  @Input() sellerFilter: Array<string>;
  @Input() listingType: Array<string>;
  @Input() pricingDetails: object;
  @Input() bestPrice: number;
  @Output() updateSelectedCount = new EventEmitter<number>();
  selectedQuantity: number;
  selectedProduct: ProductList;
  sellers: string[];
  productDetail: any;
  productListingRawData: ProductListModel;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  visibleSeller: any;
  sellerOrder: any;
  labelIcons: any;
  imgPath: string;
  itemCategory: any;
  fullSellerOrder: any;
  selectedProductMeasure: any;
  selectedUnit: any[];
  unitConstants: any;
  itemLabel: any;
  sortOption: any;
  url: SafeResourceUrl;
  isDiscountSelected: any;
  resetStatus: boolean;
  currencySymbol: any;
  sellerCount: number;
  sellerNameConstant: any;
  favProductArray: Subject<any>;
  favList: any;
  imageSource: any;
  deliveryFilter: any;
  cartItems: any;
  userPrefrencesData: any;
  premiumLabelConstant: any;
  loggedInUserData$: Observable<any>;
  loggedInUserData: any;
  isGuestIn: boolean;
  registerationReqMessage: any;

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.bilkatogo.dk/produkt/Budget-%C3%98ko.-hakkede-tomater/60303/');
  }

  constructor(
    private store: Store<ProductListingReducer.ProductListState>,
    private service: HomeService,
    private sanitizer: DomSanitizer,
    private _productsService: ProductsService,
    private _router: Router,
    private translate: TranslateService
  ) {
    this.selectedQuantity = 0;
    this.selectedProduct = _.cloneDeep(Constants.InitialSelectedProduct);
    this.labelIcons = Constants.LabelIcons;
    this.imgPath = "../../../assets/images/";
    this.selectedUnit = [];
    this.selectedProductMeasure = [];
    this.sortOption = 'relevance';
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.bilkatogo.dk/produkt/Budget-%C3%98ko.-hakkede-tomater/60303/');
    this.currencySymbol = Constants.CURRENCY_SYMBOL;
    this.sellerNameConstant = Constants.SELLER_NAMES;
    this.premiumLabelConstant = Constants.PREMIUM_LISTING_LABELS;
    this.favProductArray = new Subject<any>();
    this.imageSource = Constants.IMAGE_SOURCE; 
    this.deliveryFilter = Constants.DeliveryOptions;
    var retrievedObject = localStorage.getItem('cachedCart');
    this.cartItems =  JSON.parse(retrievedObject)?.cartItems ? JSON.parse(retrievedObject).cartItems : [];
    this.loggedInUserData$ = this.service.loggedInUserData$;
    this.isGuestIn = true;
    this.registerationReqMessage= this.translate.instant("registered_user_service");
  }

  ngOnInit(): void {

    this.loggedInUserData$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.loggedInUserData = data.loggedInUserData;
        this.isGuestIn = false;
      }
    );

    this.service.getDeliveryFilter().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(deliveryFilters => {
      this.deliveryFilter = deliveryFilters;
      // this.triggerDeliveryCountCheck();
    });

    this.service.getSellerFilterOrder().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(sellerOrder => {
      this.fullSellerOrder = sellerOrder;
      this.reOrderSellers(sellerOrder);
    });

    this.filteredProductList.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(productList => {
      this.filterProductList(productList, 'relevance' );
    });

    this.service.getSellerFilter().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(sellerFilters => {
      this.sellerFilter = sellerFilters;
      let selectedProduct = _.cloneDeep(this.selectedProduct);
      Object.keys(Constants.InitialSelectedProduct).filter(seller =>{
        let keyIndex = this.sellerFilter.indexOf(seller);
        if(keyIndex === -1){
          delete selectedProduct[seller];
        }
      })
      this.selectedProduct = _.cloneDeep(selectedProduct);
      this.filterProductList(this.productListingRawData, this.sortOption);
    });

    this.service.getDiscountSelection().subscribe(selected => {
      this.isDiscountSelected = selected;
    });

    this.service.getResetSelectedProductsStatus().subscribe(resetStatus => {
      this.selectedProduct = resetStatus ?  _.cloneDeep(Constants.InitialSelectedProduct) : this.selectedProduct;
      this.updateAmount(this.selectedProduct);
    });

    // get updated sellers selections
    this.service.getItemCategory().subscribe(itemCategory => {
      this.itemCategory = itemCategory;
    });

    // get updated sellers selections
    this.service.getfilterLabel().subscribe(itemLabel => {
      this.itemLabel = itemLabel;
    });

    // get updated sellers selections
    this.service.getSortOption().subscribe(sortOption => {
      this.sortOption = sortOption;
      console.log(sortOption);
      this.filterProductList(this.productListingRawData, this.sortOption);
    });

    this._productsService.getFavList().subscribe(favItems => {
      this.favList = favItems;
    });

    this.service.getFavProduct().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(favProducts => {
      let favProductsList = favProducts.favouriteResponse;
      var result = _(favProductsList).omitBy(_.isEmpty).value();
      let favList = [];
      Object.keys(result).map(seller => {
        result[seller].map(product => {
          favList.push(product.product_id);
        })
      })
      this._productsService.setFavList(favList);
    }); 
  }

  valueAscOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number=> {
    return a.value.localeCompare(b.value);
  }

  returnZero() {
    return 0;
  }
  
  // public checkForBuyingOption(): boolean{
  //   if(this.deliveryFilter?.length === 0 || this.deliveryFilter?.length > 2 && ){

  //   }
  //   this.sellerCount this.deliveryFilter ? 
  // }

  public filterProductList(productList, sortType: 'relevance'): void{
    this.productListingRawData = productList;
    this.productDetail = {};
    Object.entries(this.productListingRawData).map( element => {
      // const filteredPro = element[1].filter(product => {
      //   return (this.searchFilters?.length > 0 ? (this.searchFilters?.indexOf(product.foodType) >= 0) : true);
      // });
      const sortedArray = this.sortListing(element[1], sortType);
      if (sortedArray.length > 0){
        sortedArray.map(product => {
          const productDetail = {...product};
          if (!this.productDetail.hasOwnProperty(element[0])){
            this.productDetail[element[0]] = [];
            this.productDetail[element[0]].push(productDetail);
          }else{
            this.productDetail[element[0]].push(productDetail);
          }
          // let productID = productDetail.id;
          // let selectedProductID = this.selectedProduct[element[0]].selectedProductList[0]?.id;
          // productDetail.enableCart = (productID === selectedProductID) ? true : false;
        });
      }else{
        this.hideSellerVisibility(element[0]);
      }
    });
   // this.sellerCount = Object.keys(this.productDetail).length;
    this.manageSellerVisibility(Object.keys(this.productDetail));
    this.sellerCount = Object.keys(this.productDetail).length;
    let sellerOrder = _.cloneDeep(this.fullSellerOrder);
    sellerOrder ? this.reOrderSellers(_.intersection(sellerOrder, Object.keys(this.productDetail))) : 
    this.reOrderSellers(Object.keys(this.productDetail));
    let selectedProduct: ProductList = _.cloneDeep(this.selectedProduct);
    this.selectedProduct = selectedProduct;
    this.updateSelectedCartItems(selectedProduct);
    this.userPrefrencesData = this.service.getUserPremiumSites();
    
  }

  public selectedCategoryProducts(product): boolean{
    return this.itemCategory ? (product.item_category.indexOf(this.itemCategory) > -1) : true;
  }

  public selectedLabelProducts(product): boolean{
    if (this.itemLabel){
      switch (this.itemLabel) {
        case 'Avisvare':
          return (product.offer || product.labels.indexOf(this.itemLabel) > -1);
        case 'Økologisk':
          return product.labels.some(label => { return _.includes(label, this.itemLabel)});
        default:
          return (product.labels.indexOf(this.itemLabel) > -1);
      }
    }else{
      return true;
    }
  }

  public showDiscounted(product): boolean{
    return (product.offer || product.labels.indexOf('Avisvare') > -1);
  }

  public reOrderSellers(sellerOrder) : void{
    this.sellerOrder = sellerOrder;
  }

  public manageSellerVisibility(sellers): void{
    var difSeller = _.differenceWith(Object.keys(this.productDetail), this.sellerFilter, _.isEqual);
    if (difSeller.length > 0){
      let productDetail = _.cloneDeep(this.productDetail);
      difSeller.map(seller => {
        this.hideSellerVisibility(seller);
        delete productDetail[seller]; 
      })
      this.productDetail = {...productDetail};
    }
  }

  public hideSellerVisibility(seller): void {
    let selectedProduct: ProductList = _.cloneDeep(this.selectedProduct);
    let initialProductsModal = _.cloneDeep(Constants.InitialSelectedProduct);
    selectedProduct[seller] = initialProductsModal[seller];
    this.selectedProduct = {...selectedProduct};
  }
  
  sortListing(productList, sortType): any{
    switch (sortType) {
      case 'relevance':
        return productList;
      case 'asc':
        return productList.slice().sort((a, b) => a.price - b.price);
      case 'dsc':
        return productList.slice().sort((a, b) => b.price - a.price);
      case 'alpha':
        return productList.slice().sort((a, b) => a.title.localeCompare(b.title));
      case 'reversealpha':
        return productList.slice().sort((a, b) => b.title.localeCompare(a.title));
      
      default:
        return productList;
        break;
    }
  }

  public minusQuantity(product): void{
    const selectedProduct = this._productsService.minusQuantity(product, this.selectedProduct);
    this.selectedProduct = {...selectedProduct};
    this.updateAmount(this.selectedProduct);
  }

  public addQuantity(product: ProductDetailsModel): void{
    const selectedProduct = this._productsService.addQuantity(product, this.selectedProduct);
    this.selectedProduct = {...selectedProduct};
    this.updateAmount(this.selectedProduct);
  }

  public selectProduct(product: ProductDetailsModel): void{
    let selectedProduct: ProductList = _.cloneDeep(this.selectedProduct);
    selectedProduct[product.site].selectedProductList.splice(0, 1, product);
    selectedProduct[product.site].selectedQuantity = selectedProduct[product.site].selectedQuantity + 1;
    this._productsService.productSelection(selectedProduct[product.site]);
    this.selectedProduct = {...selectedProduct};
    this.updateAmount(selectedProduct);
  }

  public updateAmount(selectedProduct: ProductList): void{
    let selectedProducts = this._productsService.updateAmount(selectedProduct);
    this.updateSelectedCartItems(selectedProducts);
  }

  public updateSelectedCartItems(selectedProduct): void{
    this.store.dispatch(ProductActions.UpdateSelectedProductList({payload: selectedProduct}));
  }

  public changeProduct(product: ProductDetailsModel): void{
    let selectedProduct: ProductList = _.cloneDeep(this.selectedProduct);
    selectedProduct[product.site].totalPrice = 0;
    selectedProduct[product.site].selectedQuantity = 0;
    selectedProduct[product.site].selectedUnit = 0;
    selectedProduct[product.site].selectedMeasure = null;
    selectedProduct[product.site].selectedProductList = [];
    this.selectedProduct = {...selectedProduct};
    this.updateAmount(this.selectedProduct);
  }

  public makeFav(product: ProductDetailsModel): void{
    if ((this.favList.indexOf(product.product_id) > -1)){
      this.removeFav(product);
    }else{
      this.addToFav(product);
    }
  }

  public addToFav(product): void{
    let productObj = _.cloneDeep(product);
    this.service.setFavProduct(productObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(favResponse => {
      if (favResponse.success){
        this.favList.push(productObj.product_id);
        this._productsService.setFavList(this.favList);
      }else{
        alert("Something went wrong. Try Again");
      }
    });
  }

  public removeFav(product): void {
    let productObj = _.cloneDeep(product);
    this.service.removeFavProduct(productObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(favResponse => {
      if (favResponse.success){
        let indexOfProduct = this.favList.indexOf(product.product_id);
        if (indexOfProduct > -1) {
          this.favList.splice(indexOfProduct, 1);
          this._productsService.setFavList(this.favList);
        }
      }else{
        alert("Something went wrong. Try Again");
      }
    });
  }

  public productEnabledCart(product): boolean{
    if (this.selectedProduct && this.selectedProduct[product.site].selectedProductList.length > 0){
      return this.selectedProduct && this.selectedProduct[product.site].selectedProductList[0].id === product.id;
    }else{
      return false;
    }
  }

  public isFavProduct(product): boolean{
    return (this.favList?.indexOf(product.product_id) > -1) ? true : false;
  }

  public onImgError(event){
    event.target.src = './assets/images/no-image-available.png';
    event.target.parentElement.parentElement.className += ' no-img'
   //Do other stuff with the event.target
  }
  
  public checkforBestPrice(product): boolean{
    return ((localStorage.getItem('selectedDeal') === 'best') && _.some(this.cartItems, function (item) { return (item.searchKey === product.searchedKeyword) && (item.bestPrice === item.productList[product.site].totalPrice)}));
  }

  public checkMembership(seller): boolean{
    return (this.userPrefrencesData?.indexOf(seller) !== -1);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

