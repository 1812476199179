import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  mainMenuStatus: BehaviorSubject<boolean>;
  cartMenuStatus: BehaviorSubject<any>;

  constructor() { 
    this.mainMenuStatus = new BehaviorSubject<any>(true);
    this.cartMenuStatus = new BehaviorSubject<any>(false);
  }

  public getMainMenuStatus(): Observable<any> {
    return this.mainMenuStatus.asObservable();
  }

  public setMainMenuStatus(flag): void{
    this.mainMenuStatus.next(flag);
  }

  public getCartMenuStatus(): Observable<any> {
    return this.cartMenuStatus.asObservable();
  }

  public setCartMenuStatus(flag): void{
    this.cartMenuStatus.next(flag);
  }
}
