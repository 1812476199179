<ng-container *ngIf="cartInDetail; else cartPreview">
    <div class="title-section mb-2 d-flex justify-content-between align-items-center">
        <h5 translate>cart_detail</h5>
        <form [formGroup]="dealSelection" class="form-check-inline options">
            <div class="form-check form-check-inline">
                <input class="form-check-input" id="cheapest" type="radio" formControlName="dealOption" value="cheapest" (change)="selectDealOption(dealSelection.value)">
                <label class="form-check-label" for="cheapest" title="Best Deal">
                    Best Deal
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input"  id="best" type="radio" formControlName="dealOption" value="best" (change)="selectDealOption(dealSelection.value)">
                <label class="form-check-label" for="best" title="Best Product Offers">
                    Best Product Offers
                </label>
            </div>
        </form>
    </div>
    
    <div class="cart-detail-container animate-me">
        <ngb-accordion #acc="ngbAccordion" activeIds="panel-common">
            <ngb-panel *ngIf="(commonCart | json) != '{}'" id="panel-common">
                <ng-template ngbPanelTitle let-opened="opened">
                    <div class="d-flex justify-content-between animate-me pannel">
                        <span translate>common_cart</span>
                        <div class="best-price-preview">
                            <span class="meta text-sm" translate>best_price </span>
                            <span>{{bestPrice | number:'1.2-2'}} <span class="price-symbol">{{currencySymbol.denmark}}</span></span>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <app-products [listingType]="cartInDetail" [bestPrice]="bestPrice" [pricingDetails]="commonTotalPrice" [sellerFilter]="commonSellerFilters" [filteredProductList]="commonCartItems"></app-products> 
                </ng-template>
            </ngb-panel>
            <ngb-panel *ngIf="(seperateCart | json) != '{}'">
                <ng-template ngbPanelTitle>
                    <div class="d-flex justify-content-between">
                        <span translate>mixed_cart</span>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <app-products [listingType]="cartInDetail" [pricingDetails]="seperateTotalPrice" [sellerFilter]="seperateSellerFilters" [filteredProductList]="seperateCartItems"></app-products> 
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    
    <div class="row mt-4">
        <div class="col-md-6">
            <strong translate>choose_a_seller</strong>
            <ul class="nav flex-row" *ngIf="commonTotalPrice">
                <li class="nav-item form-check form-check-inline p-2 pl-2" *ngFor="let option of commonTotalPrice | keyvalue">
                  <input class="form-check-input" name="sellerOption" [checked]="firstBest() === option.key" (click)="selectSeller(option.key)" type="radio" [id]="option.key"/>
                  <label class="form-check-label" [for]="option.key" [title]="option.key">
                    <span class="seller-name" >{{ sellerNameConstant[option.key] }}</span>
                  </label>
                </li>
            </ul>
        </div>
        <div class="col-md-6 text-right">
            <button *ngIf="false" class="btn btn-primary mx-2" (click)="saveListModal(saveList)" translate>save_list</button>
            <button *ngIf="!isGuestIn" type="button" (click)="enterEmailinfo(email)" class="btn-outline-secondary mr-2 btn-sm mb-2"><span class="btn-text" translate>send_this_list_on_my_mail</span></button>
            <button *ngIf="isGuestIn" [ngbTooltip]="registerationReqMessage" placement="bottom" type="button" class="btn btn-outline-secondary mr-2 btn-sm mb-2"><span class="btn-text" translate>send_this_list_on_my_mail</span></button>
            <span class="btn-tooltip">
                <button [disabled]="true" placement="bottom" type="button" class="btn btn-outline-secondary mr-2 btn-sm mb-2"><span class="btn-text" translate>send_my_list_to_supermarket</span> {{sellerNameConstant[selectedSeller]}} </button>
                <span translate>pending_license_agreement_from_seller</span>
            </span>
            
        </div>
        <div class="col-sm-12 mt-2">
            <i class="mt-2 d-inline-block" translate>services_like_home_delivery_charges_minimum_purchase_amount</i>
        </div>
    </div>
</ng-container>
<ng-template #cartPreview>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 px-0">
                <h6 class="title" translate>cart_preview</h6>
                <div class="cart-item-list">
                    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
                        <ngb-panel *ngFor="let items of cartItems | keyvalue; index as i">
                            <ng-template ngbPanelTitle>
                                <div class="d-flex justify-content-between">
                                    <span class="title">{{items.value.searchKey}}</span>
                                    <div class="d-flex justify-content-end align-items-center">
                                        <div class="best-price-preview animate-me">
                                            <span>{{items.value.bestPrice | number:'1.2-2'}} <span class="price-symbol mr-1">{{currencySymbol.denmark}}</span></span>
                                        </div>
                                        <button class="cart-btn edit-btn" (click)="editProduct(quantityMatch, items.value)"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                                        <button class="cart-btn close-btn" (click)="deleteProduct(items.value)"><i class="fa fa-window-close" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Seller</th>
                                            <th scope="col">Product</th>
                                            <th class="text-center" scope="col">Qty.</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let seller of items.value.productList | keyvalue; index as i;">
                                            <tr *ngIf="seller.value.selectedProductList.length > 0">
                                                <td [ngClass]="{'best-price':items.value.bestPrice === seller.value.totalPrice}">
                                                    {{ seller.value.selectedProductList[0].site }}
                                                </td>
                                                <td [ngClass]="{'best-price':items.value.bestPrice === seller.value.totalPrice}" title="{{seller.value.selectedProductList[0].title}}">{{ seller.value.selectedProductList[0].title }}</td>
                                                <td [ngClass]="{'best-price':items.value.bestPrice === seller.value.totalPrice}" class="text-center">{{ seller.value.selectedQuantity }}</td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #quantityMatch let-modal>
    <div class="modal-header d-none">
        <h4 class="modal-title" id="modal-basic-title">Quantity Match</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-product-compare-modal [selectedProductList]="selectedProductList" [fullSellerOrder]="fullSellerOrder"></app-product-compare-modal>
    </div>
    <div class="modal-footer">
        <!-- <button type="button" class="btn btn-light" (click)="modal.close('Save click')" translate>back_to_product_selection</button> -->
        <button type="button" class="btn btn-primary" (click)="moveToCart()" translate>continue</button>
    </div>
</ng-template>


<ng-template #email let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" translate>send_this_list_on_my_mail</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="!mailSent; else mailResponseText">
            <form class="form-inline mb-2" [formGroup]="emailSelectedProductForm">
                <div class="form-group mb-2 col-sm-12">
                    <label for="userEmail" translate>enter_required_information</label>
                    <input type="email" formControlName="userEmail" class="form-control ml-2" placeholder="{{ 'enter_required_information' | translate" />
                    <div *ngIf="submitted && f.userEmail.errors" class="invalid-feedback">
                        <div *ngIf="f.userEmail.errors.required">Email is required</div>
                    </div>
                </div>
            </form>
            <small><i translate>you_can_set_your_email_from</i></small>
        </ng-container>
        <ng-template #mailResponseText>
            <h6 class="p-2 text-center text-secondary">{{mailResponse}}</h6>
        </ng-template>
    </div>
    <div class="modal-footer">
        <button type="button" *ngIf="!mailSent" class="btn btn-primary" (click)="sendSelectedProductEmail()" translate>Send</button>
        <button type="button" *ngIf="mailSent" class="btn btn-primary" (click)="closeMailModal()" translate>Ok</button>
    </div>
</ng-template>

