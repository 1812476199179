import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HomeService } from './home.service';
import { ProductList, SearchedProductModel, SelectedProductsModel } from './products/types/products.model';
import { Store } from '@ngrx/store';
import * as ProductListingReducer from './products/state/products.reducer';
import * as ProductActions from './products/state/products.actions';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NavItems } from '../navbar/_navItems';
import { AppService } from '../services/app.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy {
  productListing: any;
  selectedProductList: ProductList;
  countVal: number;
  searchedProductListData$: Observable<SearchedProductModel>;
  selectedProductListData$: Observable<any>;
  productSearchLoading$: Observable<boolean>;
  selectedDeliveryFilters$: Observable<Array<string>>;
  updateCartItems$: Observable<any>;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  expanded: boolean;
  cartItems: Array<SelectedProductsModel>;
  filteredProductList$: Observable<any>;
  rawSearchedProductData: SearchedProductModel;
  subscription: any;
  filteredProductList: SearchedProductModel;
  isMenuCollapsed: boolean;
  sellerFilters: Array<string>;
  enableAddToCart: boolean;
  expandPreview: boolean;
  flashToCart: boolean;
  shakeMe: boolean;
  hasError: boolean;
  noDataFound: string;
  movies: string[];
  productOptionList: any;
  searchedKey: string;
  itemCategories: any;
  selectedItemCategories: number;
  filterLabel: any;
  sortLabel: any;
  filteringOptions: FormGroup;
  productSortingOptions: any;
  productFilteringOptions: any;
  sortingOptions: FormGroup;
  @ViewChild("cartContainer", {read: ElementRef, static: true}) cartContainer: ElementRef; // gets #target1
  isDiscountSelected: boolean;
  resetSelectedProducts: boolean;
  favProductListData$: Observable<SearchedProductModel>;
  favPage: boolean;
  saveListForm: FormGroup;
  saveList: any;
  homePage: boolean;
  
  constructor(
    private service: HomeService,
    private appService: AppService,
    private cdref: ChangeDetectorRef,
    private store: Store<ProductListingReducer.ProductListState>,
    private modalService: NgbModal,
    private _route: Router
  ) {
    this.isMenuCollapsed = true;
    this.expanded = true;
    this.searchedProductListData$ = this.store.select(ProductListingReducer.getSearchedProductList);
    this.selectedProductListData$ = this.service.selectedProductListData$;
    this.productSearchLoading$ = this.service.productSearchLoading$;
    this.updateCartItems$ = this.service.updateCartItems$;
    this.selectedDeliveryFilters$ = this.service.deliveryFilters.asObservable();
    this.filteredProductList$ = this.service.filteredProductList.asObservable();
    this.favProductListData$ = this.service.favProductListData$;
    
    this.hasError = false;

    this.isDiscountSelected = false;
    this.saveListForm = new FormGroup({
      name: new FormControl('')
    });
  }

  ngOnInit(): void {
    if (this._route.url === '/home'){
      this.homePage = true;
    }
    
    this.appService.getMainMenuStatus().subscribe(menuStatus => {
      this.expanded = menuStatus;
    });
  }

  public collapsePreviewMenu(): void {
    this.expandPreview = false;
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

