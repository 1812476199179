import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit, ComponentFactoryResolver, TemplateRef, ContentChild, HostListener, EventEmitter, Output, DoCheck } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { HomeService } from '../home.service';
import { ProductList, SavedListsModel, SearchedProductModel, SelectedProductsModel } from '../products/types/products.model';
import { Store } from '@ngrx/store';
import * as ProductListingReducer from '../products/state/products.reducer';
import * as ProductActions from '../products/state/products.actions';
import * as _ from 'lodash';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsService } from '../products/products.service';
import { Constants } from '../shared/constants';
import { NavItems } from 'src/app/navbar/_navItems';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainPageComponent implements OnInit, OnDestroy, AfterViewInit, DoCheck {
  productListing: any;
  selectedProductList: ProductList;
  countVal: number;
  searchedProductListData$: Observable<SearchedProductModel>;
  selectedProductListData$: Observable<any>;
  productSearchLoading$: Observable<boolean>;
  selectedDeliveryFilters$: Observable<Array<string>>;
  updateCartItems$: Observable<any>;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  expanded: boolean;
  cartItems: Array<SelectedProductsModel>;
  filteredProductList$: Observable<any>;
  rawSearchedProductData: SearchedProductModel;
  subscription: any;
  filteredProductList: SearchedProductModel;
  isMenuCollapsed: boolean;
  sellerFilters: Array<string>;
  enableAddToCart: boolean;
  expandPreview: boolean;
  flashToCart: boolean;
  shakeMe: boolean;
  hasError: boolean;
  noDataFound: string;
  movies: string[];
  productOptionList: any;
  searchedKey: string;
  itemCategories: any;
  selectedItemCategories: number;
  filterLabel: any;
  sortLabel: any;
  filteringOptions: FormGroup;
  productSortingOptions: any;
  productFilteringOptions: any;
  sortingOptions: FormGroup;
  @ViewChild("cartContainer", {read: ElementRef, static: true}) cartContainer: ElementRef; // gets #target1
  isDiscountSelected: boolean;
  resetSelectedProducts: boolean;
  saveList: any;
  saveListForm: FormGroup;
  saveListMessage: string;
  savedListFlag: boolean;
  triggerSaveList: boolean;
  fullSellerOrder: any;
  saveListTriggered: any;
  saveListSavingOption: any;
  SavedListItemsData$: Observable<SearchedProductModel>;
  allSavedList: SavedListsModel;
  invalidFilter: boolean;
  savedListsData$: Observable<SavedListsModel>;
  selectedSavedList: any;
  currencySymbol: any;
  selectFilterTranslated: any;
  selectProductTranslated: any;
  
  constructor(
    private service: HomeService,
    private cdref: ChangeDetectorRef,
    private store: Store<ProductListingReducer.ProductListState>,
    private resolver:ComponentFactoryResolver,
    private _fb: FormBuilder,
    private modalService: NgbModal,
    private _productsService: ProductsService,
    private _router: Router,
    private appService: AppService,
    private translate: TranslateService
  ) {
    this.isMenuCollapsed = false;
    this.expanded = true;
    this.searchedProductListData$ = this.store.select(ProductListingReducer.getSearchedProductList);
    this.selectedProductListData$ = this.service.selectedProductListData$;
    this.productSearchLoading$ = this.service.productSearchLoading$;
    this.updateCartItems$ = this.service.updateCartItems$;
    this.selectedDeliveryFilters$ = this.service.deliveryFilters.asObservable();
    this.filteredProductList$ = this.service.filteredProductList.asObservable();
    this.SavedListItemsData$ = this.service.savedListItemsData$;
    this.savedListsData$ = this.service.savedListsData$;
    this.hasError = false;
    this.currencySymbol = Constants.CURRENCY_SYMBOL;
    
    this.filteringOptions = _fb.group({
      productFilteringOptions : ['']
    });
    this.productFilteringOptions = NavItems.PRODUCTFILTERS;
    this.sortingOptions = _fb.group({
      productSortingOptions : ['']
    });
    this.productSortingOptions = NavItems.PRODUCTSORTING;
    this.isDiscountSelected = false;
    this.saveListForm = new FormGroup({
      name: new FormControl('')
    });
    this.saveListSavingOption = 'existingList';
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    const cartViewButton = document.getElementById('cartViewButton');
    const viewMoreButton = document.getElementsByClassName('view-more-preview');
  }

  public checkForVisibility(): void{
    if (Object.keys(this.filteredProductList).filter(item => {
      return this.filteredProductList[item].length > 0;
    }).length > 1 || this.sellerFilters.length === Object.keys(this.filteredProductList).length){
      this.invalidFilter = false;
    }else{
      this.invalidFilter = true;
    }
  }

  ngOnInit(): void {
    // get form state Searched product data list
    // default filter
    this.sortLabel = 'relevance';
    this.filterLabel = null;

    this.searchedProductListData$.subscribe(
      data => {
        this.rawSearchedProductData = data;
        this.service.filterProductList(data, []);
        
        if (data?.errorInfo){
          this.hasError = true;
          this.noDataFound = data.errorInfo.errorMessage;
        }else{
          this.checkForEmptyResult(data);
        }
      }
    );

    // get filtered product list on the basis of delivery options
    this.service.getFilteredProductList().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(productList => {
      this.filteredProductList = productList;
      this.sortLabel = 'relevance';
      this.filterLabel = null;
      this.checkForVisibility();
      this.getFilterCategories(this.filteredProductList);
    })

    // get updated delivery filters selection
    this.service.getDeliveryFilter().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(deliveryFilters => {
      this.service.filterProductList(this.rawSearchedProductData, deliveryFilters/*for getting length of non empty sellers*/);
    });

    // get updated sellers selections
    this.service.getSellerFilter().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(sellerFilters => {
      this.sellerFilters = sellerFilters;
    });

    // get updated selected product List
    this.selectedProductListData$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.selectedProductList = data;
        this.updateSelectedVal(this.selectedProductList);
      }
    );

    // get updated sellers selections
    this.service.getProductOptionList().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(productOptionList => {
      this.productOptionList = productOptionList;
    });

    // get filtered Product List
    this.filteredProductList$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      filteredProductList => {
        this.filteredProductList = filteredProductList;
        this.getFilterCategories(this.filteredProductList);
      }
    );

    // Selected Product List
    this.updateCartItems$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.cartItems = data;
      }
    );

    // get updated saveList 
    this._productsService.getSavedListTriggered().subscribe(saveListTriggered => {
      this.saveListTriggered = saveListTriggered;
      this.selectedSavedList = this.saveListTriggered;
      if(saveListTriggered){
        this.countVal = null;
        this.isMenuCollapsed = false;
        this.savedListFlag = true;
        this.saveListMessage= this.translate.instant('showing_list_with_update_prices');
        setTimeout(() => {
          this.savedListFlag = false;
          this.saveListMessage= "";
        }, 4000);
      }
    });

    // Selected Product List
    this.savedListsData$.subscribe(
      data => {
        if(data){
          this.allSavedList = data;
        }else{
          this.store.dispatch(ProductActions.GetSavedLists());
        }
      }
    );

    this.appService.getCartMenuStatus().subscribe(menuStatus => {
      this.isMenuCollapsed = menuStatus;
    });

    this.service.getDiscountSelection().subscribe(selected => {
      this.isDiscountSelected = selected;
    });
  }

  public selectSavedlist(savedlist): void{
    console.log(savedlist);
    this.selectedSavedList = savedlist;
    this.saveListTriggered = savedlist;
  }

  public selectCategory(selectedItemCategories): void{
    console.log(selectedItemCategories);
    this.service.setItemCategory(selectedItemCategories);
  }

  public getFilterCategories(filteredProductList): void {
    this.selectedItemCategories = null;
    let itemCategories = [];
    Object.entries(filteredProductList).map(seller => {
      itemCategories = itemCategories.concat(this.service.getItemCategories(seller[1]));
    })
    this.itemCategories = itemCategories.filter((x, i) => itemCategories.indexOf(x) === i);
  }

  public checkForEmptyResult(data): void{
    let nonEmpty = _.filter(data, (seller) => { 
      return (seller.length > 0) 
    });
    if (nonEmpty.length > 0){
      this.hasError = false;
      this.store.select(ProductListingReducer.getSearchedKey).pipe(take(1)).subscribe( key => {
        this.searchedKey = key;
      });
    }else{
      this.hasError = true;
      this.noDataFound = "No Result Found. Search some other product";
    }
  }

  ngAfterViewInit(): void {
    // this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  public sellerCheck(selectedProduct, seller): String[]{
    return  _.intersectionWith(selectedProduct, seller, _.isEqual);;
  }

  public updateSelectedVal(selectedProductList: ProductList): void{
    let selectedProducts = [];
    
    const quantityArray = Object.entries(selectedProductList).map(seller => {
      if (seller[1].selectedProductList.length >0){
        selectedProducts.push(seller[0]);
      }
      return seller[1].selectedQuantity;
    });
    let availableSellers = this.sellerCheck(selectedProducts, this.sellerFilters);
    this.enableAddToCart = (Math.min(this.getNonEmptySellers().length, this.sellerFilters.length) <= (availableSellers.length || 4)) ? true : false;
    this.countVal = Math.max(...quantityArray);
    this.cdref.detectChanges();
  }

  public getNonEmptySellers(): Array<string> {
    return this.filteredProductList ? Object.keys(this.filteredProductList).filter(seller => 
      (this.filteredProductList[seller].length > 0) && this.productAvailability(seller)) : [];
  }

  public productAvailability(seller): boolean{
    return this.filteredProductList[seller].some( product => product.availability);
  }

  public expandMain(flag: boolean): void{
    this.expanded = flag;
  }

  public addProduct(quantityMatch){
    this.fullSellerOrder = Object.keys(this.selectedProductList);
    this.service.getSellerFilterOrder().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(sellerOrder => {
      this.fullSellerOrder = sellerOrder;
    });
    let quantityComparable = Object.entries(this.selectedProductList).some((store, i, arr) => { 
      return ((store[1].selectedUnit !== arr[0][1].selectedUnit) || (store[1].selectedMeasure !== arr[0][1].selectedMeasure))
    })
    this.checkForComparision(quantityComparable, quantityMatch);
  }

  public checkForComparision(quantityComparable, quantityMatch): void {
    if (quantityComparable){
      this.modalService.open(quantityMatch, { size: 'lg' });
    }else{
      this.moveToCart();
    }
  }

  public moveToCart(): void{
    this.modalService.dismissAll();
    this.flashToCart = true;
    var retrievedObject = localStorage.getItem('cachedCart');
    this.cartItems =  JSON.parse(retrievedObject)?.cartItems ? JSON.parse(retrievedObject).cartItems : this.cartItems ? this.cartItems : [];
    let items = this._productsService.finalizeCart(this.cartItems, this.selectedProductList, this.searchedKey);
    this.store.dispatch(ProductActions.UpdateCart({payload: items}));
    localStorage.setItem('cachedCart', JSON.stringify({'cartItems': items}));
    this.countVal = null;
    this.expandPreview = false;
    this.clearStoredData();
    setTimeout(() => {
      this.shakeMe = true;
    }, 1500);
    setTimeout(() => {
      this.flashToCart = false;
      this.shakeMe = false;
    }, 2000);
  }

  public bestPrice(selectedProductList: ProductList): number{
    
    const priceArray = Object.entries(selectedProductList).map(seller => {
      return seller[1].totalPrice;
    })
    
    return Math.min.apply(null, priceArray.filter(Boolean));

  }

  public clearStoredData(): void{
    
    this.store.dispatch(ProductActions.ClearProductList());
  }

  public selectFilteringOptions(option){
    this.filterLabel = option;
    option ? this.service.setfilterLabel(option.id) : this.service.setfilterLabel(null);
  }

  public selectSortingOptions(option){
    this.sortLabel = option;
    option ? this.service.setSortOption(option) : this.service.setSortOption(null);
  }

  public collapsePreviewMenu(): void {
    this.expandPreview = false;
    this.isMenuCollapsed = !this.isMenuCollapsed;
  }

  
  public selectDiscounted(event): void{
    this.service.setDiscountSelection(event);
  }

  public resetFilterSelections(): void{    
    this.service.setResetSelectedProductsStatus(true);
  }

  ngDoCheck(): void{
    this.selectFilterTranslated = this.translate.instant('select_ a_filter');
    this.selectProductTranslated = this.translate.instant('sort_product_by');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}


