export const NavItems: any = {
    DELIVERYOPTIONS: [
        { id: 'homedelivery', name: 'home_delivery' },
        { id: 'selfpickup', name: 'self_pick_up' },
        { id: 'driveinpickup', name: 'drive_in_pick_up' }
    ],
    PRODUCTFILTERS: [
        // { id: 'Avisvare', name: 'Tilbud' },
        { id: 'Økologisk', name: 'Økologisk' },
        { id: 'Frost', name: 'Frost' },
        { id: 'Nøglehul', name: 'Nøglehul' },
        { id: 'Glutenfri', name: 'Glutenfri' },
        { id: 'Laktosefri', name: 'Laktosefri' }
    ],
    PRODUCTSORTING: [
        { id: 'relevance', name: 'relevant' },
        { id: 'asc', name: 'price_low_to_high' },
        { id: 'dsc', name: 'price_high_to_low' },
        { id: 'alpha', name: 'a_to_z'},
        { id: 'reversealpha', name: 'z_to_a'}
    ],
    SELLERSLIST: [
        { id: 'rema', name: 'Rema' },
        { id: 'bilka', name: 'Bilka' },
        { id: 'nemlig', name: 'Nemlig' },
        { id: 'coop', name: 'Coop' },
        { id: 'foetex', name: 'Foetex' }
    ]
}

