import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectedProductsModel } from '../products/types/products.model';
import { Constants } from '../shared/constants';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as ProductActions from '../products/state/products.actions';
import * as ProductListingReducer from '../products/state/products.reducer';
import { NavItems } from 'src/app/navbar/_navItems';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { HomeService } from '../home.service';
import { filter, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsService } from '../products/products.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CartComponent implements OnInit {
  @Input() cartItems: Array<SelectedProductsModel>;
  @Input() cartInDetail: SelectedProductsModel[];
  // commonCartItems: object;
  // seperateCartItems: object;
  sellerDetail = _.cloneDeep(Constants.SellersDelliveryOptions);
  commonCartItems: BehaviorSubject<any>;
  seperateCartItems: BehaviorSubject<any>;
  commonCart: object;
  seperateCart: object;
  commonSellerFilters: string[];
  seperateSellerFilters: string[];
  commonTotalPrice: object;
  seperateTotalPrice: object;
  bestPrice: number;
  sellerOptions: any;
  sellerSeleced: boolean;
  defaultBest: string;
  currencySymbol: any;
  selectedSeller: any;
  sellerNameConstant: any;
  selectedProductListData$: Observable<any>;
  selectedProductList: unknown;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  fullSellerOrder: string[];
  searchedKey: any;
  dealSelection: any;
  pending_license_agreement_from_seller: any;
  loggedInUserData: any;
  emailSelectedProductForm: any;
  loggedInUserData$: Observable<any>;
  mailSent: boolean;
  mailResponse: any;
  isGuestIn: boolean;
  registerationReqMessage: any;

  constructor(
    private store: Store<ProductListingReducer.ProductListState>,
    private translate: TranslateConfigService,
    private translateService: TranslateService,
    private service: HomeService,
    private modalService: NgbModal,
    private _productsService: ProductsService,
    private _fb: FormBuilder
  ) { 
    this.commonCartItems = new BehaviorSubject<any>({});
    this.seperateCartItems = new BehaviorSubject<any>({});
    this.commonCart = {};
    this.seperateCart = {};
    this.commonTotalPrice = {}
    this.seperateTotalPrice = {}
    // this.sellerOptions = _.cloneDeep(NavItems.SELLERSLIST);
    this.sellerSeleced = false;
    this.currencySymbol = Constants.CURRENCY_SYMBOL;
    this.sellerNameConstant = Constants.SELLER_NAMES;
    this.selectedProductListData$ = this.service.selectedProductListData$;
    this.dealSelection = this._fb.group({
      dealOption: ['cheapest', Validators.required]
    });
    this.emailSelectedProductForm = this._fb.group({
      userEmail: ['', Validators.required]
    });
    this.loggedInUserData$ = this.service.loggedInUserData$;
    this.isGuestIn = true;
    this.registerationReqMessage= this.translateService.instant("registered_user_service");
  }

  ngOnInit(): void {
    this.service.getSellerFilter().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(sellerFilters => {
      this.sellerOptions = sellerFilters;
    });

    this.pending_license_agreement_from_seller = this.translateService.instant('pending_license_agreement_from_seller');

    if (this.cartInDetail){
      this.cartInDetail.map( cartGroup => {
        const cartItems = Object.entries(cartGroup.productList);
        
        let flag : boolean;
        let isMixedCart = Object.entries(cartGroup.productList).some(seller => {
          if(seller && this.sellerOptions.indexOf(seller[0]) !== -1){
            return seller[1]?.selectedProductList.length === 0 || !seller[1]?.selectedProductList[0].availability;
          }
        })
        // Object.entries(cartGroup.productList).some(obj => {
        //   if(obj[1]?.selectedProductList.length === 0 || !obj[1]?.selectedProductList[0].availability){
        //     this.processCartItems(cartGroup, 'seperate');
        //   }else{
        //     this.processCartItems(cartGroup, 'common');
        //   }
        // })
        if (isMixedCart){
          this.processCartItems(cartGroup, 'seperate');
          // this.seperateCartItems.push(cartGroup);
        }else{
          this.processCartItems(cartGroup, 'common');
          // this.commonCartItems.push(cartGroup);
        }
      });
      this.selectDealOption(this.dealSelection.value.dealOption);
    }
    

    // get updated selected product List
    this.selectedProductListData$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.selectedProductList = data;
      }
    );
    this.loggedInUserData$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.loggedInUserData = data.loggedInUserData;
        this.isGuestIn = false;
       
      }
    );
  }

  processCartItems(cartItem, cartType): void{
    switch (cartType) {
      case 'common':
        this.sellerOptions.map(seller => {
          if(cartItem.productList[seller]){
            let selectedProduct = _.cloneDeep(cartItem.productList[seller]?.selectedProductList[0]);
            if (!this.commonCart.hasOwnProperty(seller)){
              this.commonCart[seller] = [];
              selectedProduct['purchase_quantity'] = cartItem.productList[seller]?.selectedQuantity;
              this.commonTotalPrice[seller] = cartItem.productList[seller]?.totalPrice;
              this.commonCart[seller].push(selectedProduct);
              // this.selectProduct(productDetail);
            }else{
              selectedProduct['purchase_quantity'] = cartItem.productList[seller]?.selectedQuantity;
              this.commonTotalPrice[seller] = this.commonTotalPrice[seller] + cartItem.productList[seller]?.totalPrice;
              this.commonCart[seller].push(selectedProduct);
            }
          }
          
          // cartItem.productList[seller]
        })
        
        break;
      case 'seperate':
        this.sellerOptions.map(seller => {
          if(cartItem.productList[seller]){
            let selectedProduct = _.cloneDeep(cartItem.productList[seller]?.selectedProductList[0]);
            if (selectedProduct){
              if (!this.seperateCart.hasOwnProperty(seller)){
                this.seperateCart[seller] = [];
                selectedProduct['purchase_quantity'] = cartItem.productList[seller]?.selectedQuantity;
                this.seperateTotalPrice[seller] = cartItem.productList[seller]?.totalPrice;
                this.seperateCart[seller].push(selectedProduct);
                // this.selectProduct(productDetail);
              }else{
                selectedProduct['purchase_quantity'] = cartItem.productList[seller]?.selectedQuantity;
                this.seperateTotalPrice[seller] = this.seperateTotalPrice[seller] + cartItem.productList[seller]?.totalPrice;
                this.seperateCart[seller].push(selectedProduct);
              }
            }
          }
        })
          
          break;
      default:
        break;
    }
    console.log(this.commonCart, this.seperateCart);
  }

  public findCheaper():void {
    const prices = Object.values(this.commonTotalPrice);
    this.bestPrice = _.min(prices);
  }

  public findBestDeal(): void{
    this.bestPrice = null;
  }

  public updateCommmonCart(commonCart): void{
    this.commonSellerFilters = Object.keys(commonCart);
    this.commonCartItems.next(commonCart);
  }

  public updateSeperateCart(seperateCart): void{
    this.seperateSellerFilters = Object.keys(seperateCart);
    this.seperateCartItems.next(seperateCart);
  }

  public firstBest(): string{
    const sellerNames = Object.entries(this.commonTotalPrice).map(seller => {
      if (seller[1] === this.bestPrice){
        return seller[0];
      }
    })
    this.selectedSeller = this.selectedSeller ? this.selectedSeller : sellerNames.sort()[0];
    return sellerNames.sort()[0];
  }

  public selectSeller(seller): void{
    this.selectedSeller = seller;
  }

  public isLoggedIn(email: string): boolean{
    return email ? true : false;
  }

  public deleteProduct(product): void{
    let cartItems = _.cloneDeep(this.cartItems);
    const index = _.findIndex(cartItems, (item) => { return _.isMatch(item.productList, product.productList) });
    if (index > -1) {
      cartItems.splice(index, 1);
    }
    this.cartItems = [...cartItems];
    this.store.dispatch(ProductActions.UpdateCart({payload: this.cartItems}));
    localStorage.setItem('cachedCart', JSON.stringify({'cartItems': this.cartItems}));
  }

  public editProduct(quantityMatch, selectedProduct): void{
    this.searchedKey = selectedProduct.searchKey;
    this.modalService.open(quantityMatch, { size: 'lg' });
    this.fullSellerOrder = Object.keys(selectedProduct.productList);
    let product = this.cartItems.filter(item => {
      return item.searchKey === this.searchedKey;
    });
    this.selectedProductList = product[0].productList;
  }

  public moveToCart(): void{
    this.modalService.dismissAll();
    var retrievedObject = localStorage.getItem('cachedCart');
    this.cartItems =  JSON.parse(retrievedObject)?.cartItems ? JSON.parse(retrievedObject).cartItems : [];
    let items = this._productsService.finalizeCart(this.cartItems, this.selectedProductList, this.searchedKey);
    this.store.dispatch(ProductActions.UpdateCart({payload: items}));
    localStorage.setItem('cachedCart', JSON.stringify({'cartItems': items}));
  }

  public selectDealOption(dealSelection): void{
    localStorage.setItem('selectedDeal', dealSelection.dealOption);
    switch (dealSelection.dealOption) {
      case 'best':
        this.findBestDeal();
        break;
      default:
        this.findCheaper();
        break;
    }
    this.updateCommmonCart(this.commonCart);
    this.updateSeperateCart(this.seperateCart);
  }

  public sendSelectedProductEmail(): void{
    let reqObj = {
      cartItems: {
        seller: this.selectedSeller,
        selectedProductList: _.compact((this.commonCart[this.selectedSeller].concat(this.seperateCart[this.selectedSeller]))),
        description: "Demo"
      }
    }
    this.service.sendproductListMail(reqObj).pipe(takeUntil(this.ngUnsubscribe)).subscribe(mailResponse => {
      this.mailSent = true;
      this.mailResponse = mailResponse.success ? this.translateService.instant('mail_sent_successfully') :  'Something went wrong!';
      
      // console.log(favResponse);
    });
  }

  public closeMailModal(): void{
    this.modalService.dismissAll();
    setTimeout(() => {
      this.mailSent = false;
      this.mailResponse = '';
    });
  }

  public enterEmailinfo(email): void{
    if (email && this.loggedInUserData && this.loggedInUserData.email) {
      this.emailSelectedProductForm.patchValue({
        userEmail: this.loggedInUserData.email
      });
      this.modalService.open(email, { size: 'md' });
    }
  }
  
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
