<div class="alert alert-warning" role="alert" innerHtml="{{'product_volume_comaprission_popup_header' | translate}}">
</div>
<div class="row">
    <div class="col product-block" *ngFor="let sellerOrder of fullSellerOrder">
        <div *ngIf="selectedProductList[sellerOrder].selectedProductList.length > 0">
            <h6><span [ngClass]="{'member-indicator': checkMembership(sellerOrder)}">{{sellerOrder}} <sup *ngIf="checkMembership(sellerOrder)"> {{premiumLabelConstant[sellerOrder]}}</sup></span></h6>
            <p>{{selectedProductList[sellerOrder].selectedProductList[0].title}}</p>
            <p class="offer-desc mb-2" *ngIf="selectedProductList[sellerOrder].selectedProductList[0].offer && selectedProductList[sellerOrder].selectedProductList[0].offerdesc">{{selectedProductList[sellerOrder].selectedProductList[0].offerdesc}}</p>
            <span><span translate>price</span>: <strong [ngClass]="{'premium-price': checkMembership(sellerOrder) && selectedProductList[sellerOrder].selectedProductList[0].premium_price}">{{selectedProductList[sellerOrder].totalPrice | number: '1.2-2'}} <span class="price-symbol">{{currencySymbol.denmark}}</span></strong></span>
            <div class="d-flex align-items-center flex-row quantity-area mt-2 mb-2">
                <label class="mb-0">Adjust: </label>&nbsp;&nbsp;
                <div class="quantity-action">
                    <button class="btn btn-primary btn-sm" [disabled]="selectedProductList[sellerOrder].selectedQuantity === 0" (click)="minusQuantity(selectedProductList[sellerOrder].selectedProductList[0])"> <strong>-</strong> </button>
                    <span class="quantity-selected"> {{selectedProductList[sellerOrder].selectedQuantity}} </span>
                    <button class="btn btn-primary btn-sm" (click)="addQuantity(selectedProductList[sellerOrder].selectedProductList[0])"><strong> + </strong></button>
                </div>
            </div>
            <p><span><span translate>quantity</span>: <strong>{{selectedProductList[sellerOrder].selectedQuantity}}</strong></span><br />
            <span><span translate>volume</span>: <strong>{{selectedProductList[sellerOrder].selectedUnit}} ({{selectedProductList[sellerOrder].selectedMeasure}})</strong></span></p>
        </div>
    </div> 
</div>

