<div class="row mb-3" *ngIf="productDetail">
  <ng-container *ngFor="let sellerName of sellerOrder; let last=last">
    <div class="col product-listing-container" [ngClass]="listingType && {'best-price':bestPrice === pricingDetails[sellerName]}">
      <div class="product-listing">
        <div class="seller-header d-flex justify-content-between pb-2 mb-1">
          <h4 class="seller-name mb-0" *ngIf="!listingType" [ngClass]="{'member-indicator': checkMembership(sellerName)}">{{sellerNameConstant[sellerName]}} <sup *ngIf="checkMembership(sellerName)">{{premiumLabelConstant[sellerName]}}</sup></h4>
          <h4 class="seller-name mb-0" *ngIf="listingType" [ngClass]="{'member-indicator': checkMembership(sellerName)}">{{sellerNameConstant[sellerName]}} <sup *ngIf="checkMembership(sellerName)"> **P</sup></h4>
          <span class="total price" *ngIf="!listingType">Total: <strong>{{selectedProduct[sellerName].totalPrice | number: '1.2-2'}} <span class="price-symbol">{{currencySymbol.denmark}}</span></strong></span>
          <span class="total price" *ngIf="listingType">Total: <strong>{{pricingDetails[sellerName] | number: '1.2-2'}} <span class="price-symbol">{{currencySymbol.denmark}}</span></strong></span>
        </div>
        <div class="product-listing-content">
          <ng-container *ngFor="let productList of productDetail[sellerName]; index as i">
            <div class="card mb-2 product-card"
               *ngIf="selectedLabelProducts(productList) && (isDiscountSelected ? showDiscounted(productList) : true)"
               [ngClass]="{'best-price':checkforBestPrice(productList)}"
            >
              <div class="card-body">
                <div *ngIf="productList.foodType === 'organic'" class="foodTypeIcon organic">
                  <i class="fa fa-leaf" aria-hidden="true"></i>
                </div>
                <div *ngIf="productList.foodType === 'frost'" class="foodTypeIcon frost">
                  <i class="fa fa-snowflake-o" aria-hidden="true"></i>
                </div>
                <ng-container *ngIf="!listingType; else cartPreview">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="img-cont" [ngClass]="{'grey-out': !productList.availability}">
                        <div class="original-img">
                          <img class="mr-1" [src]='imageSource + productList.site + "_" + productList.product_id + ".jpg"' (error)="onImgError($event)" />
                        </div>
                        <i class="fa fa-expand" aria-hidden="true"></i>
                        <div class="inlarge-img">
                          <img class="mr-1" src='{{imageSource + productList.site + "_" + productList.product_id + ".jpg"}}' />
                        </div>
                      </div>
                      
                    </div>
                    <div class="col-sm-8">
                      <div class="d-flex justify-content-start">
                        <a [href]="productList.item_url" target="_blank"><h6 class="card-title">{{productList.title}}</h6></a>
                      </div>
                      <!-- <iframe [src]="url" allowTransparency="true" allow="encrypted-media" name="iframe_a" height="300px" width="100%" title="Iframe Example"></iframe>
                      <iframe is="x-frame-bypass" [src]="url"></iframe> -->
                     
                      <i *ngIf="!isGuestIn" class="fa fa-heart fav-icon" [ngClass]="{'active': isFavProduct(productList)}" [ngbTooltip]="add_fav" (click)="makeFav(productList)" aria-hidden="true"></i>
                      <i *ngIf="isGuestIn" placement="left" [ngbTooltip]="registerationReqMessage" class="fa fa-heart fav-icon" aria-hidden="true"></i>
                      <p class="card-text mb-3">{{productList.description}}</p>
                      
                      <p class="offer-desc mb-2" *ngIf="productList.offer && productList.offerdesc">{{productList.offerdesc}}</p>
                      <span *ngFor="let productLabels of productList.labels">
                        <span class="label-icons m-1 mr-2 mb-2" *ngIf="labelIcons[productLabels]"><img src='{{imgPath + labelIcons[productLabels]}}' alt="{{productLabels}}"></span>
                        <span *ngIf="!labelIcons[productLabels]"><small>{{productLabels}}</small></span>
                      </span>
                      <div class="d-flex align-items-center flex-row quantity-area mt-2 mb-2" *ngIf="productEnabledCart(productList) && productList.availability">
                        <label class="mb-0">Add: </label>&nbsp;&nbsp;
                        <div class="quantity-action">
                          <button class="btn btn-primary btn-sm" [disabled]="selectedProduct[sellerName].selectedQuantity === 0" (click)="minusQuantity(productList)"> <strong>-</strong> </button>
                          <span class="quantity-selected"> {{selectedProduct[sellerName].selectedQuantity}} </span>
                          <button class="btn btn-primary btn-sm" (click)="addQuantity(productList)"><strong> + </strong></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer d-flex justify-content-between align-items-center">
                    <div *ngIf="productList.availability"> 
                      <!-- || (this.deliveryFilter?.length === 3 && sellerCount > 1) -->
                      <button class="btn btn-success btn-sm select-product-btn" *ngIf="!productEnabledCart(productList)" [disabled]="(this.selectedProduct[productList.site].selectedProductList.length !== 0)  || (sellerCount < 1 && sellerCount === sellerFilter.length)" (click)="selectProduct(productList)" translate><i class="fa fa-shopping-basket mr-1" aria-hidden="true"></i>select</button>
                      <button class="btn btn-warning btn-sm" *ngIf="productEnabledCart(productList)" (click)="changeProduct(productList)" translate><i class="fa fa-shopping-basket mr-1" aria-hidden="true"></i>change</button>
                    </div>
                    <div *ngIf="!productList.availability"><strong translate style="color:red;">out_of_stock</strong></div>
                    <p class="card-text product-pricing">
                      <ng-container *ngIf="productList.offer && (productList.offer_data.length > 0); else noOffer">
                        <ng-container *ngFor="let offer of productList.offer_data">
                          <span class="d-flex flex-column align-items-center" *ngIf="offer.type === 'discount'; else noOffer">
                            <span class="discounted-price" [ngClass]="{'premium-price': checkMembership(sellerName) && productList.premium_price}">{{productList.price}} <span class="price-symbol">{{currencySymbol.denmark}}</span></span>
                            <span class="original-price" [ngClass]="{'premium-price': checkMembership(sellerName) && productList.premium_price}">{{(productList.price + offer.price) | number: '1.2-2'}} <span class="price-symbol">{{currencySymbol.denmark}}</span></span>
                          </span>
                        </ng-container>
                      </ng-container>
                      <ng-template #noOffer>
                        <span [ngClass]="{'premium-price': checkMembership(sellerName) && productList.premium_price}">{{productList.price}} <span class="price-symbol">{{currencySymbol.denmark}}</span></span>
                      </ng-template>
                    </p>
                  </div>
                </ng-container>
                <ng-template #cartPreview>
                  <h6 class="card-title">{{productList.title}}</h6>
                  <span *ngIf="!isGuestIn"><i class="fa fa-heart fav-icon" *ngIf="productList.favorite" [ngClass]="{'active': isFavProduct(productList)}" aria-hidden="true"></i></span>
                  <span *ngIf="isGuestIn"><i class="fa fa-heart fav-icon" [ngbTooltip]="registered_user_service" aria-hidden="true"></i></span>
                  <div class="card-text">{{productList.description}}</div>
                  <div class="d-flex justify-content-between">
                    <span *ngIf="!productList.availability"><strong translate style="color:red;">out_of_stock</strong></span>
                    <span *ngIf="productList.availability">Price: <span class="card-text" [ngClass]="{'premium-price': checkMembership(sellerName) && productList.premium_price}"><strong>{{productList.price}} <span class="price-symbol">{{currencySymbol.denmark}}</span></strong></span></span>
                    <span>Quantity: <span class="card-text"><strong>{{productList.purchase_quantity}}</strong></span></span>
                  </div>
                  <div class="d-flex align-items-center flex-row quantity-area mt-2 mb-2" *ngIf="productList.enableCart">
                    <label class="mb-0">Add: </label>&nbsp;&nbsp;
                    <div class="quantity-action">
                      <button class="btn btn-primary btn-sm" [disabled]="selectedQuantity === 0" (click)="minusQuantity(productList)"> <strong>-</strong> </button>
                      <span class="quantity-selected"> {{selectedQuantity}} </span>
                      <button class="btn btn-primary btn-sm" [disabled]="selectedQuantity >= 10" (click)="addQuantity(productList)"><strong> + </strong></button>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="sellerCount !== 5 && sellerFilter.length !== sellerCount && last && !listingType" class="col d-flex justify-content-center align-items-center placeholder-text text-center" translate>
      moved_to_mixed_not_available_in_all_store
    </div>
  </ng-container>
</div>
