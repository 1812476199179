import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ErrorPagesComponent } from './error-pages/error-pages.component';
import { AboutusComponent } from './home/pages/aboutus/aboutus.component';
import { PrivacyPolicyComponent } from './home/privacy-policy/privacy-policy.component';
import { SupportComponent } from './home/support/support.component';
import { UserComponent } from './home/user/user.component';
import { AuthService } from './services/auth/auth.service';

const routes: Routes = [
  
  { 
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'signin',
    component: LoginComponent
  },
  {
    path: 'device-error',
    component: ErrorPagesComponent
  },
  {
    path: 'support',
    component: SupportComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomeModule),
  },
  { 
    path: 'myprofile',
    component: UserComponent
  },
  {
    path: 'aboutus',
    component: AboutusComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule { }
