import { Component, Input, OnInit } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { Constants } from '../../shared/constants';
import { ProductsService } from '../products.service';
import * as ProductActions from '../../products/state/products.actions';
import * as ProductListingReducer from '../../products/state/products.reducer';
import { Store } from '@ngrx/store';
import { ProductDetailsModel, ProductList } from '../types/products.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeService } from '../../home.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-product-compare-modal',
  templateUrl: './product-compare-modal.component.html',
  styleUrls: ['./product-compare-modal.component.scss']
})
export class ProductCompareModalComponent implements OnInit {
  @Input() selectedProductList: any;
  @Input() fullSellerOrder: any;
  @Input() parentRef: any;
  currencySymbol: any;
  userPrefrencesData: any;
  premiumLabelConstant: any;
  sellerFilters: any;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private translate: TranslateConfigService,
    private _productsService: ProductsService,
    private store: Store<ProductListingReducer.ProductListState>,
    private modalService: NgbModal,
    private service: HomeService
  ) {
    this.currencySymbol = Constants.CURRENCY_SYMBOL;
    this.premiumLabelConstant = Constants.PREMIUM_LISTING_LABELS;
   }

  ngOnInit(): void {
    this.userPrefrencesData = this.service.getUserPremiumSites();

    // get updated sellers selections
    this.service.getSellerFilter().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(sellerFilters => {
      this.sellerFilters = sellerFilters;
      this.fullSellerOrder = _.intersection(this.fullSellerOrder, sellerFilters);
    });
  }

  public minusQuantity(product): void{
    const selectedProduct = this._productsService.minusQuantity(product, this.selectedProductList);
    this.selectedProductList = {...selectedProduct};
    this.updateAmount(this.selectedProductList);
  }

  public addQuantity(product: ProductDetailsModel): void{
    const selectedProduct = this._productsService.addQuantity(product, this.selectedProductList);
    this.selectedProductList = {...selectedProduct};
    this.updateAmount(this.selectedProductList);
  }

  public updateAmount(selectedProduct: ProductList): void{
    let selectedProducts = this._productsService.updateAmount(selectedProduct);
    this.updateSelectedCartItems(selectedProducts);
  }

  public updateSelectedCartItems(selectedProduct): void{
    this.store.dispatch(ProductActions.UpdateSelectedProductList({payload: selectedProduct}));
  }

  public checkMembership(seller): boolean{
    return (this.userPrefrencesData?.indexOf(seller) !== -1);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
