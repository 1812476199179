import {  BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ProductListingReducer from './products/state/products.reducer';
import { ProductDetailsModel, ProductListModel, SearchedProductModel, SelectedProductsModel } from './products/types/products.model';
import { SearchedObjectModel } from './search/types/search.model';
import { Constants } from './shared/constants';
import * as _ from 'lodash';
import * as ProductActions from '../home/products/state/products.actions';
import { SearchConstants } from './search/search_constants';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  public productSearchLoading$ = this.store.select(ProductListingReducer.getLoadingStatus);
  public searchedProductListData$ = this.store.select(ProductListingReducer.getSearchedProductList);
  public selectedProductListData$ = this.store.select(ProductListingReducer.getSelectedProductList);
  // public selectedDeliveryFilters$ = this.store.select(ProductListingReducer.getselectedDeliveryFilters);
  public updateCartItems$ = this.store.select(ProductListingReducer.getCartItems);
  public favProductListData$ = this.store.select(ProductListingReducer.getFavProductList);
  public savedListItemsData$ = this.store.select(ProductListingReducer.getSavedListItems);
  public savedListsData$ = this.store.select(ProductListingReducer.getSavedLists);
  public loggedInUserData$ = this.store.select(ProductListingReducer.getLoggedInUserData);
  public userSitePrefrencesData$ = this.store.select(ProductListingReducer.getUserSitePrefrences);
  
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  deliveryFilters: Subject<any>;
  filteredProductList: BehaviorSubject<any>;
  sellerFilters: Subject<any>;
  sellerFilterOrder: BehaviorSubject<any>;
  productOptionList: BehaviorSubject<any>;
  selectedCategory: Subject<any>;
  selectedLabel: Subject<any>;
  selectedSortOption: Subject<any>;
  isDiscountSelected: BehaviorSubject<boolean>;
  isResetSelectedProducts: Subject<any>;
  saveList: Subject<any>;
  loggedInUser: any;
  allSavedList: Subject<any>;
  userPreferencesData: BehaviorSubject<any>;
  userPremiumSites: void;
  userRegisterMessageStatus: BehaviorSubject<boolean>;
  
  constructor(
    private http: HttpClient,
    private store: Store<ProductListingReducer.ProductListState>
  ) {
    this.deliveryFilters = new Subject<any>();
    this.filteredProductList = new BehaviorSubject<any>(null);
    this.sellerFilters = new BehaviorSubject<any>(null);
    this.sellerFilterOrder = new BehaviorSubject<any>(null);
    this.selectedCategory = new Subject<any>();
    this.productOptionList = new BehaviorSubject<any>(SearchConstants.data);
    this.selectedLabel = new Subject<any>(); 
    this.selectedSortOption = new BehaviorSubject<any>('relevance');
    this.isDiscountSelected = new BehaviorSubject<boolean>(false); 
    this.isResetSelectedProducts = new Subject<any>();
    this.saveList = new Subject<any>();
    this.allSavedList = new Subject<any>();
    this.userPreferencesData = new BehaviorSubject<any>(null);
    this.userRegisterMessageStatus = new BehaviorSubject<boolean>(false);
  }

  public getSeachedProduct(reqObj: string): Observable<SearchedProductModel>{
    let url: string;
    let user = this.loggedInUser?.userEmail ? this.loggedInUser?.userEmail : 'guest_user';
    url = `${Constants.DOMAIN}${Constants.BASE_URI.searchApi}${reqObj}/${user}`;
    // url = 'api/product-search';
    return this.http.get<SearchedProductModel>(url);
  }

  public setFavProduct(reqObj: ProductDetailsModel): Observable<any>{
    let payload = {
      userInfo:{
        userId: this.loggedInUser?.userEmail
      },
      favouriteProduct: reqObj
    }
    let url: string;
    url = `${Constants.DOMAIN}${Constants.BASE_URI.addFavApi}/${payload.favouriteProduct.searchedKeyword}`;
    // url = 'api/product-search';
    return this.http.post<any>(url, payload);
  }

  public removeFavProduct(reqObj: ProductDetailsModel): Observable<any>{
    let payload = {
      userInfo:{
        userId: this.loggedInUser.userEmail
      },
      favouriteProduct: reqObj
    }
    let url: string;
    url = `${Constants.DOMAIN}${Constants.BASE_URI.removeFavApi}`;
    // url = 'api/product-search';
    return this.http.post<any>(url, payload);
  }

  public deleteUserProfile(emailId): Observable<any>{
    let url: string;
    url = `${Constants.DOMAIN}${Constants.BASE_URI.deleteUserprofileApi}${emailId}`;
    return this.http.get<any>(url);
  }
  
  public getFavProduct(): Observable<any>{
    let payload = {
      userInfo:{
        userId: this.loggedInUser?.userEmail ? this.loggedInUser?.userEmail : 'guest_user'
      }
    }
    let url: string;
    url = `${Constants.DOMAIN}${Constants.BASE_URI.getFavApi}${payload.userInfo.userId}`;
    // url = 'api/product-search';
    return this.http.get<any>(url);
  }

  public storeSaveList(saveListReqObj): Observable<any>{
    let payload = {
      userInfo:{
        userId: this.loggedInUser?.userEmail ? this.loggedInUser?.userEmail : 'guest_user'
      },
      listName: saveListReqObj.listName,
      itemInfo: saveListReqObj.itemInfo
    }
    let url: string;
    url = `${Constants.DOMAIN}${Constants.BASE_URI.saveListApi}`;
    // url = 'api/product-search';
    return this.http.post<any>(url, payload);
  }

  public getUserPrefrences(userEmail): Observable<any>{
    let payload = {
      userInfo:{
        userId: userEmail
      }
    }
    let url: string;
    url = `${Constants.DOMAIN}${Constants.BASE_URI.getUserPrefrenceApi}${payload.userInfo.userId}`;
    // url = 'api/user-preference';
    return this.http.get<any>(url);
  }

  public getSavedListItems(listName): Observable<any>{
    let payload = {
      userInfo:{
        userId: this.loggedInUser?.userEmail ? this.loggedInUser?.userEmail : 'guest_user'
      }
    }
    let url: string;
    url = `${Constants.DOMAIN}${Constants.BASE_URI.getSaveListItemsApi}${payload.userInfo.userId}/${listName}`;
    // url = 'api/product-search';
    return this.http.get<any>(url);
  }

  public getSavedLists(): Observable<any>{
    let payload = {
      userInfo:{
        userId: this.loggedInUser?.userEmail ? this.loggedInUser?.userEmail : 'guest_user'
      }
    }
    let url: string;
    url = `${Constants.DOMAIN}${Constants.BASE_URI.getSavedListsApi}${payload.userInfo.userId}`;
    // url = 'api/product-search';
    return this.http.get<any>(url);
  }

  public deleteSavedLists(listName): Observable<any>{
    let payload = {
      userInfo:{
        userId: this.loggedInUser?.userEmail ? this.loggedInUser?.userEmail : 'guest_user'
      }
    }
    let url: string;
    url = `${Constants.DOMAIN}${Constants.BASE_URI.deleteSavedListApi}/${listName}/${payload.userInfo.userId}`;
    // url = 'api/product-search';
    return this.http.get<any>(url);
  }

  public submitUserProfile(userDetailReqObj): Observable<any>{
    let payload = {
      firstName: userDetailReqObj.firstName,
      middleName: "",
      lastName: userDetailReqObj.lastName,
      emailId: userDetailReqObj.emailId,
      altEmailId: userDetailReqObj.alternateEmail,
      preferredLanguage: userDetailReqObj.preferedLanguage,
      areaCode: userDetailReqObj.areaCode,
      telephone: "",
      city: "",
      state: "",
      country: userDetailReqObj.countryName,
      address: "",
      createdBy: "",
      userValid: userDetailReqObj.acceptTerms,
      sitePreference: userDetailReqObj.preferedSellers,
      premiumSites: userDetailReqObj.premiumSites
    }
    let url: string;
    url = `${Constants.DOMAIN}${Constants.BASE_URI.myProfileApi}`;
    return this.http.post<any>(url, payload);
  }

  public sendproductListMail(reqObj: any): Observable<any>{
    reqObj['cartItems']['userId'] = this.loggedInUser.userEmail;
    let payload = reqObj;
    let url: string;
    url = `${Constants.DOMAIN}${Constants.BASE_URI.emailNotificationApi}`;
    // url = 'api/product-search';
    return this.http.post<any>(url, payload);
  }

  public hasOption(selectedDelivery, seller): boolean{
    const found = (_.intersection(seller.deliveryOptions, selectedDelivery).length === 0) ? true : false;
    return found;
  }

  public filterProductList(searchedProductList, selectedDelivery): void{
    if (searchedProductList){
      let productList: ProductListModel = _.cloneDeep(searchedProductList);
      let tempList = {};
      let sellerFilters = [];
      if (selectedDelivery?.length > 0){
        const sellerDetail = Constants.SellersDelliveryOptions;
        sellerDetail.map(seller => {
          if (this.hasOption(selectedDelivery, seller)){
            tempList[seller.name] = [];
          }else{
            tempList[seller.name] = productList[seller.name];
          }
        });
      }else{
        tempList = productList;
      }
      this.filteredProductList.next(tempList);
    }else{
      this.filteredProductList.next(null);
    }
  }

  public updateDeliveryFilters(deliveryFilters): void{
    this.deliveryFilters.next(deliveryFilters);
  }

  public clearSelectedProduct(): void{
    this.filterProductList(null, this.deliveryFilters);
    this.store.dispatch(ProductActions.ClearProductList());
  }

  public getDeliveryFilter(): Observable<any> {
    return this.deliveryFilters.asObservable();
  }

  public getFilteredProductList(): Observable<any> {
    return this.filteredProductList.asObservable();
  }

  public updateSellerFilters(sellerFilters): void{
    this.sellerFilters.next(sellerFilters);
  }

  public getSellerFilter(): Observable<any> {
    return this.sellerFilters.asObservable();
  }

  public setSellerFilterOrder(orderedArray): void{
    this.sellerFilterOrder.next(orderedArray);
  }

  public getSellerFilterOrder(): Observable<any> {
    return this.sellerFilterOrder.asObservable();
  }

  public updateProductOptionList(updatedOrderedArray): void{
    this.productOptionList.next(updatedOrderedArray);
  }

  public getProductOptionList(): Observable<any> {
    return this.productOptionList.asObservable();
  }

  public getItemCategories(productList): Array<string>{
    let itemCategory = [];
    productList.map(item => {
      if (item.availability){
        itemCategory = itemCategory.concat(item.item_category);
      }
    })
    return itemCategory;
  }

  public getItemCategory(): Observable<any> {
    return this.selectedCategory.asObservable();
  }

  public setItemCategory(selectedCategory): void{
    this.selectedCategory.next(selectedCategory);
  }

  public getfilterLabel(): Observable<any> {
    return this.selectedLabel.asObservable();
  }

  public setfilterLabel(selectedLabel): void{
    this.selectedLabel.next(selectedLabel);
  }
  
  public getSortOption(): Observable<any> {
    return this.selectedSortOption.asObservable();
  }

  public setSortOption(selectedOption): void{
    this.selectedSortOption.next(selectedOption);
  }

  public getDiscountSelection(): Observable<any> {
    return this.isDiscountSelected.asObservable();
  }

  public setDiscountSelection(discountSelected): void{
    this.isDiscountSelected.next(discountSelected);
  }

  public getResetSelectedProductsStatus(): Observable<any> {
    return this.isResetSelectedProducts.asObservable();
  }

  public setResetSelectedProductsStatus(status): void{
    this.isResetSelectedProducts.next(status);
  }

  public getSaveList(): Observable<any> {
    return this.saveList.asObservable();
  }

  public setSaveList(list): void{
    this.saveList.next(list);
  }

  public getAllSavedList(): Observable<any> {
    return this.allSavedList.asObservable();
  }

  public setAllSavedList(lists): void{
    this.allSavedList.next(lists);
  }

  public getUserInfo(): Observable<any> {
    return this.loggedInUser;
  }

  public setUserInfo(userInfo): void{
    this.loggedInUser = {
      userDisplayName : '',
      userId: `$(userInfo.uid.substring(0,6))martuser$(userInfo.providerId.split('.')[0])`,
      userEmail: userInfo.email,
      userPhoneNumber: ''
    }
  }

  public getUserPreferencesData(): Observable<any> {
    return this.userPreferencesData.asObservable();
  }

  public setUserPreferencesData(data): void{
    this.userPreferencesData.next(data);
  }

  public getUserPremiumSites(): void {
    return this.userPremiumSites;
  }

  public setUserPremiumSites(userPremiumSites): void{
    this.userPremiumSites = userPremiumSites;
  }

  public getRegisterMessageStatus(): Observable<any>{
    return this.userRegisterMessageStatus.asObservable();
  }

  public setRegisterMessageStatus(userMessageStatus): void{
    this.userRegisterMessageStatus.next(userMessageStatus);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
