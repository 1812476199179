import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as ProductListingReducer from '../../products/state/products.reducer';
import * as ProductActions from '../../products/state/products.actions';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { HomeService } from '../../home.service';
import { SavedListsModel, SearchedProductModel, SelectedProductsModel } from '../../products/types/products.model';
import * as _ from 'lodash';
import { ProductsService } from '../../products/products.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cart-preview',
  templateUrl: './cart-preview.component.html',
  styleUrls: ['./cart-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CartPreviewComponent implements OnInit {

  @Input() expandPreview: boolean;
  @Input() cartItems: Array<SelectedProductsModel>;
  @Output() savedListSaveTrigger = new EventEmitter<boolean>();
  productListing: any;
  selectedProductList: any;
  countVal: number;
  searchedProductListData$: Observable<SearchedProductModel>;
  selectedProductListData$: Observable<any>;
  productSearchLoading$: Observable<boolean>;
  selectedDeliveryFilters$: Observable<Array<string>>;
  updateCartItems$: Observable<any>;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  expanded: boolean;
  filteredProductList$: Observable<any>;
  rawSearchedProductData: SearchedProductModel;
  subscription: any;
  filteredProductList: SearchedProductModel;
  isMenuCollapsed: boolean;
  enableAddToCart: boolean;
  flashToCart: boolean;
  shakeMe: boolean;
  hasError: boolean;
  noDataFound: string;
  movies: string[];
  productOptionList: any;
  searchedKey: string;
  itemCategories: any;
  selectedItemCategories: number;
  filterLabel: any;
  sortLabel: any;
  filteringOptions: FormGroup;
  productSortingOptions: any;
  productFilteringOptions: any;
  sortingOptions: FormGroup;
  isDiscountSelected: boolean;
  resetSelectedProducts: boolean;
  saveList: any;
  saveListForm: FormGroup;
  saveListMessage: string;
  savedListFlag: boolean;
  triggerSaveList: boolean;
  fullSellerOrder: any;
  saveListTriggered: any;
  saveListSavingOption: any;
  SavedListItemsData$: Observable<SearchedProductModel>;
  allSavedList: SavedListsModel;
  invalidFilter: boolean;
  savedListsData$: Observable<SavedListsModel>;
  selectedSavedList: any;
  currencySymbol: any;
  loggedInUserData$: Observable<any>;
  loggedInUserData: any;
  isGuestIn: boolean;
  registerationReqMessage: any;
  
  constructor(
    private service: HomeService,
    private store: Store<ProductListingReducer.ProductListState>,
    private modalService: NgbModal,
    private appService: AppService,
    private _productsService: ProductsService,
    private translate: TranslateService
  ) {
    this.savedListsData$ = this.service.savedListsData$;
    this.loggedInUserData$ = this.service.loggedInUserData$;
    this.saveListForm = new FormGroup({
      name: new FormControl('')
    });
    this.saveListSavingOption = 'existingList';
    this.isGuestIn = true;
    this.registerationReqMessage= this.translate.instant("registered_user_service");
   }

  ngOnInit(): void {
    this.savedListsData$.subscribe(
       data => {
        if(data){
          this.allSavedList = data;
        }else{
          this.store.dispatch(ProductActions.GetSavedLists());
        }
      }
    );

    this._productsService.getSavedListTriggered().pipe(filter(data => data !== null || data !== undefined)).subscribe(saveListTriggered => {
      this.saveListTriggered = saveListTriggered;
      this.selectedSavedList = this.saveListTriggered;
      if(saveListTriggered){
        this.countVal = null;
        this.savedListFlag = true;
        this.saveListMessage= this.translate.instant('showing_list_with_update_prices');
        setTimeout(() => {
          this.savedListFlag = false;
          this.saveListMessage= "";
        }, 4000);
      }
    });

    this.loggedInUserData$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.loggedInUserData = data.loggedInUserData;
        this.isGuestIn = false;
      }
    );
  }

  public toggleExpandedPreview(): void {
    this.expandPreview = !this.expandPreview;
    this.appService.setCartMenuStatus(this.expandPreview );
    // this.isMenuCollapsed = this.expandPreview ? false : true;
  }

  public saveListModal(saveList): void{
    // get updated saveList 
    this.service.getSaveList().pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(saveLists => {
      this.saveList = saveLists;
    });
    this.modalService.open(saveList, { size: 'md' });
  }

  public selectSavedlist(savedlist): void{
    console.log(savedlist);
    this.selectedSavedList = savedlist;
    this.saveListTriggered = savedlist;
  }

  public saveSelectedProductsList(saveListForm): void{
    // let listName = this.saveListSavingOption === 'existingList'  ? this.saveListTriggered : saveListForm.value.name;
    const saveList= _.cloneDeep(this.saveList) ? _.cloneDeep(this.saveList) : [];
    const cartList = {
      listName: this.saveListSavingOption === 'existingList'  ? this.saveListTriggered : saveListForm.value.name,
      itemInfo:[]
    };
    let searchedProducts = [];
    this.cartItems.map(item => {
      Object.entries(item.productList).map(store => {
        if (store[1].selectedProductList.length > 0){
          // cartList.itemInfo.map(item => {
          //   item.itemIds
          // })
          let productIdIndex = searchedProducts.indexOf(store[1].selectedProductList[0].id);
          if(productIdIndex === -1){
            searchedProducts.push(store[1].selectedProductList[0].id);
            let item = {
              itemIds: store[1].selectedProductList[0].id,
              keyword: store[1].selectedProductList[0].searchedKeyword,
              quantity: store[1].selectedQuantity
            }
            cartList.itemInfo.push(item);
          }else{
            cartList.itemInfo[productIdIndex].quantity = cartList.itemInfo[productIdIndex].quantity + store[1].selectedQuantity;
          }
        }
      });
    });
    this.triggerSaveList = true;
    // const saveListObj = 
    this.service.storeSaveList(cartList).pipe(takeUntil(this.ngUnsubscribe)).subscribe(setSaveListResponse => {
      this.savedListFlag = false;
      if (setSaveListResponse.success){
        this.triggerSaveList = false;
        this.modalService.dismissAll();
        this.savedListFlag = true;
        this.saveListMessage= this.translate.instant("list_is_successfully_saved");
        this.store.dispatch(ProductActions.GetSavedLists());
        // this.clearCart();
      }else{
        this.triggerSaveList = false;
        this.modalService.dismissAll();
        this.savedListFlag= true;
        this.saveListMessage= "Something went wrong. Try Again";
      }
      setTimeout(() => {
        this.clearCart();
        this.savedListFlag = false;
        this.saveListMessage= "";
      },3000);
      this.savedListSaveTrigger.emit(false);
    });

    saveList.push(cartList.listName);
    this.service.setSaveList(saveList);
    saveListForm.value.name = '';
    console.log("Save List: ", cartList);
  }

  public onChangeSaveOption(optionName): void {
    this.saveListSavingOption = optionName;
  }

  public clearCart(): void{
    this.store.dispatch(ProductActions.ClearCart());
    localStorage.removeItem('cachedCart');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
