import { DoCheck, Injectable, NgZone, OnInit } from '@angular/core';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import * as firebase from 'firebase';
import { HomeService } from 'src/app/home/home.service';
@Injectable({
  providedIn: 'root'
})

export class AuthService implements DoCheck{
  userData: any; // Save logged in user data
  loggedInUserData: Subject<any>;
  userPrefrences: any;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  linkUserData: any;
  guestUser: any;

  constructor(
    // public afs: AngularFirestore,   // Inject Firestore service
    private readonly afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,  
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public service: HomeService
  ) {  
    this.loggedInUserData = new Subject<any>();  
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        
        this.setLoggedInUserinfo(user);
        this.service.getUserPrefrences(this.userData.email).pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(userPrefrences => {
          this.userPrefrences = userPrefrences;
          if(!this.userPrefrences?.userInfo?.userValid){
            this.router.navigate(['myprofile']);
          }else{
            this.router.navigate(['home']);
          }
        });
        
      } else {
        this.router.navigate(['signin']);
      }
    })
  }

  public getLoggedInUserinfo(): Observable<any> {
    return this.loggedInUserData.asObservable();
  }

  public setLoggedInUserinfo(userData): void{
    // this.loggedInUserData.next(userData);
    const provider = userData?.providerData[0]?.providerId?.split('.')[0];
    switch (provider) {
      case 'google':
      case 'facebook':
        this.loggedInUserData.next(userData.providerData[0]);
        break;
    
      default:
        userData
        break;
    }
  }

  // Firebase SignInWithPopup
  OAuthProvider(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((res) => {
        this.ngZone.run(() => {
            this.router.navigate(['home']);
        })
      }).catch((error) => {
        if (error.code == 'auth/account-exists-with-different-credential') {
          
          const retRes = {'res': 'account-exists-with-different-credential', 'error': error};
          this.linkUserData = retRes;
        } else {
          console.log('error', error)
          this.ngZone.run(() => {
            this.router.navigate(['signin']);
          })
          window.alert(error);
        }
        
      })
  }

  // Firebase Google Sign-in
  SigninWithGoogle() {
    return this.OAuthProvider(new firebase.default.auth.GoogleAuthProvider());
  }

  SigninWithFb() {
    //const fbres = this.OAuthProvider(new firebase.default.auth.FacebookAuthProvider());
    //return fbres;
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.default.auth.FacebookAuthProvider();
      this.afAuth.signInWithPopup(provider).then(result => {
        resolve(result);
      },  error => {
        console.log(error); //error.code email message
        //An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.
        if (error.code == 'auth/account-exists-with-different-credential') {
          
          const pendingCred = error.credential;
          const email = error.email;
          resolve({'res': 'account-exists-with-different-credential', 'error': error});
          
        } else {
          reject(error);
        }
        
      });
    });
  }

  // Firebase Logout 
  SignOut() {
      return this.afAuth.signOut().then(() => {
          this.router.navigate(['signin']);
      })
  }

  ngDoCheck(){
  this.service.getUserPreferencesData().subscribe(userPrefrences => {
    this.userPrefrences = userPrefrences.userInfo.premiumSites;
  })
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    //   // true for mobile device
    //   this.router.navigate(['device-error']);
    // }else{
    //   this.service.getUserPreferencesData().pipe(filter(userPrefrences => userPrefrences !== null)).subscribe(userPrefrences => {
    //     this.userPrefrences = userPrefrences;
    //   });
    //   if (!this.userData) {
    //     this.router.navigate(['signin']);
    //     return false;
    //   }else if(this.userData && !this.userPrefrences?.userInfo?.userValid){
    //     this.router.navigate(['myprofile']);
    //     return false;
    //   }else{
    //     // this.router.navigate(['home']);
    //     return this.userPrefrences?.userInfo?.userValid;
    //   }
    // }
    this.service.getUserPreferencesData().pipe(filter(userPrefrences => userPrefrences !== null)).subscribe(userPrefrences => {
      this.userPrefrences = userPrefrences;
    });
    this.service.getRegisterMessageStatus().subscribe(status => {
      this.guestUser = status;
    });
    if (!this.userData && !this.guestUser) {
      this.router.navigate(['signin']);
      return false;
    }else if(this.userData && !this.userPrefrences?.userInfo?.userValid && !this.guestUser){
      this.router.navigate(['myprofile']);
      return false;
    }else{
      // this.router.navigate(['home']);
      return this.userPrefrences?.userInfo?.userValid ? this.userPrefrences?.userInfo?.userValid : this.guestUser;
    }
  }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): Observable<boolean>|Promise<boolean>|boolean {
  //   if (!this.userData) {
  //     this.router.navigate(['signin']);
  //     return false;
  //   }else if(this.userData && !this.userPrefrences?.userInfo?.userValid){
  //     this.router.navigate(['myprofile']);
  //     return false;
  //   }
  //   // else if(!this.userPrefrences?.userInfo?.userValid){
  //   //   this.router.navigate(['/home/myprofile']);
  //   // }
  //   else{
  //     return true;
  //   }
  // }

  linkSocialAccounts(email: string, pendingCred: any){
    return new Promise<any>((resolve, reject) => {
      const that = this;
      this.afAuth.fetchSignInMethodsForEmail(email).then(function(methods) {
        
        let currProvider: any = that.getProviderForProviderId(methods[0]);
        that.afAuth.signInWithPopup(currProvider).then(function(result: any) { //linkAndRetrieveDataWithCredential
          result.user.linkWithCredential(pendingCred).then(function(usercred: any) {
            
            if(usercred.credential.idToken) {
              resolve({'linkedres': 'success'});
            }
            
          });
        }, error => {
          window.alert(error);
        });
      });
    });
    
  }

  getProviderForProviderId(providerId: any) {
    switch (providerId) {
      case firebase.default.auth.GoogleAuthProvider.PROVIDER_ID:
        return new firebase.default.auth.GoogleAuthProvider();
      case firebase.default.auth.FacebookAuthProvider.PROVIDER_ID:
        return new firebase.default.auth.FacebookAuthProvider();
      // case firebase.default.auth.GithubAuthProvider.PROVIDER_ID:
      //   return new firebase.default.auth.GithubAuthProvider();
      default:
        throw new Error(`No provider implemented for ${providerId}`);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
