import { Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  @ViewChild("loginlinkconfirm") modalContent: TemplateRef<any>;
  
  constructor(
    private readonly authService: AuthService,
    private modalService: NgbModal,
    public router: Router,  
    public ngZone: NgZone,
  ) { }

  ngOnInit(): void {
  }

  public appSignin(provider): void{
    this.modalService.dismissAll();
    switch (provider) {
      case 'google':
        this.authService.SigninWithGoogle();
        break;
      case 'facebook':
         this.authService.SigninWithFb().then((userData) => {
          if(userData.res == 'account-exists-with-different-credential') {
            this.showLinkConfirmation();
            this.doLinkProviders(userData);
          } else {
            this.ngZone.run(() => {
              this.router.navigate(['home']);
            })
          }
        }).catch((error: any) => {
          this.ngZone.run(() => {
            this.router.navigate(['signin']);
          })
          window.alert(error);
        });
        break;
      default:
        break;
    }
  }

  public showLinkConfirmation(): void {
    this.modalService.open(this.modalContent, { centered: true }).result.then((result) => {
    //this.modalService.dismissAll();
    }, (reason) => {
     
    });
  }

  doLinkProviders(linkRes: any) {
    const userData = linkRes;
    this.authService.linkSocialAccounts(linkRes.error.email, linkRes.error.credential).then(linkedRes => {
      if(linkedRes.linkedres == 'success') {
        this.modalService.dismissAll();
        this.ngZone.run(() => {
          this.router.navigate(['home']);
        })
        
      }
    });
  }
}
