import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HomeService } from '../../home.service';
import * as  ProductActions from './products.actions';

@Injectable()
export class ProductListingEffects {

    loadProductListingView$ = createEffect(() => this.actions$.pipe(
        ofType(ProductActions.GetProductList),
        mergeMap( action => this.homeService.getSeachedProduct(action.payload).pipe(
            map(productListData => ({
                type: ProductActions.ProductListingActionTypes.LOAD_PRODUCT_SUCCESS,
                payload: productListData.simpleSearchResponse
            })),
            catchError(() => of({
                type: ProductActions.ProductListingActionTypes.LOAD_PRODUCT_FAILURE,
                payload: {errorInfo:{errorMessage: "Something went wrong! Retry Later"}}
            }))
        ))
    ));

    getFavProduct$ = createEffect(() => this.actions$.pipe(
        ofType(ProductActions.GetFavList),
        mergeMap( action => this.homeService.getFavProduct().pipe(
            map(apiResponse => ({
                type: ProductActions.ProductListingActionTypes.LOAD_FAV_SUCCESS,
                payload: apiResponse
            })),
            catchError(() => of({
                type: ProductActions.ProductListingActionTypes.LOAD_PRODUCT_FAILURE,
                payload: {errorInfo:{errorMessage: "Something went wrong! Retry Later"}}
            }))
        ))
    ));

    getSavedListsItemsList$ = createEffect(() => this.actions$.pipe(
        ofType(ProductActions.GetSavedListItems),
        mergeMap( action => this.homeService.getSavedListItems(action.payload).pipe(
            map(apiResponse => ({
                type: ProductActions.ProductListingActionTypes.LOAD_SAVED_LIST_ITEMS_SUCCESS,
                payload: apiResponse
            })),
            catchError(() => of({
                type: ProductActions.ProductListingActionTypes.LOAD_SAVED_LIST_ITEMS_FAILURE,
                payload: {errorInfo:{errorMessage: "Something went wrong! Retry Later"}}
            }))
        ))
    ));

    getSavedLists$ = createEffect(() => this.actions$.pipe(
        ofType(ProductActions.GetSavedLists),
        mergeMap( action => this.homeService.getSavedLists().pipe(
            map(apiResponse => ({
                type: ProductActions.ProductListingActionTypes.LOAD_SAVED_LISTS_SUCCESS,
                payload: apiResponse
            })),
            catchError(() => of({
                type: ProductActions.ProductListingActionTypes.LOAD_SAVED_LISTS_FAILURE,
                payload: {errorInfo:{errorMessage: "Something went wrong! Retry Later"}}
            }))
        ))
    ));

    // loadUserPreferencesData$ = createEffect(() => this.actions$.pipe(
    //     ofType(ProductActions.GetUserPrefrencesData),
    //     mergeMap( action => this.homeService.getUserPrefrences().pipe(
    //         map(userPreferenceData => ({
    //             type: ProductActions.ProductListingActionTypes.LOAD_USER_PREFRENCES_SUCCESS_DATA,
    //             payload: userPreferenceData
    //         })),
    //         catchError(() => of({
    //             type: ProductActions.ProductListingActionTypes.LOAD_USER_PREFRENCES_FAILURE_DATA,
    //             payload: {errorInfo:{errorMessage: "Something went wrong! Retry Later"}}
    //         }))
    //     ))
    // ));

    constructor(
        private actions$: Actions,
        private homeService: HomeService
    ) {}
}
