<div class="container-fluid">
    <div class="row d-flex justify-content-center align-items-start">
        <div class="col-sm-9 my-3">
            <div class="content" role="main">
                <div class="form-group mb-4 px-5">
                    <input class="form-control" type="text" [(ngModel)]="searchText" placeholder="Type for similar queries.." >
                </div>
                <div class="card">
                    <!-- <ul class="list-group list-group-flush" *ngIf="(faqQuestions | filterFaq: searchText).length > 0; else noResults">
                        <li class="list-group-item" *ngFor="let faq of faqQuestions | filterFaq: searchText; let i = index">
                            <strong>Q{{i + 1}}. {{faq.ques}}</strong><br />
                            Ans- {{faq.ans}}
                        </li>
                    </ul> -->
                    <ul class=" list-group-flush" *ngIf="(faqQuestions | filterFaq: searchText).length > 0; else noResults">
                        <li class="mb-3" *ngFor="let faq of faqQuestions | filterFaq: searchText; let i = index">
                            <strong translate>support_faq.{{faq.ques}}
                                
                            </strong><br />
                            <span translate>support_faq.{{faq.ans}}</span>
                        </li>
                    </ul>
                    <ng-template #noResults>
                        <p>No results found for "{{faqText}}".</p>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="mail-to-block">
            <a class="mailto" href="mailto:visavehelp@gmail.com"><i class="fa fa-envelope" aria-hidden="true"></i> Write to us</a>
        </div>
    </div>
</div>
<footer class="footer mt-auto py-2">
    <div class="container">
      <span class="d-flex justify-content-center">ARN  | CVR# 32 50 21 05 | visavehelp@gmail.com &copy;<a [routerLink]="['/privacy-policy']">Privacy Policy</a></span>
    </div>
</footer>