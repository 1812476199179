export class UserPreferenceMock {
    public static data = {
        favourite: true,
        savedLists: [
            'test list'
        ],
        userInfo: {
            firstName: 'rohit',
            middleName: '',
            lastName: 'kandpal',
            emailid: 'rohitkandpal89@gmail.com',
            preferedLanguage: 'en',
            areaCode: '1234',
            telephone: '',
            city: '',
            state: '',
            country: 'Denmark',
            address: '',
            createdon: null,
            createdby: '',
            userValid: true,
            alternate_email: null,
            site_preference: [
                'Rema',
                'Coop',
                'Nemlig',
                'Bilka'
            ]
        }
    }
}