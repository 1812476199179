import { InMemoryDbService } from 'angular-in-memory-web-api';
import { ProductSearchMock } from './product-search-mock';
import { UserPreferenceMock } from './user-preference-mock';

export class FakeDbService implements InMemoryDbService {
    createDb(): any {
        return {
            'product-search': ProductSearchMock.data,
            'user-preference': UserPreferenceMock.data
        }
    }
}