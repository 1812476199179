<div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h6 class="page-title mt-3 pb-1">My favorite Products</h6>
      </div>
    </div>
    <div class="row mb-3">
      <ng-template #loading>
        <div class="loader-overlay">
          <div class="loader"></div>
      </div>
      </ng-template>
      <ng-container *ngIf="(favProductsList$ | async); else loading">
        <ng-container *ngFor="let sellerName of sellerOrder">
          <div class="col-md-3 product-listing-container" *ngIf="favProductsList[sellerName].length > 0 && sellerVisibility(sellerName);">
            <div class="product-listing">
              <div class="seller-header d-flex justify-content-between pb-2 mb-1">
                <h4 class="seller-name mb-0">{{sellerNameConstant[sellerName]}}</h4>
              </div>
              <div class="product-listing-content">
                <ng-container *ngFor="let productList of favProductsList[sellerName]; index as i">
                  <div class="card mb-2 product-card">
                    <div class="card-body">
                      <ng-container>
                        <div class="row">
                          <div class="col-sm-4">
                            <div class="img-cont">
                              <div class="original-img">
                                <img class="mr-1" [src]='imageSource + productList.site + "_" + productList.product_id + ".jpg"' (error)="onImgError($event)" />
                              </div>
                              <i class="fa fa-expand" aria-hidden="true"></i>
                              <div class="inlarge-img">
                                <img class="mr-1" src='{{imageSource + productList.site + "_" + productList.product_id + ".jpg"}}' />
                              </div>
                            </div>
                            
                          </div>
                          <div class="col-sm-8">
                            <a [href]="productList.item_url" target="_blank"><h6 class="card-title">{{productList.title}}</h6></a>
                            <i class="fa fa-heart fav-icon" [ngClass]="{'active': productList.favorite}" title="Add as favourite" (click)="removeFav(productList)" aria-hidden="true"></i>
                            <p class="card-text mb-3">{{productList.description}}</p>
                      
                            <p class="offer-desc mb-2" *ngIf="productList.offer && productList.offerdesc">{{productList.offerdesc}}</p>
                            <span *ngFor="let productLabels of productList.labels">
                              <span class="label-icons m-1 mr-2 mb-2" *ngIf="labelIcons[productLabels]"><img src='{{imgPath + labelIcons[productLabels]}}' alt="{{productLabels}}"></span>
                              <span *ngIf="!labelIcons[productLabels]"><small>{{productLabels}}</small></span>
                            </span>
                          </div>
                          <div class="col-sm-12">
                            <div class="card-footer d-flex justify-content-between align-items-center">
                              <div *ngIf="productList.availability">
                                <button class="btn btn-success btn-sm select-product-btn" (click)="selectProduct(productList)" translate><i class="fa fa-shopping-basket mr-1" aria-hidden="true"></i>search_similar</button>
                              </div>
                              <div *ngIf="!productList.availability"><strong translate style="color:red;">out_of_stock</strong></div>
                              <p class="card-text product-pricing">
                                <ng-container *ngIf="productList.offer && (productList.offer_data.length > 0); else noOffer">
                                  <ng-container *ngFor="let offer of productList.offer_data">
                                    <span class="d-flex flex-column align-items-center" *ngIf="offer.type === 'discount'; else noOffer">
                                      <span class="discounted-price">{{productList.price}}</span>
                                      <span class="original-price">{{(productList.price + offer.price) | number: '1.2-2'}}</span>
                                    </span>
                                  </ng-container>
                                </ng-container>
                                <ng-template #noOffer>
                                  <span>{{productList.price}}</span>
                                </ng-template>
                              </p>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  
  