import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { HomeService } from '../home.service';
import { Constants } from '../shared/constants';
import * as _ from 'lodash';
import * as ProductListingReducer from '../products/state/products.reducer';
import * as ProductActions from '../products/state/products.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  profileForm: FormGroup;
  submitted = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  loggedInUser: any;
  loggedInUserData$: Observable<any>;
  languageOptions: any;
  savedSuccessfullMessage: string;
  savedFlag: boolean;
  userPrefrences: any;
  sellersList: any;
  disableSellerSelection: boolean;
  showAlert: boolean;
  selectedSellerFilters: any[];
  subscription: any;
  selectedSellersNames: any;
  sellerValidationFlag: boolean;
  sellerPrefrenceNotChanged: boolean;
  availableStore: any;
  premiumSiteInvalid: any;
  premiumLabelConstant: any;
  userEmail: any;
  selectedSellerLocal: string;
  loggedInUserEmail: any;
  
  constructor(
    private formBuilder: FormBuilder,
    private service: HomeService,
    private modalService: NgbModal,
    private router: Router,
    private translate: TranslateConfigService,
    private translateService: TranslateService,
    private store: Store<ProductListingReducer.ProductListState>,
    ) { 
      this.premiumLabelConstant = Constants.PREMIUM_LABELS;
    // this.sellersList = Constants.SELLER_NAMES;
    this.profileForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      alternateEmail: [''],
      countryName: ['', [Validators.required]],
      areaCode: ['', [Validators.required]],
      preferedLanguage: ['', Validators.required],
      preferedSellers: this.createSellerList(Constants.SELLER_NAMES),
      acceptTerms: [false, Validators.requiredTrue],
      premiumSites: ['']
    });
    this.languageOptions = Constants.LANGUAGE_OPTIONS;
    this.loggedInUserData$ = this.service.loggedInUserData$;
    this.selectedSellerFilters = [];
    this.sellerPrefrenceNotChanged = true;
    // this.getSelectedSellers();
    this.loggedInUserEmail = 'guest_user';
  }

  createSellerList(sellersArray) {
    this.sellersList = [];
    
    for (let key of Object.keys(sellersArray)) {
      let seller = {
        key: key,
        value: sellersArray[key]
      }
      this.sellersList.push(seller);
    }
    const arr = this.sellersList.map(seller => {
      return new FormControl(seller.selected || false);
    });
    return new FormArray(arr);
  }

  public createSelectedSellerList(sellerList): any{
    this.createSellerList(Constants.SELLER_NAMES);
    sellerList.map(sell => {
      let sellerIndex = Object.keys(Constants.SELLER_NAMES).indexOf(sell);
      if(Object.keys(Constants.SELLER_NAMES).indexOf(sell) > -1){
        this.profileForm.controls.preferedSellers['controls'][sellerIndex].patchValue(true);
        this.onCheckboxChange(sell);
      }
    });
    console.log(sellerList);
  }

  ngOnInit(): void {
    this.loggedInUserData$.pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.loggedInUser = data.loggedInUserData;
        this.loggedInUserEmail = this.loggedInUser.email;
        this.getUserPrefrences();
      }
    );
  }

  ngDoCheck(): void {
    // this.selectedSellerLocal = localStorage.getItem('selectedSellers');
    // if (!this.loggedInUser && JSON.parse(this.selectedSellerLocal)) {
    //   this.createSelectedSellerList(JSON.parse(this.selectedSellerLocal));
    // }
  }
  public setFormFields(): void{
    this.profileForm.patchValue({
      firstName: this.userPrefrences.userInfo.firstName,
      lastName: this.userPrefrences.userInfo.lastName,
      email: this.userPrefrences.userInfo.emailid ? this.userPrefrences.userInfo.emailid : this.loggedInUser.email,
      alternateEmail: this.userPrefrences.userInfo.alternate_email,
      countryName: this.userPrefrences.userInfo.country ? this.userPrefrences.userInfo.country : 'Denmark',
      areaCode: this.userPrefrences.userInfo.areaCode,
      preferedLanguage: this.userPrefrences.userInfo.preferedLanguage ? this.userPrefrences.userInfo.preferedLanguage : localStorage.getItem('prefered_lang'),
      // preferedSellers: this.createSelectedSellerList(this.userPrefrences.userInfo.preferedSellers),
      acceptTerms: this.userPrefrences.userInfo.userValid,
      premiumSites: this.userPrefrences.userInfo.premiumSites
    });
    this.selectedSellerLocal = localStorage.getItem('selectedSellers');
    if (!this.userPrefrences.userInfo.site_preference) {
      this.createSelectedSellerList(JSON.parse(this.selectedSellerLocal));
    } else {
      this.createSelectedSellerList(this.userPrefrences.userInfo.site_preference);
    }
    this.service.setUserPremiumSites(this.userPrefrences.userInfo.premiumSites);
  }

  get f() { return this.profileForm.controls; }
  

  submitUserDetails(): void{
    this.submitted = true;
    // stop here if form is invalid
    if (this.profileForm.invalid) {
      return;
    }
    const userInfo = {
      firstName: this.profileForm.value.firstName,
      middleName: "",
      lastName: this.profileForm.value.lastName,
      emailId: this.loggedInUser?.email ? this.loggedInUser?.email : this.profileForm.value.email,
      alternateEmail: this.profileForm.value.alternateEmail,
      preferedLanguage: this.profileForm.value.preferedLanguage,
      areaCode: this.profileForm.value.areaCode,
      telephone: this.profileForm.value.telephone,
      city: "",
      state: "",
      countryName: this.profileForm.value.countryName,
      address: "",
      createdBy: "",
      acceptTerms: this.profileForm.value.acceptTerms,
      preferedSellers: _.compact(this.selectedSellersNames),
      premiumSites: this.profileForm.value.premiumSites ? this.profileForm.value.premiumSites : []
    };

    this.service.submitUserProfile(userInfo).pipe(takeUntil(this.ngUnsubscribe)).subscribe(userDetailResponse => {
      if (userDetailResponse.success) {
        if (this.loggedInUserEmail !== 'guest_user'){
          this.getUserPrefrences();
          this.savedFlag = true;
          this.savedSuccessfullMessage = this.translateService.instant('data_successfully_saved');
          localStorage.setItem('prefered_lang', this.profileForm.value.preferedLanguage);
          
          setTimeout(() => {
            this.savedFlag = false;
            this.savedSuccessfullMessage= "";
          }, 3000);
        }else{
          this.router.navigate(['home']);
        }
      } else {
        console.log('saved failed');
      }
    });
  }

  public getUserPrefrences(): void{
    this.service.getUserPrefrences(this.loggedInUserEmail).pipe(filter(data => data !== null), takeUntil(this.ngUnsubscribe)).subscribe(userPrefrences => {
      this.userPrefrences = userPrefrences;
      this.service.setUserPreferencesData(this.userPrefrences);
      this.service.setUserPremiumSites(this.userPrefrences.userInfo.premiumSites);
      if (!this.submitted) {
        this.setFormFields();
      }
      if (this.savedFlag) {
        this.store.dispatch(ProductActions.UpdateUserSitePrefrences({payload: this.selectedSellersNames.filter(Boolean)}));
        setTimeout(() => {
          window.location.reload();
        },3000);
      }
    });
  }

  openTerms(modalName): void{
    this.modalService.open(modalName, { size: 'lg' });
  }

  acceptTerms(flag): void{
    this.profileForm.patchValue({    
      acceptTerms: flag 
    }); 
    this.modalService.dismissAll();
  }

  // public onCheckboxChange(option){

  // }

  getSelectedSellers() {
    this.selectedSellersNames = _.map(
      this.profileForm.controls.preferedSellers["controls"],
      (seller, i) => {
        return seller.value && this.sellersList[i].key;
      }
    );
    // this.getSelectedSellersName();
  }


  public onCheckboxChange(e) {
    this.getSelectedSellers();
    if(this.selectedSellersNames.filter(Boolean).length === 4){
      this.profileForm.controls.preferedSellers['controls'][this.selectedSellersNames.indexOf(false)].disable();
    }else{
      let disabledIndex = _.findIndex(this.profileForm.controls.preferedSellers['controls'], (e) => {
        return e.disabled;
      }, 0);
      if(disabledIndex > -1){
        this.profileForm.controls.preferedSellers['controls'][disabledIndex].enable();
      }
    }
    this.sellerValidator();
  }

  public sellerValidator(): void{
    this.sellerValidationFlag = this.selectedSellersNames.filter(Boolean).length > 1;
    const sitePreference = this.userPrefrences?.userInfo?.site_preference ? this.userPrefrences?.userInfo?.site_preference : JSON.parse(this.selectedSellerLocal);
    this.sellerPrefrenceNotChanged = _.isEqual(sitePreference, this.selectedSellersNames.filter(Boolean));
    this.availableStore =  this.selectedSellersNames.filter(Boolean);
    this.premiumSiteInvalid = (_.difference(this.profileForm.controls['premiumSites'].value,this.selectedSellersNames.filter(Boolean))).length;
    // if(difference.length > 0){
    //   this.premiumSiteInvalid = true;
    // }else{
    //   this.premiumSiteInvalid = false;
    // }
  }

  public siteChange(event): void{
    this.premiumSiteInvalid = (_.difference(this.profileForm.controls['premiumSites'].value,this.selectedSellersNames.filter(Boolean))).length;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
