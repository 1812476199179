import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { HomeService } from './home/home.service';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'mart-poc';
  expanded: boolean;
  isMenuCollapsed: boolean;
  @ViewChild("cartContainer", {read: ElementRef, static: true}) cartContainer: ElementRef; // gets #target1
  showLoginRequiredMsg: any;

  constructor(
    private readonly _appService: AppService,
    private readonly _homeService: HomeService,
    private modalService: NgbModal,
  ){
    this.expanded = true;
    this.isMenuCollapsed = true;
  }
  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    
    const targetElement = event.target as HTMLElement;
    const cartViewButton = document.getElementById('cartViewButton');
    const viewMoreButton = document.getElementsByClassName('view-more-preview');
    // Check if the click was outside the element
    if((cartViewButton === targetElement) && !this.isMenuCollapsed){
      this.isMenuCollapsed = this.isMenuCollapsed;
    }else if (this.cartContainer &&  (targetElement && !this.cartContainer.nativeElement.contains(targetElement)) && !this.isMenuCollapsed) {
      this.isMenuCollapsed = true;
    }
    // else if(cartViewButton === targetElement){
    //   this.isMenuCollapsed = !this.isMenuCollapsed;
    // }
  }
  public expandMain(flag: boolean): void{
    this._appService.setMainMenuStatus(flag);
  }

  public detectMob() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      document.write("mobile device");
    }else{
      // false for not mobile device
      document.write("not mobile device");
    }
  }

  ngOnInit(): void {
    this.detectMob();

    this._homeService.getRegisterMessageStatus().subscribe(status => {
      this.showLoginRequiredMsg = status;
    });
  }

  public openLoginModal(modalName): void{
    this.modalService.open(modalName, { size: 'sm' });
  }

}


