export const SearchConstants: any = {
    //   data : [{
    //     letter: 'A',
    //     names: ['Alabama', 'Alaska', 'Arizona', 'Arkansas']
    //   }, {
    //     letter: 'C',
    //     names: ['California', 'Colorado', 'Connecticut']
    //   }, {
    //     letter: 'D',
    //     names: ['Delaware']
    //   }, {
    //     letter: 'F',
    //     names: ['Florida']
    //   }, {
    //     letter: 'G',
    //     names: ['Georgia']
    //   }, {
    //     letter: 'H',
    //     names: ['Hawaii']
    //   }, {
    //     letter: 'I',
    //     names: ['Idaho', 'Illinois', 'Indiana', 'Iowa']
    //   }, {
    //     letter: 'K',
    //     names: ['Kansas', 'Kentucky']
    //   }, {
    //     letter: 'L',
    //     names: ['Louisiana']
    //   }, {
    //     letter: 'M',
    //     names: ['Maine', 'Maryland', 'Massachusetts', 'Michigan',
    //       'Minnesota', 'Mississippi', 'Missouri', 'Montana']
    //   }, {
    //     letter: 'N',
    //     names: ['Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    //       'New Mexico', 'New York', 'North Carolina', 'North Dakota']
    //   }, {
    //     letter: 'O',
    //     names: ['Ohio', 'Oklahoma', 'Oregon']
    //   }, {
    //     letter: 'P',
    //     names: ['Pennsylvania']
    //   }, {
    //     letter: 'R',
    //     names: ['Rhode Island']
    //   }, {
    //     letter: 'S',
    //     names: ['South Carolina', 'South Dakota']
    //   }, {
    //     letter: 'T',
    //     names: ['Tennessee', 'Texas']
    //   }, {
    //     letter: 'U',
    //     names: ['Utah']
    //   }, {
    //     letter: 'V',
    //     names: ['Vermont', 'Virginia']
    //   }, {
    //     letter: 'W',
    //     names: ['Washington', 'West Virginia', 'Wisconsin', 'Wyoming']
    //   }]
      data: [
        "A38","Æblejuice","Æbler","Æg","Æggesalat","Ærter","Afkalker","Agurker","Akvavit","Ale",
        "Alkoholfri","Ananas","And","Ansigt","Aperitif","Appelsinjuice","Appelsinsodavand","Artiskokker",
        "Asparges","Avocado","Babycreme","Babygrød","Babymad","Babyolie","Babypleje","Babyshampoo","Bacon",
        "Bær","Bagepulver","Baguetteflutes","Bakedbeans","Balsam","Bananer","Barberskrabere","Barberskum",
        "Barer","Basmatiris","Batterier","Bind","Bitter","Blebukser","Bleer","Bleposer","Bloklys","Blommer",
        "Bodylotion","Bolcher","Boller","Bønner","Børneost","Børneyoghurt","Børster","Bouillon","Breezer",
        "Brie","Broccoli","Brød","Bruneris","Bulgur","Butterbeans","Cashewnødder","Cerutter","Champignon","Cheddar",
        "Chili","Chilisauce","Chips","Chokolade","Chokoladekiks","chorizo","Chutney","Cider","Cigaretter","Citrusfrugter",
        "Coca Cola","Cocktailpølser","Cognac","Coke","Cornflakes","Couscous","Creme","Cremefraiche","Croissanter","Croutoner",
        "Dadler","Danskvand","Dåsemad","Dej","Deodorant","Dressing","Druer","Durummel","Eddike","Espresso","Fåreost","Farfalle",
        "Fedt","Fersken","Feta”,”Fettucine","Figner","Film","Fisk","Fiskefars","Fiskefrikadeller","Fiskekonserves","Fiskeolie",
        "Fiskepålæg","Fjerkræ","Flåede tomater","Flager","Fløde m.v.","Flødeboller","Flødeerstatning","Flødeost","Flødeskum",
        "Flûtes","Fond","Forårsruller","Forkogte ris","Franskbrød","Frikadeller","Frø","Frugteddike","Frugtgrød","Frugtgrød",
        "Frugtpålæg","Fuglefoder","Fuldkornspasta","Gær","Gedeost","Gelé","Gin","Ginger ale","Ginger beer","Glaskartofler",
        "Gouda","Græsk yoghurt","Grand Padano","Gris","Grissini","Grødris","Grøntsagsjuice","Grovbrød","Grovkiks","Gulerødder",
        "Gulvrengøring","Hakkede tomater","Hakket kalvekød","Hakket kød","Hakket kylling","Hakket oksekød","Halspastiller",
        "Håndcreme","Håndsprit","Hårbørster, kamme","Hårfarve","Hårkur","Hårvoks","Hasselnødder","Havregryn","Hedvin/aperitif",
        "Helse","Honning","Hønsesalat ","Hot wings","Hummus","Hundemad","Hvedemel","Hvedeøl","Hvid chokolade","Hvidløg","Hvidvin",
        "Hytteost","Ice tea","Ingefærshots","Instant kaffe","Is","Isbarer","Iskaffe","Ispinde","Isvafler","Jalapeños","Jasminris",
        "Jordnødder","Kærnemælk","Kaffe","Kaffebønner","Kaffefløde","Kageblanding","Kager","Kakao","Kål","Kalkun","Kalvekød",
        "Kalvestege","Kammerjunkere","Kapers","Kapsler og puder","Karameller","Karrysalater","Kartoffelsalat","Kartofler","Kattemad",
        "Kaviarogblinis","Kemisk","Kerner","Ketchup","Kikærter","Kiks","Kildevand","Kiwi","Klid","Klude","Knækbrød","Kød","Kødboller",
        "Kogechokolade","Køkken","køkkenruller","Køkkenudstyr","Kokosmælk","Kokosmel","Kokosolie","Køl","Koldpressedeolier","Koldskål",
        "Kondomer","Konserves","Krydderboller","Krydderier","Kulør","Kylling","Kyllingefilet","Kyllingelår","Kyllingepølser",
        "Kyllingesalat","Læbepomade","Lagereddike","Lagkagebunde","Lakrids","Laks","Lam","Lasagneplader","Lemonade","Letmælk",
        "Leverpostej","Likør","Linser","Løg","Lommetørklæder","Macadamianødder","Madbrød","Madlavningsfløde","Madlavningsost","Mælk",
        "Mælkesnitte","Majs","Majsmel","Mandelmel","Mandler","Marcipan","Margarine","Marmelade","Mascarpone","Maskinopvask",
        "Maskinrens","Mayonnaise","Mejeri","Mel","Meloner","Minimælk","Mørktrugbrød","Mos","Most","Mousserendevin","Mozzarella",
        "Muffins","Mundskyl","Müsli","Naanbrød","Nachos","Natbleer","Nektariner","Nespresso","Nødder","Nougat","Nudler",
        "Økologiskøl","Oksekød","Øl","Olie","Oliven","Olivenolie","Opvask","Opvaskemiddel","Ost","Pærer","Pålæg","Pandekager",
        "Parboiledris","Parmaskinke","Parmesan","Pasta","Pastasauce","Pastaskruer","Pastiller","Paté","Peanuts","Peber","Peberfrugt",
        "Peberrodssalater","Pekannødder","Pesto","Pibetobak","Pilsner","Piskefløde","Pistacienødder","Pizza","Pizzatopping",
        "Plader","Plantebaseret","Plantemargarine","Plaster","Plastvarer","Pletfjerner","Pølsebrød","Pølser","Popcorn","Porter øl",
        "Poser","Pudsemiddel","Quinoa","Rapsolie","Rasp","Ravioli","Rejer","Rejsespil","Remoulade","Revet ost","Ricotta","Ris",
        "Riskiks","Rismel","Risottoris","Rispapir","Ristedeløg","Ristede nødder","Roastbeef","Rød sodavand","Rødbeder",
        "Røde kidneybønner","Rødkål","Rødløg","Røget ål","Rogn","Røgvarer","Rom","Romkugler","Rosevin","Rosiner","Rugbrød",
        "Rugbrødssnacks","Rundstykker","Sæbe","Saftevand","Salami","Salat","Salatdressing","Salattoppings","Salsa","Salt",
        "Salte kiks","Sandwich is","Sauce","Scones","Sennep","Serrano","Servietter","Shampoo","Shots","Showergel","Sild","Skæreost",
        "Skaldyr","Skinkesalat","Skocreme","Skoletasker","Skum","Skummetmælk","Skyllemiddel","Skyr","Slik","Smoothies","Smør",
        "Smøreost","Snaps","Snitsalater","Sødmælk","Solcreme","Solsikkerugbrød","Soltørrede tomater”,”Sortebønner","Soya",
        "Soyasauce","Spaghetti","Specialost","Spegepølser","Spinat","Spiritus","Stænger","Stanniol","Stearinlys","Sukker",
        "Suppe","Suppepasta","Surdejsbrød","Sutteflasker","Sutter","Svampe","Svesker","Svinekød","Svinekoteletter",
        "Svømmebleer","Syltede","Syltetøj","Syltning","Tacosauce","Tacoskaller","Tærtedej","Tahin","Tamponer","Tandbørster",
        "Tandpasta","Tandpleje","Tandstikkere","Tandtråd","Tapas","Tapas brød","Tapenade","Tarteletter","Tassimo","Te","Tequila",
        "Tex mex","Tobak","Toiletartikler","Toiletpapir","Tøjvask","Tomat på dåse","Tomater","Tomatpuré","Tonic","Tørrede svampe",
        "Torsk","Tortellini","Tortilla","Tyggegummi","Vådservietter","Vafler","Valnødder","Vanilje","Vaniljekiks","Vaseline",
        "Vaskekapsler","Vaskepulver","Vermouth","Vildt","Vin","Vineddike","Vingummi","Viskestykker","Vitaminer","Vodka","Whisky",
        "Wienerbrød","Yoghurt","Zinksalve"
      ]
      // data: [
    //     'Batterier',
    // 'Bind',
    // 'Bitter',
    // 'Blebukser',
    // 'Bleer',
    // 'Bleposer',
    // 'Bloklys',
    // 'Blommer',
    // 'Bodylotion',
    // 'Bolcher',
    // 'Boller'
      // ]
    }
    
    