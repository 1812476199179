<div class="row">
  <div class="col-12">
    <nav class="navbar navbar-expand-md navbar-light bg-light justify-content-between align-items-center" [ngClass]="{'blue-bg': !signinPage}">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex justify-content-start align-items-center flex-column flex-lg-row col-md-3">
          <button class="navbar-toggler" type="button" *ngIf="loggedInFlag" aria-controls="navbarContent" [attr.aria-expanded]="!isMenuCollapsed" aria-label="Toggle navigation" (click)="sidebarToggle($event)">
            <span class="navbar-toggler-icon"></span>
          </button>
          <span class="navbar-brand ml-3">
            <a [routerLink]="['home']" class="d-flex justify-content-start align-items-center">
              <img src="../../assets/images/viSave_logo.png" alt="viSave Logo" width="110px" height="30px">
            </a>
          </span>
        </div>
        <div [ngClass]="{'col-md-6':!signinPage, 'col': signinPage}">
          <app-search></app-search>
        </div>
        <div class="text-right" [ngClass]="{'col':!signinPage, 'col-6': signinPage}">
          <ul class="navbar-nav admin-menu d-flex justify-content-end align-items-center">
            <div class="lang-selection" [ngClass]="{'logged-in': loggedInFlag}">
              <form class="form-inline d-flex justify-content-center align-items-center" [formGroup]="languageOptionForm">
                  <ng-container *ngIf="signinPage">
                    <fieldset *ngFor="let language of languageOptions | keyvalue">
                      <div class="form-check form-check-inline" >
                          <input class="form-check-input" [id]="language.key" type="radio" formControlName="languageOption" value="{{language.key}}" (change)="selectPreferedLanguage(languageOptionForm)">
                          <label class="form-check-label" [for]="language.key" [title]="language.value">
                              {{ language.value }} 
                          </label>
                      </div>
                    </fieldset>
                  </ng-container>
                  <li class="lang-select-box mr-2 text-left" *ngIf="!signinPage">
                    <ng-select
                      formControlName="languageOption"
                      placeholder="Select Language"
                      appendTo="body"
                      (change)="selectPreferedLanguage($event)">
                      <ng-option *ngFor="let language of languageOptions | keyvalue" [value]="language.key">
                          {{language.value}}
                      </ng-option>
                    </ng-select>
                  </li>
              </form>
            </div>
            <li class="nav-item btn px-2 ml-3 secondary-btn support-btn"><a [routerLink]="['support']" translate><i class="fa fa-question" aria-hidden="true"></i>&nbsp;support</a></li>
            <!-- <li *ngIf="!loggedInFlag" class="nav-item btn px-2 ml-3 btn-danger"><a href="#" (click)="signout()" translate><i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp; log_out</a></li> -->
            <li class="ml-3" *ngIf="cartItems?.length > 0">
              <span class="d-flex justify-content-start align-items-center">
                <button class="btn navbar-toggler cart-toggler" style="padding: .4rem;" type="button" aria-controls="navbarContent" [attr.aria-expanded]="!isCartMenuCollapsed" aria-label="Toggle navigation" (click)="collapsePreviewMenu()">
                    <i aria-hidden="true" class="fa fa-shopping-cart mr-3"></i>  
                    <span class="badge badge-secondary" [ngClass]="{'quantity-zoom': flashToCart}">{{cartItems.length}}</span>
                    <i class="fa fa-bars" id="cartViewButton" aria-hidden="true" [ngClass]="{'shake-me': shakeMe}"></i>
                </button>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="navbar-collapse" [class.collapse]="isMenuCollapsed"
        [ngClass]="{ 'desktop-view': scrWidth >= 768, 'mobile-view': scrWidth < 768 }" id="navbarContent" >
        <div class="sidenav" #mainSidebar>
          <div class="container-fluid">
            <div class="row flex-grow-1">
              <div class="col-md-12 px-0">
                <ul class="nav flex-column">
                  <li class="nav-item form-check p-2 user-info">
                    <div class="pl-2">
                      <span class="d-flex justify-content-start align-items-center">
                        <span class="profile_pic mr-2" *ngIf="loggedInFlag && loggedInUserData?.photoURL"><img [src]="loggedInUserData.photoURL" alt="profile_picture"></span>
                        <span *ngIf="loggedInFlag" class="profile_name">{{loggedInUserDetails?.displayName}}</span>
                        <span *ngIf="!loggedInFlag" class="profile_name">viSave Guest</span>
                      </span>
                    </div>
                  </li>
                  <li class="nav-item form-check p-1 pl-5">
                    <a [routerLink]="['/myprofile']" (click)="$event.preventDefault()" translate><i class="fa fa-cogs" aria-hidden="true"></i>&nbsp; my_profile</a>
                  </li>
                </ul>
                <ngb-accordion #acc="ngbAccordion" class="menu-accord">
                  <!-- <ngb-panel id="delivery-toggle">
                    <ng-template ngbPanelTitle>
                      <div class="d-flex justify-content-between align-items-center">
                        <span translate><i class="fa fa-paper-plane" aria-hidden="true"></i> &nbsp; delivery_options</span>
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <ul class="nav flex-column">
                        <li class="nav-item form-check p-2 pl-5" *ngFor="let option of deliveryOptions">
                          <input class="form-check-input" [checked]="option.selected" (change)="selectDeliveryOptions(option, $event.target.checked)" type="checkbox" [id]="option.id"/>
                          <label class="form-check-label" [for]="option.id" [title]="option.name | translate">
                            {{ option.name | translate}} 
                          </label>
                        </li>
                      </ul>
                    </ng-template>
                  </ngb-panel> -->
                  <ngb-panel id="seller-toogle">
                    <ng-template ngbPanelTitle>
                      <div class="d-flex justify-content-between align-items-center">
                        <span translate><i class="fa fa-shopping-cart" aria-hidden="true"></i> &nbsp; seller_options</span>
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <ul class="nav flex-column" 
                        cdkDropList
                        [cdkDropListData]="sellerOptions"
                        (cdkDropListDropped)="drop($event)">
                        <li class="nav-item form-check p-2 pl-4 d-flex justify-content-start align-items-center" *ngFor="let option of sellerOptions" cdkDrag 
                        [cdkDragDisabled]="(filteredProductList && filteredProductList[option.name]?.length === 0)">
                          <!-- <input class="form-check-input" [disabled]="(disableSellerSelection && option.selected) || (filteredProductList && filteredProductList[option.name].length === 0)" [checked]="option.selected" (change)="selectSellerOptions(option, $event.target.checked)" type="checkbox" [id]="option.id"/> -->
                          <label class="form-check-label" [ngClass]="{'exclude': (filteredProductList && filteredProductList[option.name]?.length === 0)}" [for]="option.id" [title]="option.name">
                            <span class="seller-name">{{sellerNameConstant[option.name]}}</span>
                            <span class="small-text">{{(filteredProductList && filteredProductList[option.name].length === 0) ? '(Not Available)' : ''}}</span>
                          </label>
                          <span class="px-2"><i class="fa fa-bars" aria-hidden="true"></i></span>
                        </li>
                      </ul>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel id="saved-toogle" *ngIf="loggedInUserData && userPrefrences?.savedLists.length > 0">
                    <ng-template ngbPanelTitle>
                      <div class="d-flex justify-content-between align-items-center">
                        <span translate><i class="fa fa-folder-open" aria-hidden="true"></i> &nbsp;saved_lists</span>
                        <i class="fa fa-chevron-left" aria-hidden="true"></i>
                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <ul class="nav flex-column" id="savedToggle">
                        <li class="nav-item form-check p-2 pl-3" *ngFor="let listName of saveList">
                          <div class="d-flex justify-content-between align-items-center">
                            <button (click)="deleteSaveList(listName, deleteModalSaveList)" type="button" class="close mr-2" data-dismiss="alert" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <span class="form-check-label" [title]="listName" (click)="getSavedListItems(listName)">
                              {{ listName }}
                            </span>
                            <span class="px-2"><i class="fa fa-refresh" aria-hidden="true" title="Refresh for updated prices" (click)="getSavedListItems(listName)"></i></span>
                          </div>
                        </li>
                      </ul>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
                <ul class="nav flex-column" *ngIf="userPrefrences?.favourite">
                  <li class="nav-item form-check p-2 pl-3">
                    <a [routerLink]="['/home/favrouites']" translate><i class="fa fa-heart" aria-hidden="true"></i> &nbsp;favourites</a>
                  </li>
                </ul>
                <div class="seperator"></div>
                <ul class="nav flex-column" *ngIf="loggedInUserData">
                  <li class="nav-item form-check p-2 px-3">
                    <a href="#" (click)="signout()" translate><i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp; log_out</a>
                  </li>
                </ul>
                <div class="seperator" *ngIf="loggedInUserData"></div>
                <ul class="nav flex-column" *ngIf="loggedInUserData">
                  <li class="nav-item form-check p-2 px-3">
                    <button class="btn btn-link" (click)="deleteProfile(deleteProfileModal)" translate><i class="fa fa-sign-out" aria-hidden="true"></i>&nbsp; delete_my_profile</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>

<ng-template #deleteModalSaveList let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title" translate>delete_saved_list</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="row">
          <div class="col-sm-12">
            <span translate>are_you_sure_you_want_to_delete_your_saved_list</span>&nbsp;<strong>{{listName}}</strong>?
          </div>
      </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')" translate>cancel</button>
      <button type="button" class="btn btn-primary" (click)="confirmDeleteSavedList(listName)" translate>yes_delete</button>
  </div>
</ng-template>

<div class="navbar-collapse" [ngClass]="{'expanded-preview': expandPreview}" [class.collapse]="isCartMenuCollapsed" #cartContainer>
  <app-cart-preview (savedListSaveTrigger)="openCart($event)" [expandPreview]="expandPreview" [cartItems]="cartItems" *ngIf="cartItems?.length > 0"></app-cart-preview>
</div>

<ng-template #deleteProfileModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title" translate>delete_my_viSave_profile</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="row">
          <div class="col-sm-12" translate>
            {{messageDeleteProfile}}
          </div>
      </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="confirmProfileDelete()" translate>ok</button>
  </div>
</ng-template>