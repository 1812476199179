export const SupportConstants = [
    {
        ques: 'ques1',
        ans: "ans1"
    },
    {
        ques: 'ques2',
        ans: "ans2"
    },
    {
        ques: 'ques3',
        ans: "ans3"
    },
    {
        ques: 'ques4',
        ans: "ans4"
    },
    {
        ques: 'ques5',
        ans: "ans5"
    },
    {
        ques: 'ques6',
        ans: "ans6"
    }
]