import { Injectable } from '@angular/core';
import { CartModel, ProductDetailsModel, ProductList, SelectedProductsModel } from './types/products.model';
import * as _ from 'lodash';
import { Constants } from '../shared/constants';
import { Store } from '@ngrx/store';
import * as ProductListingReducer from '../products/state/products.reducer';
import * as ProductActions from '../products/state/products.actions';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  unitConstants: any;
  isSavedListTriggered: BehaviorSubject<string>;
  favProductArray: BehaviorSubject<any>;

  constructor(
    private store: Store<ProductListingReducer.ProductListState>,
  ) { 
    this.unitConstants = Constants.ProductUnit;
    this.isSavedListTriggered = new BehaviorSubject<string>(null); 
    this.favProductArray = new BehaviorSubject<any>([]);
  }
  
  public minusQuantity(product, selectedProducts): ProductList{
    let selectedProduct: ProductList = _.cloneDeep(selectedProducts);
    selectedProduct[product.site].selectedQuantity--;
    // product.selectedQuantity = selectedProduct[product.site].selectedQuantity;
    // selectedProduct[product.site].selectedUnit = selectedProduct[product.site].selectedQuantity * product.item_quantity.quantity;
    if (selectedProduct[product.site].selectedQuantity === 0){
      product.enableCart = false;
      selectedProduct[product.site].totalPrice = 0;
      selectedProduct[product.site].selectedProductList = [];
    }
    this.productSelection(selectedProduct[product.site]);
    return selectedProduct;
  }

  public addQuantity(product, selectedProducts): ProductList{
    let selectedProduct: ProductList = _.cloneDeep(selectedProducts);
    selectedProduct[product.site].selectedQuantity++;
    this.productSelection(selectedProduct[product.site]);
    // selectedProduct[product.site].selectedUnit = selectedProduct[product.site].selectedQuantity * selectedProduct[product.site].selectedUnit;
    return selectedProduct;
  }

  public updateAmount(selectedProduct: ProductList): ProductList{
    Object.entries(selectedProduct).forEach( element => {
      if (element[1].selectedProductList.length !== 0){
        this.calculateAmount(element[1].selectedProductList[0], selectedProduct);
      }else{
        return false;
      }
    });
    return selectedProduct;
  }

  public calculateAmount(product, selectedProducts): void{
    if (product.offer && product.offer_data.length > 0){
      
      product.offer_data.map(offer => {
        console.log(offer.type);
        switch (offer.type) {
          case "mix":
          case "stk":
            if ((selectedProducts[product.site].selectedQuantity === offer.quantity) || Math.floor(selectedProducts[product.site].selectedQuantity/offer.quantity) > 0){
              this.calculateOfferedPrice(product, offer, selectedProducts);
              // selectedProduct[product.site].totalPrice = product.discount_amount[index] * this.selectedQuantity/product.offer_quantity[index];
            }else{
              selectedProducts[product.site].totalPrice = product.availability ? product.price * selectedProducts[product.site].selectedQuantity : 0.00;
            }
            break;
          
          default:
            selectedProducts[product.site].totalPrice = product.availability ? product.price * selectedProducts[product.site].selectedQuantity : 0.00;
            break;
        }
      })
    }else{
      selectedProducts[product.site].totalPrice = product.availability ? product.price * selectedProducts[product.site].selectedQuantity : 0.00;
    }
  }

  public productSelection(selectedProduct: CartModel): void{
    let product = selectedProduct.selectedProductList[0];
    // let selectedProduct: ProductList = _.cloneDeep(this.selectedProduct);
    if (Object.entries(product.item_quantity).length){
      let volume = product.item_quantity.quantity;
      let unit = product.item_quantity.unit;
      let temp = Object.entries(this.unitConstants).filter(matchedUnit => { return _.includes(matchedUnit[1], unit) });
      switch (unit) {
        case 'kg':
          volume = volume * 1000;
          break;
        case 'liter':
          volume = volume * 1000;
          break;
        default:
          break;
      }
      selectedProduct.selectedUnit = selectedProduct.selectedQuantity * volume ;
      selectedProduct.selectedMeasure = temp[0][0];
    }
  }

  public calculateOfferedPrice(product, offer, selectedProducts): void{
    let quantityInOffer = Math.floor(selectedProducts[product.site].selectedQuantity/offer.quantity);
    let quantityOutOffer = selectedProducts[product.site].selectedQuantity - (quantityInOffer * offer.quantity);
    selectedProducts[product.site].totalPrice = (offer.price * quantityInOffer) + product.price * quantityOutOffer;
  }

  public selectProduct(product: ProductDetailsModel, selectedProducts): ProductList{
    let selectedProduct: ProductList = _.cloneDeep(selectedProducts);
    selectedProduct[product.site].selectedProductList.splice(0, 1, product);
    selectedProduct[product.site].selectedQuantity = selectedProduct[product.site].selectedQuantity + 1;
    this.productSelection(selectedProduct[product.site]);
    return selectedProduct;
  }

  public finalizeCart(cart, selectedProductList, searchedKey): any{
    let searchId = 1;
    let cartItems = [];
    if (cart && cart.length > 0){
      let editableItemIndex = _.findIndex(cart, value => value.searchKey === searchedKey);
      cartItems = [...cart];
      if(editableItemIndex > -1){
        let cartClone = []
        searchId = cart[editableItemIndex].searchId;
        cartItems.splice(editableItemIndex, 1);
      }else{
        searchId = cart.length + 1;
      };
    }
    let selectedItem: SelectedProductsModel = {
      productList: _.cloneDeep(selectedProductList),
      searchId: searchId,
      searchKey: searchedKey,
      bestPrice: this.bestPrice(selectedProductList)
    }
    cartItems.push(selectedItem);
    return cartItems;
  }

  public bestPrice(selectedProductList: ProductList): number{
    const priceArray = Object.entries(selectedProductList).map(seller => {
      return seller[1].totalPrice;
    })
    return Math.min.apply(null, priceArray.filter(Boolean));
  }

  public getSavedListTriggered(): Observable<any> {
    return this.isSavedListTriggered.asObservable();
  }

  public setSavedListTriggered(listName): void{
    this.isSavedListTriggered.next(listName);
  }

  public updateSelectedProduct(product, selectedProducts): ProductList {
    let selectedProduct: ProductList = _.cloneDeep(selectedProducts);
    selectedProduct[product.selectedProductList[0].site] = {...product}
    selectedProduct[product.selectedProductList[0].site].selectedQuantity = selectedProduct[product.selectedProductList[0].site].selectedQuantity + 1;
    return selectedProduct;
  }

  public arrangeCartItems(listItems): any{
    let cartItemArray = [];
    let searchedProducts = [];
    let searchKeyWord = "No Name";
    let sellers = Object.keys(listItems.savedListResponse);
    // console.log("raw data:",listItems.savedListResponse);
    if(sellers){
      sellers.map( name => {
        for(let index = 0; index < listItems.savedListResponse[name].length; index++){
          if(searchedProducts.indexOf(listItems.savedListResponse[name][index].searchedKeyword) === -1){
            searchedProducts.push(listItems.savedListResponse[name][index].searchedKeyword);
          }
          // console.log(searchedProducts);
        }
      })
      cartItemArray = this.makeCart(sellers, listItems.savedListResponse, searchedProducts, cartItemArray);
      // this.updateCart(cartItemArray);
    }
    return cartItemArray;
  }

  public makeCart(sellers, listItems, searchedProducts, cartItemArray): any{
    let cartItems = [];
    searchedProducts.map( searchKeyWord => {
      let selectedProduct = _.cloneDeep(Constants.InitialSelectedProduct);
      var diff = _.differenceWith(Object.keys(selectedProduct),sellers, _.isEqual);
      delete selectedProduct[diff];
      sellers.forEach(name => {
        for(let index = 0; index < listItems[name].length; index++){
          if(searchKeyWord === listItems[name][index].searchedKeyword){
            for(let count = 1; count <= listItems[name][index].purchase_quantity; count++){
              selectedProduct = this.selectProduct(listItems[name][index], selectedProduct);
            }
          }
        }
      });
      this.updateAmount(selectedProduct);
      let items = this.finalizeCart(cartItems, selectedProduct, searchKeyWord);
      cartItemArray.push(items[0]);
      // this._route.navigate(['searchresults']);
      // console.log(this.selectedProduct);
    })
    return cartItemArray;
    // this.setSavedListTriggered(listName);
  }

  public getFavList(): Observable<any> {
    return this.favProductArray.asObservable();
  }

  public setFavList(orderedArray): void{
    this.favProductArray.next(orderedArray);
  }
}