<div class="home-search" [ngClass]="{'home-page' : homePage}" *ngIf="showSearch">
  <form role="form" id="form-search" [formGroup]="searchForm" novalidate>
      <div class="form-group my-2">
          <div class="input-group">
              <label for="typeahead-format" class="d-none">Enter Product:</label>
              <input formControlName= "productName"
                  placeholder="{{ 'search_product' | translate }}"
                  id="typeahead-format"
                  type="search"
                  class="form-control" 
                  (ngModelChange)="model" 
                  [ngModel]="model"
                  [ngbTypeahead]="search" 
                  (selectItem)="searchProduct($event, alreadySearchedAlert)"
                  (keydown.enter)= "onEnter($event.target.value, alreadySearchedAlert)"
                  [focusFirst]= "false"
                  [resultFormatter]="formatter" />
                  <i class="btn btn-primary fa fa-search search-btn" (click)="onEnter($event.target.value, alreadySearchedAlert)"></i>
          </div>
      </div>
  </form>
</div>    
<ng-template #alreadySearchedAlert let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title" translate>searched_product</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="row">
          <div class="col-sm-12" translate>
            similar_product_already_exists
          </div>
      </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Ok</button>
  </div>
</ng-template>

