import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterFaq'
})
export class FilterFaqPipe implements PipeTransform {
  transform(items: any[], faqText: string): any[] {
    if (!items) return [];
    if (!faqText) return items;
  
    return items.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(faqText.toLowerCase());
      });
    });
  }
}
