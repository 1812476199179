import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { ProductsComponent } from './products/products.component';
import { NgbModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { CartComponent } from './cart/cart.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FavrouiteComponent } from './favrouite/favrouite.component';
import { MainPageComponent } from './main-page/main-page.component';
import { UserComponent } from './user/user.component';
import { FilterFaqPipe } from './support/pipes/filter-faq.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ProductCompareModalComponent } from './products/product-compare-modal/product-compare-modal.component';
import { CartPreviewComponent } from './cart/cart-preview/cart-preview.component';


@NgModule({
  declarations: [HomeComponent, MainPageComponent, ProductsComponent, CartComponent, FavrouiteComponent, UserComponent, FilterFaqPipe, ProductCompareModalComponent, CartPreviewComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    AutocompleteLibModule,
    DragDropModule,
    TranslateModule.forChild()
  ],
  exports: [FilterFaqPipe, CartPreviewComponent]
})
export class HomeModule { }
